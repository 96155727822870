import { useEffect } from "react"
import { useAuth } from "./use-auth.js"
import { useRouter } from "./use-router.js"

export default function useRequireAuth(redirectUrl = "/loginpage") {
    const auth = useAuth()
    const router = useRouter()

    useEffect(() => {
        if (auth.login === null) {
            router.navigate(redirectUrl)
        }
    }, [auth, redirectUrl])

    return auth
}
