import React, { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import PPLoadingIndicator from "../components/PPLoadingIndicator"
import "moment/locale/de"
import { PPPresetView } from "../components/PPPresetView"
import { PPPresetSelector } from "../components/PPPresetSelector"
import PPPriceSettings from "../components/PPPriceSettings"
import { useAuth } from "../hooks/use-auth"
import { useTranslation } from "react-i18next"
import { PPUserSettings } from "../components/PPUserSettings"

const SETTINGS_QUERY = gql`
    query getDataForSettings($userId: Long!) {
        shops {
            id
            name
            lang
            domain
            shouldBeActive
            active
            deactivationTime
            deactivationReason
        }
        settings {
            presets {
                label: name
                value: id
            }
        }
        user(userId: $userId) {
            name
            defaultPresetId: configShowTablePreset
        }
    }
`

export default function PPSettings(props) {
    const auth = useAuth()

    const [refetchPreset, setRefetchPreset] = useState()

    const { loading, data, refetch } = useQuery(SETTINGS_QUERY, {
        variables: { userId: auth.login.user.id },
        fetchPolicy: "no-cache"
    })

    useEffect(() => {
        if (props.presetId === null || props.presetId === undefined) {
            props.changePresetId(data?.settings?.presets[0]?.value)
        }
    }, [data])

    if (loading) return <PPLoadingIndicator />

    return (
        <div>
            <PPUserSettings
                presets={data?.settings?.presets}
                defaultPresetId={data?.user?.defaultPresetId}
                refetchSettingsData={refetch}
                setDataCurrentAuth={props.setDataCurrentAuth}
                refetchPreset={refetchPreset}
            />
            <PresetSettings
                presetId={props.presetId}
                changePresetId={props.changePresetId}
                data={data}
                refetchData={refetch}
                setRefetchPreset={setRefetchPreset}
            />
            <PPPriceSettings settingLocalstorage={props.settingLocalstorage} shops={data.shops} />
        </div>
    )
}

function PresetSettings(props) {
    const [t] = useTranslation()

    return (
        <div className="card shadow">
            <div className="card-header">
                <h2 className="card-title">{t("settings.preset.header")}</h2>
            </div>
            <div className="card-body p-4">
                <div className="container-fluid mb-2">
                    <div className="row d-flex">
                        <div className="col d-flex justify-content-end align-items-start">
                            <button
                                id="buttonPresetCollapseEditPreset"
                                className="btn btn-outline-secondary"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseEditPreset"
                                aria-expanded="false"
                                aria-controls="collapseEditPreset">
                                <i className="fas fa-cog" /> Preset
                            </button>
                            <PPPresetSelector
                                id="selectPresetSelector"
                                presetId={props.presetId}
                                changePresetId={props.changePresetId}
                                presets={props.data.settings.presets}
                            />
                        </div>
                    </div>
                    <PPPresetView
                        presetId={props.presetId}
                        refetchData={props.refetchData}
                        changePresetId={props.changePresetId}
                        presets={props.data.settings.presets}
                        setRefetchPreset={props.setRefetchPreset}
                    />
                </div>
            </div>
        </div>
    )
}
