import React, { useState, useEffect } from "react"
import { evaluate } from "mathjs"
import PPPriceHistory from "./PPPriceHistory"
import PPMerchantView from "./PPMerchantView"
import { useTranslation } from "react-i18next"
import GraphQL from "../utils/GraphQLClient"
import { toast } from "react-toastify"
import PPPictureBoxLoad from "./PPPictureBoxLoad"
import _ from "lodash"
import jquery from "jquery"
import { gql, useQuery } from "@apollo/client"
import { JsonViewer } from "@textea/json-viewer"
import useRequireAuth from "../hooks/use-require-auth"
import PPUtils from "../utils/PPUtils"
import { PPRightTabComment } from "./PPRightTabComment"

const query_mappingdelete = `mutation($shopId: Int!, $productNrFrom: String, $productNrTo: String, $addProductToRemovedMatchings: String) {
        deleteMapping(
            shopId: $shopId
            productNrFrom: $productNrFrom
            productNrTo: $productNrTo
            addProductToRemovedMatchings: $addProductToRemovedMatchings
        )
    }
`
const GET_MAPPING_PRICE_CONVERSION_FORMULA = gql`
    query getMappingPriceConversionFormula($shopId: Int!, $customerId: Long!, $productNr: String!) {
        getMappingPriceConversionFormula(shopId: $shopId, customerId: $customerId, productNr: $productNr) {
            mappingConversionFormula
        }
    }
`
function isJsonObject(str) {
    try {
        let jsonObj = JSON.parse(str)
        return typeof jsonObj === "object"
    } catch (e) {
        return false
    }
}

export default function PPrightTabShopInfo(props) {
    const [t] = useTranslation()

    const [conversionFormula, setConversionFormula] = useState("")

    const { refetch } = useQuery(GET_MAPPING_PRICE_CONVERSION_FORMULA, {
        displayName: "MappingConversionFormulaQuery",
        variables: {
            shopId: props.selectedProductRow.shopId,
            customerId: props.customerId,
            productNr: props.selectedProductRow.productNr
        },
        fetchPolicy: "no-cache",
        enabled: false,
        onCompleted: (data) => {
            setConversionFormula(data?.getMappingPriceConversionFormula?.mappingConversionFormula)
        },
        onError: (error) => console.error(error)
    })

    useEffect(() => {
        try {
            if (conversionFormula.length > 49) {
                throw Error("Value too long!")
            }

            let result = evaluate("1" + conversionFormula)

            if (result <= 0) {
                throw Error("Value must be positiv!")
            }

            if (props.priceConversionBtnRef.current) {
                props.priceConversionBtnRef.current.removeAttribute("disabled")
            }
        } catch (error) {
            if (props.priceConversionBtnRef.current) {
                props.priceConversionBtnRef.current.setAttribute("disabled", true)
            }
        }
    }, [conversionFormula])

    useEffect(() => {
        refetch()
    }, [props.selectedProductRow.shopId, props.selectedProductRow.productNr])

    if (props.selectedProductRow === undefined || props.selectedProductCell === undefined) {
        return null
    }

    let shouldUseSecondary =
        localStorage.getItem("switchCurrency") === "true" &&
        props.selectedProductCell?.secondPrice !== "-1,00" &&
        props.selectedProductCell?.secondPrice !== "0,00"

    let price = 0

    if (localStorage.getItem("showTotalPrice") === "true") {
        price = shouldUseSecondary
            ? props.selectedProductCell.secondPrice
            : props.selectedProductCell.priceByTotalPriceProvider > 0
              ? props.selectedProductCell.priceByTotalPriceProvider
              : props.selectedProductCell.price
    } else {
        price = shouldUseSecondary ? props.selectedProductCell.secondPrice : props.selectedProductCell.price
    }

    let totalPrice = 0

    if (localStorage.getItem("showTotalPrice") === "true") {
        totalPrice = shouldUseSecondary
            ? props.selectedProductCell.secondTotalPrice
            : props.selectedProductCell.totalPriceByTotalPriceProvider > 0
              ? props.selectedProductCell.totalPriceByTotalPriceProvider
              : props.selectedProductCell?.totalPrice
    } else {
        totalPrice = shouldUseSecondary
            ? props.selectedProductCell?.secondTotalPrice
            : props.selectedProductCell?.totalPrice
    }

    let currencySymbol = shouldUseSecondary
        ? props.selectedProductCell?.secondCurrencySymbol
        : props.selectedProductCell?.currencySymbol

    let productInfoFrontend = {}
    if (props?.selectedProductCell?.productInfoFrontend) {
        productInfoFrontend = JSON.parse(props?.selectedProductCell?.productInfoFrontend)
    }

    let deliveryPrice = Number(totalPrice - price)
    if (props?.selectedProductCell?.merchantPrices?.length > 0) {
        props?.selectedProductCell?.merchantPrices.forEach((data) => {
            if (localStorage.getItem("showTotalPrice") === "true") {
                if (
                    data.name == props.selectedProductCell?.cheapestMerchantNameTotalPrice &&
                    data.deliveryPrice !== undefined &&
                    data.deliveryPrice > 0
                ) {
                    deliveryPrice = data.deliveryPrice
                }
            } else {
                if (
                    data.name == props.selectedProductCell?.cheapestMerchantName &&
                    data.deliveryPrice !== undefined &&
                    data.deliveryPrice > 0
                ) {
                    deliveryPrice = data.deliveryPrice
                }
            }
        })
    }

    function triggerInstantCrawl() {
        props.onIcButtonClick(props.selectedProductCell?.url)
    }

    function triggerPriceConversion() {
        props.onConversionBtnClick(conversionFormula)
    }

    function deleteMapping(addProductToRemovedMatchings) {
        let variables = {
            shopId: props.selectedProductRow.shopId,
            productNrFrom: props.selectedProductRow.productNr,
            productNrTo: props.selectedProductCell.productnr,
            addProductToRemovedMatchings: addProductToRemovedMatchings
        }

        GraphQL.getClient()
            .request(query_mappingdelete, variables)
            .then(() => {
                toast(
                    t("pricetable.reset_mapping.toast_reset_msg", {
                        mapping: props.selectedProductRow.productNr + " -> " + props.selectedProductCell.productnr
                    }),
                    {
                        onClose: () => {
                            jquery("button#sidebarclose").trigger("click")
                            props?.refetchPricetable?.refetch()
                        }
                    }
                )
            })
            .catch((error) => console.error(error))
    }

    //extra wegen Flaschenpost
    const auth = useRequireAuth()
    const currentAuth = PPUtils.getCurrentAuthFromAuth(auth)

    return (
        <>
            <div className="d-flex align-items-center bg-white mr-0 my-3 m-3 pl-2">
                <button id="sidebarclose" type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="d-flex align-items-center bg-white mr-0 my-3 m-3">
                <div className="productimgbox">
                    {props.selectedProductCell?.imageUrl !== "" && (
                        <PPPictureBoxLoad image={props.selectedProductCell?.imageUrl} className={"img-fluid"} />
                    )}
                    {props.selectedProductCell?.imageUrl === "" && (
                        <PPPictureBoxLoad image={props.selectedProductRow?.imageUrl} className={"img-fluid"} />
                    )}
                </div>
                <div>
                    <h5 className="col px-3 p-2 align-self-start">{props.selectedProductCell?.productName}</h5>
                    <p className="col px-3 mb-0">
                        Shop:{" "}
                        <a target="_blank" rel="noopener noreferrer" href={props.selectedProductCell?.url}>
                            {props.selectedProductCell?.shopname + " " + props.selectedProductCell?.lang?.toUpperCase()}
                            <i className={"fas fa-external-link-alt righttab-icon"} />
                        </a>
                    </p>
                    <p className="col px-3 mb-0">
                        {t("single.category")}: {props.selectedProductRow?.department}
                    </p>
                    <p className="col px-3 mb-0">
                        {PPUtils.isTariffComparisonMode(props?.selectedProductRow?.tariffComparisonMode)
                            ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                            : t("single.manufacturer")}
                        : {props.selectedProductCell?.producer}
                    </p>
                    <p className="col px-3 mb-0">
                        {t("single.price")}: {price.toFixed(2)} {currencySymbol}
                    </p>
                    {totalPrice > 0 &&
                        !PPUtils.isTariffComparisonMode(props?.selectedProductRow?.tariffComparisonMode) && (
                            <>
                                <p className="col px-3 mb-0">
                                    {t("single.totalprice")}: {totalPrice.toFixed(2)} {currencySymbol}
                                </p>
                                <p className="col px-3">
                                    {t("single.shipping")}: {deliveryPrice.toFixed(2)} {currencySymbol}
                                </p>
                            </>
                        )}
                    <div className="px-3 mt-2 mb-2">
                        {props.selectedProductCell.isManualMatch == 1 && (
                            <p>{t("pricetable.righttab.infotext_is_manual_match")}</p>
                        )}
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-toggle="modal"
                            data-target="#deleteMapping">
                            {t("app.menu.matching")} {t("single.delete")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="card card-primary card-outline card-outline-tabs m-3">
                <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${
                                    props?.selectedProductCell?.merchantPrices?.length > 0 ? "active" : ""
                                }`}
                                id="custom-tabs-four-competitor-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-competitor"
                                role="tab"
                                aria-controls="custom-tabs-four-competitor"
                                aria-selected={`${
                                    props?.selectedProductCell?.merchantPrices?.length > 0 ? "true" : "false"
                                }`}>
                                {t("pricetable.righttab.competitor")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${
                                    props?.selectedProductCell?.merchantPrices?.length === 0 ? "active" : ""
                                }`}
                                id="custom-tabs-four-pricehistory-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-pricehistory"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-pricehistory"
                                aria-selected={`${
                                    props?.selectedProductCell?.merchantPrices?.length === 0 ? "true" : "false"
                                }`}>
                                {t("pricetable.righttab.price_history.title")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                id="custom-tabs-four-info-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-info"
                                role="tab"
                                aria-controls="custom-tabs-four-info"
                                aria-selected="false">
                                {t("pricetable.righttab.info")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                id="custom-tabs-five-info-tab"
                                data-toggle="pill"
                                href="#custom-tabs-five-info"
                                role="tab"
                                aria-controls="custom-tabs-five-info"
                                aria-selected="false">
                                {t("pricetable.righttab.price_conversion")}
                            </a>
                        </li>
                        {currentAuth.customer.name !== "Flaschenpost" && (
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-four-comment-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-four-comment"
                                    role="tab"
                                    aria-controls="custom-tabs-four-comment"
                                    aria-selected="false">
                                    {t("pricetable.righttab.comment")}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div
                            className={`tab-pane fade ${
                                props?.selectedProductCell?.merchantPrices?.length > 0 ? "active show" : ""
                            }`}
                            id="custom-tabs-four-competitor"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-competitor-tab">
                            <PPMerchantView foreignShop={props.selectedProductCell} />
                        </div>
                        <div
                            className={`tab-pane fade ${
                                props?.selectedProductCell?.merchantPrices?.length === 0 ? "active show" : ""
                            }`}
                            id="custom-tabs-four-pricehistory"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-pricehistory-tab">
                            <PPPriceHistory
                                columnLang={props.selectedProductRow?.columnLang}
                                selectedProductId={props.selectedProductRow?.id}
                                foreignShop={props.selectedProductCell}
                                productNr={props.selectedProductRow?.productNr}
                            />
                            <button
                                type="button"
                                ref={props.icBtnRef}
                                className="btn btn-block btn-primary"
                                onClick={triggerInstantCrawl}>
                                {t("pricetable.righttab.label_start_crawler") + props.selectedProductRow?.productNr}
                            </button>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="custom-tabs-four-info"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-info-tab">
                            {_.size(productInfoFrontend) > 0 ? (
                                <table id={"tableProductInfoFrontend"}>
                                    <tbody>
                                        {Object.keys(productInfoFrontend).map((key, index) => (
                                            <tr key={parseInt(index)}>
                                                <td>{key}</td>
                                                {(() => {
                                                    if (isJsonObject(productInfoFrontend[key])) {
                                                        return (
                                                            <td className={"data"}>
                                                                <JsonViewer
                                                                    value={JSON.parse(productInfoFrontend[key])}
                                                                />
                                                            </td>
                                                        )
                                                    } else if (typeof productInfoFrontend[key] === "object") {
                                                        return (
                                                            <td className={"data"}>
                                                                <JsonViewer value={productInfoFrontend[key]} />
                                                            </td>
                                                        )
                                                    } else {
                                                        return (
                                                            <td className={"data"}>
                                                                {productInfoFrontend[key].toString()}
                                                            </td>
                                                        )
                                                    }
                                                })()}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                t("pricetable.righttab.infotext")
                            )}
                        </div>
                        <div
                            className="tab-pane fade"
                            id="custom-tabs-five-info"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-five-info-tab">
                            <div>
                                <label>{t("pricetable.righttab.url.label_to_price_conversion")}</label>
                                <input
                                    onChange={(e) => setConversionFormula(e.target.value)}
                                    type="input"
                                    className="form-control"
                                    id="conversionFormula"
                                    title={t("pricetable.righttab.price_conversion_info")}
                                    placeholder="* 2"
                                    value={conversionFormula}
                                />
                                <br />
                            </div>
                            <button
                                type="button"
                                ref={props.priceConversionBtnRef}
                                className="btn btn-block btn-primary"
                                onClick={triggerPriceConversion}>
                                {t("pricetable.righttab.label_start_price_conversion") +
                                    props.selectedProductRow?.productNr}
                            </button>
                        </div>
                        {currentAuth.customer.name !== "Flaschenpost" && (
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-four-comment"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-four-comment-tab">
                                <PPRightTabComment
                                    comment={props.selectedProductRow.comment}
                                    saveCommentMappingEditinfo={props.saveCommentMappingEditinfo}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div
                className="modal fade"
                id="deleteMapping"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="deleteMappingLabel"
                aria-hidden="true"
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteMappingLabel">
                                {t("single.delete")} ?
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">{t("mapping.mapping_modal_delete_text")}</div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.cancel")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    deleteMapping("no")
                                }}>
                                {t("single.delete")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    deleteMapping("yes")
                                }}>
                                {t("mapping.mapping_modal_delete_safety_question")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
