import { GraphQLClient } from "graphql-request"
import PPUtils from "./PPUtils"

// const GQLClient =  new GraphQLClient('/graphql', {   headers: {
//             "Authorization": "Bearer " + localStorage.getItem("token")
//         } })
//
// export default GQLClient;

class GraphQLClass {
    constructor() {
        this._data = []
    }

    add(item) {
        this._data.push(item)
    }

    getClient() {
        return new GraphQLClient("/graphql", {
            headers: {
                Authorization: "Bearer " + PPUtils.getLogin().token
            }
        })
    }
}

const GraphQL = new GraphQLClass()
Object.freeze(GraphQL)

export default GraphQL
