import React from "react"
import "../App.css"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import md5 from "md5-hash"
import PPTarifView from "./PPTarifView"

function insertInternalIdealoRedirectService(shopId, url) {
    if (url !== undefined && url.includes("idealohash://")) {
        url = "/resolveIdealoRedirects?hash=" + url.replace("idealohash://", "") + "&shopId=" + shopId
    }

    return url
}

function PPMerchantRow(props) {
    return (
        <li
            key={props.index + "_" + props.merchant.name.toString()}
            className={props.isCheapest ? "list-group-item-success" : "list-group-item"}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={insertInternalIdealoRedirectService(props.foreignShop.shopId, props.merchant.url)}>
                <h5>
                    {props.merchant.name}{" "}
                    <span
                        className={`float-right badge badge-secondary stock-${props.merchant.stock}-font-color`}
                        style={{ backgroundColor: props.merchant.stock }}>
                        {props.merchant.price.toFixed(2)}
                        {props.currencySymbol}
                        {localStorage.getItem("showTotalPrice") === "true" && (
                            <>
                                {" "}
                                ({props.merchant.totalPrice.toFixed(2)}
                                {props.currencySymbol})
                            </>
                        )}
                    </span>
                </h5>
            </a>
            {props.merchant.tarif !== undefined && props.merchant.tarif !== null && (
                <div>
                    <ul>
                        {Object.entries(props.merchant.tarif?.tarifInfoMap)?.map(([propertyName, tarifData]) => (
                            <PPTarifView key={propertyName} tarifData={tarifData} />
                        ))}
                    </ul>
                </div>
            )}
        </li>
    )
}

PPMerchantRow.propTypes = { x: PropTypes.any }

function PPMerchantView(props) {
    const [t] = useTranslation()

    if (props.foreignShop === undefined) {
        return null
    }
    let merchantPrices = props?.foreignShop?.merchantPrices
    let price = props?.foreignShop?.price

    if (merchantPrices.length === 0 && price <= 0) {
        return <p>{t("pricetable.righttab.competitor_tab.no_competitors_found_only_customer")}</p>
    } else if (merchantPrices.length === 0) {
        return <p>{t("pricetable.righttab.competitor_tab.no_competitors_found")}</p>
    }

    if (localStorage.getItem("showTotalPrice") === "true") {
        merchantPrices.sort((m1, m2) => m1.totalPrice - m2.totalPrice)
    } else {
        merchantPrices.sort((m1, m2) => m1.price - m2.price)
    }

    let items = merchantPrices.map((x, index) => {
        let key = index + "_" + md5(JSON.stringify(x))
        return (
            <PPMerchantRow
                key={key}
                index={index}
                foreignShop={props?.foreignShop}
                merchant={x}
                currencySymbol={props?.foreignShop?.currencySymbol}
            />
        )
    })

    return <ul className="list-group ul-scroll">{items}</ul>
}

export default PPMerchantView
