import React from "react"
import PPPriceHistory from "./PPPriceHistory"
import { useTranslation } from "react-i18next"
import PPPictureBoxLoad from "./PPPictureBoxLoad"
import PPUtils from "../utils/PPUtils"

export default function PPrightTabAllShops(props) {
    const [t] = useTranslation()

    if (props.selectedProductRow === undefined || props.selectedProductRow?.presetShopIds === {}) {
        return null
    }

    return (
        <>
            <div className="d-flex align-items-center bg-white mr-0 my-3 m-3 pl-2">
                <button id="sidebarclose" type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="d-flex align-items-center bg-white mr-0 my-3 m-3">
                <div className="productimgbox">
                    <PPPictureBoxLoad image={props.selectedProductRow?.imageUrl} className={"img-fluid"} />
                </div>
                <div>
                    <h5 className="col px-3 p-2 align-self-start">{props.selectedProductRow?.productName}</h5>
                    <p className="col px-3">
                        {t("single.category")}: {props.selectedProductRow?.department}
                    </p>
                    <p className="col px-3">
                        {PPUtils.isTariffComparisonMode(props?.selectedProductRow?.tariffComparisonMode)
                            ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                            : t("single.manufacturer")}
                        : {props.selectedProductRow?.producer}
                    </p>
                </div>
            </div>
            <div className="card card-primary card-outline card-outline-tabs m-3">
                <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li className="nav-item active">
                            <a
                                className="nav-link active"
                                id="custom-tabs-four-pricehistory-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-pricehistory"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-pricehistory"
                                aria-selected="true">
                                {t("pricetable.righttab.price_history.title")} ({t("pricetable.righttab.preset_shops")})
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div
                            className="tab-pane fade active show"
                            id="custom-tabs-four-pricehistory"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-pricehistory-tab">
                            <PPPriceHistory
                                shopIds={props.selectedProductRow?.presetShopIds}
                                selectedProductId={props.selectedProductRow?.id}
                                columnLang={props.selectedProductRow?.columnLang}
                                productname={props.selectedProductRow.productName}
                                productNr={props.selectedProductRow?.productNr}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
