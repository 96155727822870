import React, { MouseEventHandler } from "react"
import Select, { components, MultiValueGenericProps, MultiValueProps, MultiValueRemoveProps } from "react-select"
import { DndContext } from "@dnd-kit/core"
import { restrictToParentElement } from "@dnd-kit/modifiers"
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import PPUtils from "../utils/PPUtils"
import countries from "i18n-iso-countries"

export const Option = ({ children, ...props }) => {
    const { data } = props
    const userlang = PPUtils.getLogin().user.configLanguage
    return (
        <components.Option {...props}>
            {data.lang && <span title={countries.getName(data.lang, userlang)} className={"fi fi-" + data.lang} />}{" "}
            {children}
        </components.Option>
    )
}

export const MultiValueLabel = ({ children, ...props }) => {
    const { data } = props
    const userlang = PPUtils.getLogin().user.configLanguage
    return (
        <components.MultiValueLabel {...props}>
            {data.lang && <span title={countries.getName(data.lang, userlang)} className={"fi fi-" + data.lang} />}{" "}
            {children}
        </components.MultiValueLabel>
    )
}

const MultiValue = (props: MultiValueProps) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.data.id
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    const userlang = PPUtils.getLogin().user.configLanguage

    return (
        <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
            <components.MultiValue {...props} innerProps={innerProps}>
                <span title={countries.getName(props.data.lang, userlang)} className={"fi fi-" + props.data.lang} />{" "}
                {props.children}
            </components.MultiValue>
        </div>
    )
}

const MultiValueContainer = (props: MultiValueGenericProps) => {
    return <components.MultiValueContainer {...props} />
}

const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{
                onPointerDown: (e) => e.stopPropagation(),
                ...props.innerProps
            }}
        />
    )
}

export function PPShopPreset(props) {
    const [selected, setSelected] = React.useState(props.selectedShops)

    const onChange = (selectedOptions) => {
        setSelected(selectedOptions)
        props.changeShops(selectedOptions)
    }

    const onSortEnd = (event) => {
        const { active, over } = event

        if (!active || !over) return

        function findIndex(selected) {
            const oldIndex = selected.findIndex((item) => item.id === active.id)
            const newIndex = selected.findIndex((item) => item.id === over.id)
            return arrayMove(selected, oldIndex, newIndex)
        }
        let newSelected = findIndex(selected)

        setSelected(newSelected)
        props.changeShops(newSelected)
    }

    return (
        <DndContext modifiers={[restrictToParentElement]} onDragEnd={onSortEnd}>
            <SortableContext items={selected} strategy={rectSortingStrategy}>
                <Select
                    className={props.className}
                    isMulti
                    options={props.shops}
                    value={selected}
                    onChange={onChange}
                    components={{
                        Option,
                        MultiValue,
                        MultiValueContainer,
                        MultiValueRemove
                    }}
                    closeMenuOnSelect={false}
                />
            </SortableContext>
        </DndContext>
    )
}
