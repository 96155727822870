import React from "react"
import "../App.css"
import "moment/locale/de"
import PPUtils from "../utils/PPUtils"
import getSymbolFromCurrency from "currency-symbol-map"
import { useTranslation } from "react-i18next"

function PriceBox(props) {
    let mainPrice = props.mainprice
    if (parseFloat(props.mainprice) <= 0) {
        mainPrice = "-"
    }

    return (
        <div>
            {localStorage.getItem("showPriceDifferenceThumb") === "true" ? (
                <>
                    <p className="font-weight-bold pricetext" style={{ marginTop: "0.45em" }}>
                        {mainPrice}
                    </p>
                    {props.clampprice && (
                        <p className="font-weight-bold clamppricetext" style={{ marginBottom: 0 }}>
                            ({props.clampprice})
                        </p>
                    )}
                    {props.optionArrow &&
                        (mainPrice != "-" ? (
                            <i
                                className={`price-difference ${props.optionArrow.arrow}`}
                                style={{
                                    color: props.optionArrow.color
                                }}
                            />
                        ) : (
                            ""
                        ))}
                </>
            ) : (
                <>
                    {props.optionArrow ? (
                        <>
                            <p
                                className={`font-weight-bold pricetext pricetext_color_${props.optionArrow?.color?.replace(
                                    "#",
                                    ""
                                )}`}
                                style={{ marginTop: "0.45em", color: props.optionArrow.color }}>
                                {mainPrice}
                            </p>
                            {props.clampprice && (
                                <p className="font-weight-bold clamppricetext" style={{ marginBottom: 0 }}>
                                    ({props.clampprice})
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <p className={`font-weight-bold pricetext`} style={{ marginTop: "0.45em" }}>
                                {mainPrice}
                            </p>
                            {props.clampprice && (
                                <p className="font-weight-bold clamppricetext" style={{ marginBottom: 0 }}>
                                    ({props.clampprice})
                                </p>
                            )}
                        </>
                    )}
                </>
            )}
            {props.subline && (
                <p style={{ marginBottom: 0 }} className={"subline"}>
                    <small>{props.subline}</small>
                </p>
            )}
            {props.subsubline !== undefined && <small className={"pricecell-subsubline"}>{props.subsubline}</small>}
        </div>
    )
}

function NormalBox(props) {
    return <div>{props.value}</div>
}

function PPStockIndicator(props) {
    if (
        (localStorage.getItem("switchCustomerStock") !== "false" && parseFloat(props.priceCopy) <= 0) ||
        (localStorage.getItem("switchStock") !== "false" && parseFloat(props.priceCopy) > 0)
    ) {
        let lineCount
        let stockColor
        if (props.color === "green") {
            lineCount = 3
            stockColor = PPUtils.Colors.GreenStart
        } else if (props.color === "yellow") {
            lineCount = 2
            stockColor = PPUtils.Colors.OrangeStart
        } else if (props.color === "red") {
            lineCount = 1
            stockColor = PPUtils.Colors.RedStart
        }

        let items = []
        for (let i = 0; i < lineCount; i++) {
            items.push(
                <div
                    key={i}
                    className="stockitem"
                    style={{
                        borderBottomColor: stockColor,
                        borderBottomStyle: props.borderHidden
                    }}
                />
            )
        }

        if (items.length > 0) {
            return (
                <>
                    <div className={"stockbox"}>{items}</div>
                </>
            )
        }
    }

    return ""
}

function PPTableCellPrice(props) {
    const [t, i18n] = useTranslation()

    let payload
    let divStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
    let stockColor
    let stockBorderStyle = "solid"
    let comment = ""
    let isCustomerSelf = 0
    let linktoproductpage = ""

    function setRowAndCellData() {
        props.changeSelectedCell(payload.type === "i" ? null : props.foreignShop)
        props.changeSelectedRow({
            id: props.row.id,
            productNr: props.row.productnr,
            producer: props.row.producer,
            department: props.row.department,
            imageUrl: props.row.imageUrl,
            productName: props.row.productName,
            shopName: props.shopName !== undefined ? props.shopName : "",
            shopId: props.shopId !== undefined ? props.shopId : null,
            shopDomain: props.shopDomain !== undefined ? props.shopDomain : "",
            presetShopIds: props.presetShopIds,
            columnLang: getCellLang(),
            comment:
                props.row?.mappingEditinfo[props?.shopId] !== undefined
                    ? props.row?.mappingEditinfo[props?.shopId]
                    : "",
            tariffComparisonMode: props.tariffComparisonMode
        })
    }

    function getCellLang() {
        let lang = null
        if (props.shopLang !== undefined) {
            lang = props.shopLang
        } else if (props.columnName !== undefined) {
            let priceLang = props.columnName?.split("_")
            lang = priceLang[1]
        }
        return lang
    }

    function getForeignPriceValue(columnName) {
        let priceLang = columnName.split("_")
        let priceItem
        if (localStorage.getItem("switchCurrency") === "true") {
            priceItem = props.row.secondPricesV2?.find(function (item) {
                return item?.lang === priceLang[1]
            })
        }

        if (priceItem === undefined) {
            priceItem = props.row.pricesV2?.find(function (item) {
                return item?.lang === priceLang[1]
            })
        }

        stockColor = priceItem?.stockLight

        if (priceItem?.lang === "de" && localStorage.getItem("showTotalPrice") === "true") {
            let totalPrice = props?.row?.genericColumns["TotalPrice"]
            if (totalPrice !== null && totalPrice !== undefined) {
                totalPrice = parseFloat(Number(PPUtils.addZeroesIfNeeded(props.row.genericColumns["TotalPrice"])))
                return (
                    <PriceBox
                        mainprice={priceItem?.value.toFixed(2) + priceItem?.symbol}
                        clampprice={totalPrice.toFixed(2) + priceItem?.symbol}
                    />
                )
            }
        }

        return <PriceBox mainprice={priceItem?.value.toFixed(2) + priceItem?.symbol} />
    }

    function getForeignUrlValue(columnName) {
        let priceLang = columnName.split("_")
        let url = ""
        if (typeof props.row.urls === "object" && props.row.urls !== null && props.row.urls !== undefined) {
            for (const key in props?.row?.urls) {
                if (priceLang[1] == key) {
                    url = props?.row?.urls[key]
                }
            }
        }

        if (url == "") {
            let currentAuth = PPUtils.getLogin()
            url = PPUtils.getCustomerProductURL(currentAuth?.customer, props.row, priceLang[1])
        }

        return url
    }

    if (props.foreignShop === undefined) {
        payload = PPUtils.getLinkIcon()
        divStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    }

    let priceCopy = 0
    if (props.foreignShop !== undefined) {
        let shouldUseSecondary =
            localStorage.getItem("switchCurrency") === "true" &&
            props.foreignShop.secondPrice !== "-1,00" &&
            props.foreignShop.secondPrice !== "0,00"
        let price = ""
        let clampprice = ""
        if (localStorage.getItem("showTotalPrice") === "true") {
            price = shouldUseSecondary
                ? props.foreignShop?.secondPrice.toFixed(2)
                : props.foreignShop?.priceByTotalPriceProvider > 0
                  ? props.foreignShop?.priceByTotalPriceProvider.toFixed(2)
                  : props.foreignShop?.price.toFixed(2)
            clampprice = shouldUseSecondary
                ? props.foreignShop?.secondTotalPrice.toFixed(2)
                : props.foreignShop?.totalPriceByTotalPriceProvider > 0
                  ? props.foreignShop?.totalPriceByTotalPriceProvider.toFixed(2)
                  : props.foreignShop?.totalPrice.toFixed(2)
        } else {
            price = shouldUseSecondary ? props.foreignShop?.secondPrice.toFixed(2) : props.foreignShop?.price.toFixed(2)
        }
        if (price < 0) {
            price = "-"
        }
        if (clampprice < 0) {
            clampprice = "-"
        }
        let currencySymbol = shouldUseSecondary
            ? props.foreignShop.secondCurrencySymbol
            : props.foreignShop.currencySymbol
        if (currencySymbol == null || currencySymbol == undefined) {
            currencySymbol = ""
        }
        let pricediff = ""

        if (shouldUseSecondary) {
            //Wir haben in der Datenbank keine Preisdifferenz für second prices daher rechnen wir das hier ondemand dann aus
            pricediff = PPUtils.getPercentDifference(
                props.row.secondPricesV2.find((item) => {
                    return item.lang === props.foreignShop.lang
                })?.value,
                price
            )
        } else {
            if (localStorage.getItem("showTotalPrice") === "true") {
                pricediff = props.foreignShop.totalPriceDifference
            } else {
                pricediff = props.foreignShop.priceDifference
            }
        }

        if (price != "-" && price > 0) {
            price += currencySymbol
        }

        if (clampprice != "-" && clampprice > 0) {
            clampprice += currencySymbol
        }

        priceCopy = price
        props.row.foreignShop = props.foreignShop
        let cheapestMerchantNameToShow = props.foreignShop.cheapestMerchantName
        if (localStorage.getItem("showTotalPrice") === "true") {
            cheapestMerchantNameToShow = props.foreignShop.cheapestMerchantNameTotalPrice
        }
        payload = (
            <PriceBox
                mainprice={`${price}`}
                clampprice={`${clampprice}`}
                subline={
                    localStorage.getItem("switchPriceAge") === "true"
                        ? PPUtils.getCrawlAgo(props.foreignShop.crawlDate, i18n)
                        : ""
                }
                subsubline={cheapestMerchantNameToShow}
                optionArrow={PPUtils.getPriceMood(pricediff)}
            />
        )
        stockColor = props.foreignShop.stock
        divStyle = {
            opacity: props.foreignShop.parsingFlag ? "1.0" : "0.5"
        }
        comment = ""
        isCustomerSelf = props.foreignShop.isCustomerSelf
        if (props.foreignShop.url !== "") {
            linktoproductpage = props.foreignShop.url
        }
    } else if (props.columnName !== undefined) {
        let value
        let merchantName

        if (props.columnFormat?.toLowerCase() == "formatprice") {
            payload = ""
            if (props.row.genericColumns[props.columnName] !== undefined) {
                value = props.row.genericColumns[props.columnName]
                payload = getForeignPriceValue(props.columnName)
            }
        } else if (props.columnFormat?.toLowerCase() == "formatnormal") {
            if (props.row.genericColumns[props.columnName] !== undefined) {
                payload = <NormalBox value={props.row.genericColumns[props.columnName]} />
            }
        } else {
            switch (props.columnName) {
                case "avgPrice":
                    if (localStorage.getItem("showTotalPrice") === "false") {
                        value = props.row.avgPrice
                    } else {
                        value = props.row.avgTotalPrice
                    }
                    if (props.row.avgCountDealers != "") {
                        merchantName = t("single.dealers") + ": " + props.row.avgCountDealers
                    }
                    break
                case "minPrice":
                    if (localStorage.getItem("showTotalPrice") === "false") {
                        value = props.row.minPrice
                        merchantName = props.row.minPriceMerchantName?.replaceAll('"', "")
                    } else {
                        value = props.row.minTotalPrice
                        merchantName = props.row.minTotalPriceMerchantName?.replaceAll('"', "")
                    }
                    break
                case "maxPrice":
                    if (localStorage.getItem("showTotalPrice") === "false") {
                        value = props.row.maxPrice
                        merchantName = props.row.maxPriceMerchantName?.replaceAll('"', "")
                    } else {
                        value = props.row.maxTotalPrice
                        merchantName = props.row.maxTotalPriceMerchantName?.replaceAll('"', "")
                    }
                    break
                case "priceEk":
                    value = props.row.priceEk
                    break
                case "TotalPrice":
                    value = props.row.genericColumns[props.columnName]
                    break
                case "Versandkosten":
                    value = props.row.genericColumns[props.columnName]
                    break
                case "UVP":
                    payload = ""
                    if (props.row.genericColumns[props.columnName] !== undefined) {
                        value = props.row.genericColumns[props.columnName]
                        payload = getForeignPriceValue(props.columnName)
                    }
                    break
                case "checked":
                    payload = PPUtils.getBooleanValue(props.row.checked, t)
                    break
                case "appointed":
                    payload = PPUtils.getBooleanValue(props.row.appointed, t)
                    break
                case "discontinuedItem":
                    payload = PPUtils.getBooleanValue(props.row.discontinuedItem, t)
                    break
            }
        }
        comment = ""
        isCustomerSelf = 0

        //Rule Engine Preis in den verschiedenen Kanälen
        if (props.columnName.toLowerCase().includes("pms_price")) {
            value = props.row.genericColumns[props.columnName]
        }

        if (value !== undefined) {
            if (value.length > 0 && value.indexOf(",") > 0) {
                value = value.replace(",", ".")
            }

            priceCopy = value
            let currencySymbol = getSymbolFromCurrency(props.currencyIsoCode)
            payload =
                merchantName !== null && merchantName !== undefined ? (
                    localStorage.getItem("showTotalPrice") === "true" ? (
                        <PriceBox
                            mainprice={Number(PPUtils.addZeroesIfNeeded(value)).toFixed(2) + currencySymbol}
                            subsubline={merchantName}
                        />
                    ) : (
                        <PriceBox
                            mainprice={Number(PPUtils.addZeroesIfNeeded(value)).toFixed(2) + currencySymbol}
                            subsubline={merchantName}
                        />
                    )
                ) : localStorage.getItem("showTotalPrice") === "true" && props.columnName === "avgPrice" ? (
                    <PriceBox mainprice={Number(PPUtils.addZeroesIfNeeded(value)).toFixed(2) + currencySymbol} />
                ) : (
                    <PriceBox mainprice={Number(PPUtils.addZeroesIfNeeded(value)).toFixed(2) + currencySymbol} />
                )
        } else if (!props.columnName.toLowerCase().includes("pms_price") && props.columnName.includes("price_")) {
            payload = getForeignPriceValue(props.columnName)
        }

        if (props.columnName.includes("price_")) {
            linktoproductpage = getForeignUrlValue(props.columnName)
        }

        if (props.row.foreignShopsProductInformations.length > 0) {
            divStyle = {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }
        }
    }

    if (props?.row?.mappingEditinfo[props?.shopId] !== undefined) {
        let text = props?.row?.mappingEditinfo[props?.shopId]
        if (text.length > 40) {
            text = text.substring(0, 40) + " ..."
        }
        comment = <i className="fas fa-file-word mappingeditinfocomment" style={{ color: "gray" }} title={text}></i>
        if (props.foreignShop !== undefined) {
            comment = <div className="mappingeditinfocomment_box">{comment}</div>
        }
    }

    let linkicon = ""
    if (localStorage.getItem("switchShowLinks") === "true" && linktoproductpage != "") {
        linkicon = (
            <div className={"linkicon-external"}>
                <a href={linktoproductpage} target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-external-link-alt" aria-hidden="true"></i>
                </a>
            </div>
        )
    }

    if (props.foreignShop === undefined && stockColor == "") {
        stockBorderStyle = "none"
    }

    function isOnClickColumn() {
        return (
            (props.shopName !== undefined && !PPUtils.isBooleanColumn(props.columnName)) ||
            props.columnName.includes("price_")
        )
    }

    function getStockIndicator() {
        let divIsCustomerSelf = ""
        if (isCustomerSelf > 0) {
            divIsCustomerSelf = (
                <div className={"iscustomerself"}>
                    <i className="fas fa-box-open" title={t("single.iscustomerself")}></i>
                </div>
            )
        }

        return (
            <div>
                {payload}
                {comment}
                <PPStockIndicator
                    color={stockColor}
                    borderHidden={stockBorderStyle}
                    priceCopy={priceCopy}
                    isCustomerSelf={isCustomerSelf}
                />
                {divIsCustomerSelf}
            </div>
        )
    }

    return (
        <>
            {isOnClickColumn() && (
                <>
                    {linkicon}
                    <div
                        className="pricebox"
                        style={divStyle}
                        data-widget="control-sidebar"
                        {...{
                            onClick: () => setRowAndCellData()
                        }}>
                        {getStockIndicator()}
                    </div>
                </>
            )}
            {!isOnClickColumn() && (
                <div className="pricebox_noonclick" style={divStyle}>
                    {getStockIndicator()}
                </div>
            )}
        </>
    )
}

export { PPStockIndicator }
export default PPTableCellPrice
