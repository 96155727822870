import gql from "graphql-tag"

const INSTANT_CRAWL_SUBSCRIPTION = gql`
    subscription startInstantCrawlSub($channel: String!) {
        instantCrawlSub(channel: $channel) {
            productnr
            shopId
            responseCode
            responseErrorMessage
        }
    }
`

export default INSTANT_CRAWL_SUBSCRIPTION
