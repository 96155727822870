import React from "react"
import "../App.css"
import { useTranslation } from "react-i18next"

function PPMatchingTarifView(props) {
    const [t] = useTranslation()

    let volume = ""
    let effektivpreis = ""
    let laufzeit = ""
    let netz = ""
    Object.entries(props.tarifData?.tarif?.tarifInfoMap)?.map(([propertyName, tarifData]) => {
        if (propertyName === "volume") {
            volume = tarifData.value
        } else if (propertyName === "effektivpreis") {
            effektivpreis = tarifData.value + " €"
        } else if (propertyName === "laufzeit") {
            laufzeit = tarifData.value
        } else if (propertyName === "netz") {
            netz = tarifData.value
        }
    })
    return (
        <ul className="tarif-data">
            <li>
                {t("pricetable.tarif_data.effectiveCosts")}: {effektivpreis}
            </li>
            <li>
                {t("pricetable.tarif_data.duration")}: {laufzeit}
            </li>
            <li>
                {t("pricetable.tarif_data.volume")}: {volume}
            </li>
            <li>
                {t("pricetable.tarif_data.network")}: {netz}
            </li>
        </ul>
    )
}

export default PPMatchingTarifView
