import React from "react"
import "../App.css"
import reportIcon from "../img/dummyReport1.png"

function PPExportTile({ data, t, language, boxsize_css }) {
    function isJson(str) {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    function getName() {
        if (isJson(data.name)) {
            return JSON.parse(data.name)[language]
        } else {
            return data.name
        }
    }

    function getDescription() {
        if (isJson(data.description)) {
            return JSON.parse(data.description)[language]
        } else {
            return data.description
        }
    }

    function getFormattedDate() {
        return new Intl.DateTimeFormat(language, {
            year: "numeric",
            month: "long",
            day: "2-digit"
        }).format(new Date(data.createDate))
    }

    return (
        <div className={"card shadow " + boxsize_css}>
            <div className="card-header">
                <h3 className="card-title">{getName()}</h3>
                <p className="card-subtitle" style={{float: "right"}}>
                    {t("reports.custom_export.create_date_header") + " " + getFormattedDate()}
                </p>
            </div>
            <div className="card-body">
                <img
                    width={"256px"}
                    className={"mx-auto d-block"}
                    src={reportIcon}
                    height={"256px"}
                    alt={"Excel Export"}
                />
                <p>{getDescription()}</p>
            </div>

            <div className="card-footer">
                <a role="button" href={data.downloadLink} className="btn btn-primary float-right reports-button">
                    Download
                    <i className={"far fa-file-excel reports-icon"}/>
                </a>
            </div>
        </div>
    )
}

export default PPExportTile
