import React, { MouseEventHandler } from "react"
import Select, { components, MultiValueGenericProps, MultiValueProps, MultiValueRemoveProps } from "react-select"
import { DndContext } from "@dnd-kit/core"
import { restrictToParentElement } from "@dnd-kit/modifiers"
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import PPUtils from "../utils/PPUtils"

const customStyles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "grey" } : base
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 } : base
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base
    },
    valueContainer: (base) => ({
        ...base,
        padding: "6px 6px"
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0
    })
}

export const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <span /> {children}
        </components.Option>
    )
}

export const MultiValueLabel = ({ children, ...props }) => {
    return <components.MultiValueLabel {...props}>{children}</components.MultiValueLabel>
}

const MultiValue = (props: MultiValueProps) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.data.id
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
            <components.MultiValue {...props} innerProps={innerProps}>
                {props.children}
            </components.MultiValue>
        </div>
    )
}

const MultiValueContainer = (props: MultiValueGenericProps) => {
    return <components.MultiValueContainer {...props} />
}

const MultiValueRemove = (props: MultiValueRemoveProps) => {
    if (props.data.isFixed) {
        return null
    }

    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{
                onPointerDown: (e) => e.stopPropagation(),
                ...props.innerProps
            }}
        />
    )
}

export function PPColumnPreset(props) {
    const [selected, setSelected] = React.useState(props.selectedColumns)

    const onChange = (selectedOptions, { action, removedValue }) => {
        switch (action) {
            case "remove-value":
            case "pop-value":
                if (removedValue.isFixed) {
                    return
                }
                break
            case "clear":
                selectedOptions = selected?.filter((v) => v.isFixed)
                break
        }
        setSelected(selectedOptions)
        props.changeColumns(orderOptions(selectedOptions))
    }

    const onSortEnd = (event) => {
        const { active, over } = event

        if (!active || !over) return

        function findIndex(selected) {
            const oldIndex = selected.findIndex((item) => item.id === active.id)
            const newIndex = selected.findIndex((item) => item.id === over.id)
            return arrayMove(selected, oldIndex, newIndex)
        }
        let newSelected = findIndex(selected)

        setSelected(newSelected)
        props.changeColumns(orderOptions(newSelected))
    }

    const orderOptions = (values) => {
        if (values !== null && values !== undefined) {
            return values
                .filter((v) => {
                    if (v.value === "imageUrl") {
                        return isFixedColumn(v)
                    }
                })
                .concat(
                    values.filter((v) => {
                        if (v.value === "productName") {
                            return isFixedColumn(v)
                        }
                    })
                )
                .concat(
                    values.filter((v) => {
                        if (v.value === "price_de") {
                            return isFixedColumn(v)
                        }
                    })
                )
                .concat(values.filter((v) => !v.isFixed))
        }
    }

    function isFixedColumn(column) {
        if (PPUtils.isFixedColumn(column.value)) {
            column.isFixed = true
            return column
        }
    }

    return (
        <DndContext modifiers={[restrictToParentElement]} onDragEnd={onSortEnd}>
            <SortableContext items={selected} strategy={rectSortingStrategy}>
                <Select
                    className={props.className}
                    isMulti
                    styles={customStyles}
                    options={props.columns}
                    value={orderOptions(selected)}
                    onChange={onChange}
                    components={{
                        Option,
                        MultiValue,
                        MultiValueContainer,
                        MultiValueRemove
                    }}
                    closeMenuOnSelect={false}
                />
            </SortableContext>
        </DndContext>
    )
}
