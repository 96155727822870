import React, { useState } from "react"
import "../App.css"
import countries from "i18n-iso-countries"
import { useTranslation } from "react-i18next"
import Select from "react-select"

function PPExpandedRowUserAdministration(props) {
    const [t, i18n] = useTranslation()

    let configShopsActivated = props?.data?.configShopsActivated?.split(",")

    const [selectedCustomerValue, setSelectedCustomerValue] = useState(
        props?.customers.find((e) => e.value == props?.data?.customerId)
    )

    return (
        <>
            <div className={`custumerbox custumerbox-${props?.data.id} mt-1 mb-1`}>
                <div className="row">
                    <div className="col-3">
                        <label className="customerlabel">Customer</label>
                        <Select
                            className="selectcustomer"
                            options={props?.customers}
                            name="selectcustomer"
                            value={selectedCustomerValue}
                            onChange={(event) => {
                                setSelectedCustomerValue(event)
                            }}
                        />
                    </div>
                    <div className="col-9"></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label className="customerlabel">{t("single.shops")}</label>
                        <ul
                            id={`useradministrationshopslist-${props?.data.id}`}
                            className="useradministrationshopslist">
                            {props?.shops?.map((shop, i) => {
                                let isChecked = false
                                if (configShopsActivated?.find((x) => x == shop.id)) {
                                    isChecked = true
                                }

                                return (
                                    <li key={parseInt(i)}>
                                        <input
                                            type="checkbox"
                                            id={`custom-checkbox-${shop.id}`}
                                            name="useradministrationshopslist[]"
                                            value={shop.id}
                                            defaultChecked={isChecked}
                                        />{" "}
                                        <label htmlFor={`custom-checkbox-${shop.id}`}>
                                            {shop.lang && (
                                                <span
                                                    title={countries.getName(shop.lang, i18n.language)}
                                                    className={"fi fi-" + shop.lang}
                                                />
                                            )}{" "}
                                            {shop.name}
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PPExpandedRowUserAdministration
