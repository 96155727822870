import React, { useEffect, useState } from "react"
import "../App.css"
import { gql, useQuery } from "@apollo/client"

const QUERY = gql`
    query imagecache($urlpath: String) {
        imagecache(urlpath: $urlpath)
    }
`

function PPPictureBoxLoad(props) {
    const [imgLoaded, setImgLoaded] = useState(false)
    const [imgFile, setImgFile] = useState("")

    useQuery(QUERY, {
        displayName: "PPPictureBoxLoad",
        fetchPolicy: "no-cache",
        variables: { urlpath: props?.image },
        onCompleted: (data) => {
            setImgFile(data?.imagecache)
        }
    })

    useEffect(() => {
        setImgLoaded(false)
        setImgFile("")
    }, [props?.image])

    return (
        <>
            <img
                style={imgLoaded ? {} : { display: "none" }}
                className={props?.className}
                src={imgFile}
                alt={props?.alt}
                width={props?.width}
                height={props?.height}
                onLoad={() => setImgLoaded(true)}
            />
            <i
                className="far fa-image fa-5x defaultpictureproduct"
                style={imgLoaded ? { display: "none" } : { display: "unset" }}
            ></i>
        </>
    )
}

export default PPPictureBoxLoad
