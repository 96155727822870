import React, { useEffect, useState } from "react"
import "../App.css"
import { gql, useQuery } from "@apollo/client"
import PPLoadingIndicator from "./PPLoadingIndicator"
import PPErrorDisplay from "./PPErrorDisplay"
import PPHotProductItem from "./PPHotProductItem"
import { useTranslation } from "react-i18next"

const QUERY = gql`
    query getStats($department: String) {
        stats(department: $department) {
            topProducts {
                customerId
                id
                lastPriceChangesOverAllShops {
                    price
                    shopName
                    lang
                    crawlDate
                }
                priceChangesLast5Days
                priceTrendToCustomerPrice
                productImage
                productName
                productnr
                productProducer
                price
                minPrice
            }
        }
    }
`

const QUERY_OWNTOPPRODUCTS = gql`
    query getStatsOwnTopProducts($ownProductsnrs: String) {
        statsOwnTopProducts(ownProductsnrs: $ownProductsnrs) {
            customerId
            id
            lastPriceChangesOverAllShops {
                price
                shopName
                lang
                crawlDate
            }
            priceChangesLast5Days
            priceTrendToCustomerPrice
            productImage
            productName
            productnr
            productProducer
            price
            minPrice
        }
    }
`

function PPTopProducts(props) {
    const [t, i18n] = useTranslation()
    let variables = {
        department:
            props?.changeFilterDepartment != null && props?.changeFilterDepartment?.name != ""
                ? props?.changeFilterDepartment?.name
                : ""
    }
    let query = QUERY

    //overwrite
    let topProductsOwnProductsnrs = localStorage.getItem("dashboard_topproducts_own_nrs")
    if (topProductsOwnProductsnrs != null && topProductsOwnProductsnrs != "" && topProductsOwnProductsnrs != "0") {
        query = QUERY_OWNTOPPRODUCTS
        variables.ownProductsnrs = topProductsOwnProductsnrs
    }

    const { loading, error, data, refetch } = useQuery(query, {
        displayName: "PPTopProductsQuery",
        variables: variables,
        fetchPolicy: "no-cache"
    })
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        refetch()
    }, [props.changeFilterDepartment])

    if (loading) return <PPLoadingIndicator />
    if (error) return <PPErrorDisplay error={error} />

    return (
        <div className="col-lg-12 connectedSortable ui-sortable" id="divTopProducts">
            <div className="card shadow d-flex flex-fill">
                <div className="card-header">
                    <h3>
                        {t("dashboard.top_products")} {data?.statsOwnTopProducts && <>*</>}
                    </h3>
                </div>
                <div className="card-body">
                    {data?.stats?.topProducts != null &&
                        data?.stats?.topProducts
                            .slice(0, showMore ? data?.stats?.topProducts.length : 3)
                            .map((product) => (
                                <PPHotProductItem
                                    key={product.id}
                                    product={product}
                                    t={t}
                                    i18n={i18n}
                                    tariffComparisonMode={props?.tariffComparisonMode}
                                />
                            ))}

                    {data?.statsOwnTopProducts != null &&
                        data?.statsOwnTopProducts
                            .slice(0, showMore ? data?.statsOwnTopProducts.length : 3)
                            .map((product) => (
                                <PPHotProductItem
                                    key={product.id}
                                    product={product}
                                    t={t}
                                    i18n={i18n}
                                    tariffComparisonMode={props?.tariffComparisonMode}
                                />
                            ))}
                </div>
                <div className="card-footer text-center">
                    <a
                        href="#"
                        style={{
                            cursor: "pointer",
                            borderRadius: "0px 0px 3px 3px",
                            color: "grey"
                        }}
                        className={"small-box-footer"}
                        onClick={() => setShowMore(!showMore)}>
                        {showMore ? t("single.less") : t("single.more")}{" "}
                        <i className={showMore ? "fas fa-arrow-circle-up" : "fas fa-arrow-circle-right"} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PPTopProducts
