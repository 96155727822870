import React, { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { PPShopPreset } from "./PPShopPreset"
import { PPColumnPreset } from "./PPColumnPreset"
import PPLoadingIndicator from "./PPLoadingIndicator"
import { PPDefaultSelector } from "./PPDefaultSelector"
import jquery from "jquery"
import PPUtils from "../utils/PPUtils"
import PPUtilsCatFilter from "../utils/PPUtilsCatFilter"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import GraphQL from "../utils/GraphQLClient"
import QUERY_PRODUCER from "../utils/const/QueryProducer"

const UPDATE_PRESET = gql`
    mutation (
        $id: Long
        $name: String
        $shops: [PresetShopFlatInput]
        $columns: [PresetColumnInput]
        $searchword: String
        $searchDepartment: String
        $searchGroup: String
        $searchCategory: String
        $searchProducer: String
    ) {
        updatePreset(
            id: $id
            name: $name
            shops: $shops
            columns: $columns
            searchTitle: $searchword
            searchDepartment: $searchDepartment
            searchGroup: $searchGroup
            searchCategory: $searchCategory
            searchProducer: $searchProducer
        ) {
            id
            name
            columns {
                order
                propertyName
            }
            shops {
                order
                shop {
                    id
                    name
                }
            }
        }
    }
`

const DELETE_PRESET = gql`
    mutation ($presetId: Long!) {
        deletePreset(presetId: $presetId) {
            id
            name
        }
    }
`

const SETTINGS_QUERY = gql`
    query getpreset($id: Long!) {
        preset(presetId: $id) {
            name
            id
            searchDepartment
            searchGroup
            searchCategory
            searchSubcategory
            searchProducer
            searchTitle
            shops {
                order
                shop {
                    label: name
                    shortName
                    lang
                    value: id
                }
            }
            columns {
                order
                label: propertyName
                value: propertyName
            }
        }

        shops(onlyActive: true) {
            label: name
            value: id
            lang
        }

        baseData {
            tariffComparisonMode
            pricetablePropertyNames
            producers {
                label: name
                value: name
            }
            categories {
                ...selectFields
                type
                children {
                    ...selectFields
                    type
                    children {
                        ...selectFields
                        type
                        children {
                            ...selectFields
                            type
                        }
                    }
                }
            }
        }
    }
    fragment selectFields on Category {
        label: name
        value: name
    }
`

export function PPPresetView(props) {
    const [t, i18n] = useTranslation()
    const [tariffComparisonMode, setTariffComparisonMode] = useState("N")
    const [presetName, setPresetName] = useState("")
    const [searchword, setSearchword] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedProductGroup, setSelectedProductGroup] = useState(null)
    const [selectedProductCat, setSelectedProductCat] = useState(null)
    const [selectedProducer, setSelectedProducer] = useState(null)

    const [dataformatGroups, setDataformatGroups] = useState(null)
    const [dataformatCats, setDataformatCats] = useState(null)
    const [dataProducer, setDataProducer] = useState(null)

    const [shops, setShops] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (selectedDepartment != null) {
            let variables = {
                department: selectedDepartment ? selectedDepartment.value : "",
                productGroup: selectedProductGroup ? selectedProductGroup.value : "",
                productCat: selectedProductCat ? selectedProductCat.value : ""
            }
            GraphQL.getClient()
                .request(QUERY_PRODUCER, variables)
                .then((data) => {
                    setDataProducer(data?.getProducerByQuery?.producerList)
                })
                .catch((error) => console.error(error))
        }
    }, [selectedDepartment, selectedProductGroup, selectedProductCat])

    const { loading, error, data, refetch } = useQuery(SETTINGS_QUERY, {
        skip: !props.presetId,
        variables: { id: props.presetId },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setTariffComparisonMode(data.baseData.tariffComparisonMode)

            let shops = []
            data.preset.shops.forEach(function (el) {
                if (data.shops.find((e) => e.value == el.shop.value)) {
                    el.shop.id = el.shop.value
                    shops.push(el.shop)
                }
            })
            setShops(shops)

            setColumns(data.preset.columns)
            setSearchword(data.preset.searchTitle)
            setPresetName(data.preset.name)
            setDataProducer(data?.baseData?.producers)

            let t_dataformatGroups = PPUtilsCatFilter.formatGroups(data.baseData.categories, "PRODUCTGROUP")
            let t_dataformatCats = PPUtilsCatFilter.formatGroups(t_dataformatGroups, "PRODUCTCAT")
            setDataformatGroups(t_dataformatGroups)
            setDataformatCats(t_dataformatCats)

            setSelectedDepartment(
                PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchDepartment, data.baseData.categories)
            )
            setSelectedProductGroup(PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchGroup, t_dataformatGroups))
            setSelectedProductCat(PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchCategory, t_dataformatCats))
            setSelectedProducer(
                PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchProducer, data.baseData.producers)
            )

            jquery("#collapseEditPreset").show("show") //klappt nicht mehr show direkt in collapseEditPreset

            props.setRefetchPreset({ ...{ refetch } })
        }
    })

    const [updatePreset] = useMutation(UPDATE_PRESET, {
        onCompleted: (data2) => {
            jquery("#collapseEditPreset").collapse("hide")
            toast(t("settings.preset.toast_save_ok", { name: data2.updatePreset?.name }))
            props.changePresetId(data2.updatePreset.id)
            props.refetchData()
        },
        onError: (error2) => {
            if (error2.message.toString().includes("Duplicate entry")) {
                toast.error("settings.preset.toast_error_duplicate")
            } else {
                toast.error(t("settings.preset.toast_error"))
            }
        }
    })

    const [deletePreset] = useMutation(DELETE_PRESET, {
        onCompleted: (data2) => {
            jquery("#collapseEditPreset").collapse("hide")
            toast(t("settings.preset.toast_delete_ok"))
            props.refetchData()
            if (props.presets.length > 0) {
                props.changePresetId(
                    parseFloat(props.presets.find((e) => parseFloat(e.value) !== data2.deletePreset?.id)?.value)
                )
            }
        },
        onError: () => {
            toast.error(t("settings.preset.toast_error_delete"))
        }
    })

    if (loading) return <PPLoadingIndicator />
    if (error) {
        return <p>Error :(</p>
    }
    if (!data) return null

    function formatProductGroup() {
        if (selectedDepartment === null || selectedDepartment.children === undefined) {
            return dataformatGroups
        }
        return selectedDepartment.children.length > 0 ? selectedDepartment.children : []
    }

    function formatProductCat() {
        if (
            selectedDepartment === null ||
            selectedProductGroup === null ||
            selectedProductGroup.children === undefined
        ) {
            return dataformatCats
        }
        return selectedProductGroup.children.length > 0 ? selectedProductGroup.children : []
    }

    return (
        <div className="row collapse show mt-2" id="collapseEditPreset">
            <div className="col-12 mt-2">
                <form>
                    <div className="form-row">
                        <div className="col mx-1">
                            <div className="form-group">
                                <label htmlFor="select1">{t("single.department")}</label>
                                <PPDefaultSelector
                                    id="selectPresetDepartment"
                                    name="department"
                                    data={data.baseData.categories}
                                    value={selectedDepartment}
                                    onChange={(optionSelected) => {
                                        setSelectedDepartment(optionSelected)
                                        setSelectedProductGroup(null)
                                        setSelectedProductCat(null)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="select2">{t("single.product_group")}</label>
                                <PPDefaultSelector
                                    id="selectPresetProductgroup"
                                    name="productgroup"
                                    data={formatProductGroup()}
                                    onChange={(optionSelected) => {
                                        setSelectedProductGroup(optionSelected)
                                        setSelectedProductCat(null)
                                    }}
                                    value={selectedProductGroup}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="select3">{t("single.product_category")}</label>
                                <PPDefaultSelector
                                    id="selectPresetProductcat"
                                    name="productcat"
                                    data={formatProductCat()}
                                    onChange={(optionSelected) => {
                                        setSelectedProductCat(optionSelected)
                                    }}
                                    value={selectedProductCat}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="select4">
                                    {PPUtils.isTariffComparisonMode(tariffComparisonMode)
                                        ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                                        : t("single.manufacturer")}
                                </label>
                                <PPDefaultSelector
                                    id="selectPresetProducer"
                                    name="producer"
                                    data={dataProducer}
                                    onChange={(optionSelected) => {
                                        setSelectedProducer(optionSelected)
                                    }}
                                    value={selectedProducer}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="select5">{t("single.column")}</label>
                                <PPColumnPreset
                                    id="selectPresetColumn"
                                    columns={data?.baseData?.pricetablePropertyNames?.map((x) => ({
                                        id: x,
                                        label: PPUtils.getMappedColumnName(x, t, i18n),
                                        value: x
                                    }))}
                                    selectedColumns={data?.preset?.columns?.map((x) => ({
                                        id: x.value,
                                        label: PPUtils.getMappedColumnName(x.label, t, i18n),
                                        value: x.value,
                                        isFixed: PPUtils.isFixedColumn(x.label)
                                    }))}
                                    changeColumns={setColumns}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="select6">Shops</label>
                                <PPShopPreset
                                    id="selectPresetShops"
                                    shops={data.shops}
                                    selectedShops={data.preset.shops.map(function (x) {
                                        if (data.shops.find((e) => e.value == x.shop.value)) {
                                            return x.shop
                                        }
                                    })}
                                    changeShops={setShops}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="select7">{t("single.search_keyword")}</label>
                                <input
                                    onChange={(e) => {
                                        setSearchword(e.target.value)
                                    }}
                                    type="input"
                                    className="form-control"
                                    id="inputSearchword"
                                    placeholder={t("single.search_word")}
                                    value={searchword || ""}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="select8">{t("single.presetname")}</label>
                                <input
                                    onChange={(e) => {
                                        setPresetName(e.target.value)
                                    }}
                                    className="form-control"
                                    type="input"
                                    id="inputPresetName"
                                    value={presetName}
                                />
                            </div>
                            <div className="form-row">
                                <div className="col d-md-flex justify-content-md-end">
                                    <button
                                        id="buttonPresetSave"
                                        className="btn btn-primary mx-2"
                                        type="button"
                                        onClick={() => {
                                            updatePresetCall(false)
                                        }}>
                                        {t("single.save")}
                                    </button>
                                    <button
                                        id="buttonPresetSaveAsNewPreset"
                                        className="btn btn-primary mx-0"
                                        type="button"
                                        onClick={() => {
                                            updatePresetCall(true)
                                        }}>
                                        {t("settings.preset.save_as_new_preset")}
                                    </button>
                                    <button
                                        id="buttonPresetDelete"
                                        className="btn btn-danger ml-2"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#deletePresetModal">
                                        {t("single.delete")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div
                className="modal fade"
                id="deletePresetModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="deletePresetModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deletePresetModalLabel">
                                Preset {data.preset.name}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {(props.presets.length > 1 && (
                            <div className="modal-body">{t("settings.preset.question_delete_preset")}</div>
                        )) || <div className="modal-body">{t("settings.preset.info_min_preset")}</div>}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.cancel")}
                            </button>
                            {props.presets.length > 1 && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        deletePresetCall()
                                    }}>
                                    {t("single.delete")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function updatePresetCall(isNewEntry) {
        let variables = {
            id: isNewEntry ? null : props.presetId,
            name: presetName === null ? data.preset.name : presetName,
            shops:
                shops === null || shops === undefined
                    ? []
                    : shops.map((shop, index) => ({ order: index, shopId: shop.value })),
            columns:
                columns === null || columns === undefined
                    ? []
                    : columns.map((column, index) => ({ order: index, propertyName: column.value })),
            searchword: searchword,
            searchDepartment: selectedDepartment ? selectedDepartment.value : "",
            searchGroup: selectedProductGroup ? selectedProductGroup.value : "",
            searchCategory: selectedProductCat ? selectedProductCat.value : "",
            searchProducer: selectedProducer ? selectedProducer.value : ""
        }
        updatePreset({ variables: variables })
    }

    function deletePresetCall() {
        let variables = {
            presetId: props.presetId
        }
        deletePreset({ variables: variables })
    }
}
