import React from "react"
import "../App.css"
import { useParams } from "react-router-dom"

export default function PPIframe({ url }) {
    let { param1, param2, param3 } = useParams()
    if (param1 && param2 && param3) url += "/" + param1 + "/" + param2 + "/" + param3

    return (
        <div className="embed-content-box">
            <iframe id="iFrame" className="embed-responsive-item-special" title="ruleengine" src={url} />
        </div>
    )
}
