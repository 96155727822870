import React from "react"
import "../App.css"
import { useTranslation } from "react-i18next"

function RuleEngineNotEnabledPage() {
    const [t] = useTranslation()
    return (
        <div className="card shadow">
            <div className="card-body">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <h1 className="mb-5 text-bold">{t("pricemanagementDisabledPage.title")}</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col card">
                                    <div className="card-body">
                                        <i className="fas fa-cogs fa-5x my-2 text-gray" />
                                        <h3>{t("pricemanagementDisabledPage.rule_engine")}</h3>
                                        <p className="lead">{t("pricemanagementDisabledPage.rule_engine_info")}</p>
                                    </div>
                                </div>
                                <div className="col card mx-4">
                                    <div className="card-body">
                                        <i className="fas fa-binoculars fa-5x my-2" style={{ color: "#004c25" }} />
                                        <h3>{t("pricemanagementDisabledPage.monitoring")}</h3>
                                        <p className="lead">{t("pricemanagementDisabledPage.monitoring_info")}</p>
                                    </div>
                                </div>
                                <div className="col card">
                                    <div className="card-body">
                                        <i className="fas fa-euro-sign fa-5x my-2 text-yellow" />
                                        <h3>{t("pricemanagementDisabledPage.personnel_costs")}</h3>
                                        <p className="lead">{t("pricemanagementDisabledPage.personnel_costs_info")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row card my-4">
                                <div className="col card-body">
                                    <h4>{t("pricemanagementDisabledPage.contact_text")}</h4>
                                    <a
                                        className="btn btn-primary btn-lg mt-4"
                                        href="mailto:info@sylphen.com?subject=Preis Management Suite"
                                    >
                                        {t("pricemanagementDisabledPage.contact")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RuleEngineNotEnabledPage
