import _ from "lodash"

class PPUtilsCatFilter {
    static formatGroups(categories, type) {
        return PPUtilsCatFilter.removeDuplicates(
            categories.reduce(function (previous, current) {
                return previous.concat(_.filter(current.children, { type: type }))
            }, [])
        )
    }

    static removeDuplicates(arr) {
        var uLabelSet = []
        var uArr = []
        arr.forEach((item) => {
            if (uLabelSet.indexOf(item.label) === -1) {
                uArr.push(item)
                uLabelSet.push(item.label)
            }
        })
        return uArr
    }

    static setSelectDefaultValue(value, data) {
        var r_value = null
        if (value != "") {
            var result = data?.find((e) => e.value == value)
            if (result) {
                r_value = result
            }
        }
        return r_value
    }

    static getEmptyObject() {
        return {
            name: "",
            value: "",
            label: "",
            type: "DEPARTMENT"
        }
    }
}

export default PPUtilsCatFilter
