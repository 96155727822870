import "admin-lte/plugins/bootstrap/js/bootstrap.bundle"
import "admin-lte/plugins/jquery/jquery"
import "admin-lte/plugins/fontawesome-free/css/all.css"
import "admin-lte/dist/css/adminlte.css"

import packageInfo from "../../package.json"

import React, { useEffect, useState } from "react"

import "react-toastify/dist/ReactToastify.css"
import "../App.css"
import "../App_Darkmode.css"

import "datatables.net-bs4/css/dataTables.bootstrap4.css"
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css"
import "react-bootstrap-multiselect/css/bootstrap-multiselect.css"
import "admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"

import { Routes, Route, NavLink } from "react-router-dom"

import jquery from "jquery"
import PPDashboard from "./PPDashboard"
import PPLoginPage from "./PPLoginPage"
import PPPriceTable from "./PPPriceTable"
import { RightTabContent } from "../components/PPRightTab"
import PPReports from "./PPReports"
import useRequireAuth from "../hooks/use-require-auth"
import * as PropTypes from "prop-types"
import { TopRightToolbar } from "../components/TopRightToolbar"
import { SearchBar } from "../components/Searchbar"
import PPIframe from "../components/PPIframe"
import PPStatistics from "./PPStatistics"
import PPSettings from "./PPSettings"
import PPCrawler from "./PPCrawler"
import PPAdmin from "./PPAdmin"
import PPUtils from "../utils/PPUtils"
import { useSubscription } from "@apollo/client"
import { ToastContainer, toast } from "react-toastify"
import INSTANT_CRAWL_SUBSCRIPTION from "../utils/const/InstantCrawlSub"
import ExportSubscription from "../utils/const/ExportSub"
import ExportToastMsg from "../utils/const/ExportToastMsg"
import RuleEngineNotEnabledPage from "./RuleEngineNotEnabledPage"
import PPSelectCustomers from "../components/PPSelectCustomers"
import { useTranslation } from "react-i18next"
import MappingState from "../utils/const/MappingState"
import PPPriceAlertsSetting from "./PPPriceAlertsSetting"

window.$ = window.jQuery = jquery
require("admin-lte/dist/js/adminlte")

SearchBar.propTypes = { onSubmit: PropTypes.func }

function App() {
    const [t, i18n] = useTranslation()
    const [searchword, setSearchword] = useState("")
    const [selectedProductCell, setSelectedProductCell] = useState()
    const [selectedRowInfos, setSelectedRowInfos] = useState()
    const [refetchPricetable, setRefetchPricetable] = useState()
    const [productCount, setProductCount] = useState("")
    const [lightMode, setLightMode] = useState(!PPUtils.activDarkmode())
    const [presetId, setPresetId] = useState(null)
    const [dataCurrentAuth, setDataCurrentAuth] = useState()

    const [authName, setAuthName] = useState("")
    const [authAvatarUrl, setAuthAvatarUrl] = useState("")

    const settingLocalstorage = {
        PPPriceSettings: [
            { name: "switchPriceAge", defaultValue: false },
            { name: "switchStock", defaultValue: true },
            { name: "switchCustomerStock", defaultValue: false },
            { name: "switchCurrency", defaultValue: false },
            { name: "mappingState", defaultValue: MappingState.MAPPED_AND_UNMAPPED },
            { name: "sortRanking", defaultValue: false },
            { name: "showTotalPrice", defaultValue: false },
            { name: "showLongShopname", defaultValue: false },
            { name: "showPriceDifferenceThumb", defaultValue: true },
            { name: "default_language_stats", defaultValue: "de" },
            { name: "dashboard_topproducts_own_nrs", defaultValue: "" },
            { name: "switchShowLinks", defaultValue: false },
            { name: "switchDiscontinuedItems", defaultValue: false }
        ]
    }

    const handleSubmit = (event) => {
        setSearchword(event.target.searchinput.value)
        event.preventDefault()
        if (event.target.searchinput.value.length > 0) {
            window.location.replace("/pricetable/s/" + encodeURIComponent(event.target.searchinput.value))
        } else {
            window.location.replace("/pricetable/")
        }
    }
    const auth = useRequireAuth()
    const currentAuth = PPUtils.getCurrentAuthFromAuth(auth)

    const userId = currentAuth?.user?.id

    useEffect(() => {
        if (currentAuth) {
            let title = currentAuth?.customer?.name ? " - " + currentAuth?.customer?.name : ""
            document.title = t("pp.title") + title
        }
        if (currentAuth?.user?.configLanguage) {
            i18n.changeLanguage(currentAuth?.user?.configLanguage)
        }

        if (presetId !== null && presetId !== undefined) {
            localStorage.setItem("presetId", presetId)
        } else if (Number(localStorage.getItem("presetId")) !== 0) {
            setPresetId(Number(localStorage.getItem("presetId")))
        }

        if (currentAuth?.user?.name) {
            setAuthName(currentAuth?.user?.name)
        }
        if (currentAuth?.user?.avatarUrl != "") {
            setAuthAvatarUrl(currentAuth?.user?.avatarUrl)
        } else {
            setAuthAvatarUrl("")
        }
    }, [currentAuth, presetId])

    useEffect(() => {
        if (currentAuth && dataCurrentAuth) {
            if (dataCurrentAuth.name) {
                setAuthName(dataCurrentAuth.name)
            }
            if (dataCurrentAuth.avatarUrl != "") {
                setAuthAvatarUrl(dataCurrentAuth.avatarUrl)
            } else {
                setAuthAvatarUrl("")
            }
        }
    }, [dataCurrentAuth])

    const notifySuccess = (msg) => {
        toast(msg)
    }
    const notifyError = (msg) => {
        toast.error(msg)
    }

    useSubscription(INSTANT_CRAWL_SUBSCRIPTION, {
        variables: { channel: "InstantCrawls" + userId },
        onData: ({ data: { data } }) => {
            if (data !== undefined && data.instantCrawlSub.responseCode === 200) {
                notifySuccess(t("subscription.instantcrawl_ready", { productnr: data.instantCrawlSub.productnr }))
                jquery("button#sidebarclose").trigger("click")
                refetchPricetable?.refetch()
            } else if (data !== undefined && data.instantCrawlSub.responseCode === 500) {
                notifyError(
                    t("subscription.instantcrawl_failed", {
                        productnr: data.instantCrawlSub.productnr,
                        responseErrorMessage: data.instantCrawlSub.responseErrorMessage
                    })
                )
            }
        }
    })

    useSubscription(ExportSubscription.GQL.EXPORTS_SUBSCRIPTION, {
        variables: { channel: "Exports" + userId },
        onData: ({ data: { data } }) => {
            if (data !== undefined && data.exportSub.state === ExportSubscription.States.READY) {
                toast(<ExportToastMsg exportData={data.exportSub} />, {
                    autoClose: false,
                    closeOnClick: false
                })
            } else if (data !== undefined && data.exportSub.state === ExportSubscription.States.FAILED) {
                notifyError(t("subscription.export_failed", { name: data.exportSub.name }))
            }
        }
    })

    if (!auth.login || auth.isExpired()) {
        return <PPLoginPage changePresetId={setPresetId} />
    }

    PPUtils.setGenericTranslation(auth, i18n)
    PPUtils.setSettingsLocalstorage(settingLocalstorage)

    jquery("body")
        .removeClass("pricetable_datatable_fixedheader_activ")
        .removeClass("filtercat_open filtershop_open filterprice_open")

    jquery(document).ready(function () {
        jquery("body").tooltip({ selector: "[data-toggle=tooltip]" })
    })

    return (
        <Routes>
            <Route path="/loginpage" element={<PPLoginPage changePresetId={setPresetId} />} />

            <Route
                path="*"
                element={
                    <div className="wrapper">
                        <nav className={"main-header navbar navbar-expand navbar-white navbar-light"}>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" data-widget="pushmenu" role="button">
                                        <i className="fas fa-bars" />
                                    </a>
                                </li>
                                <li className="nav-item d-none d-sm-inline-block">
                                    <a href="/" className="nav-link">
                                        Home
                                    </a>
                                </li>
                            </ul>

                            <SearchBar
                                onSubmit={handleSubmit}
                                searchword={searchword}
                                setLightMode={setLightMode}
                                lightMode={lightMode}
                            />
                            <TopRightToolbar customerLogo={currentAuth?.customer?.logoUrl} />
                        </nav>

                        <aside
                            className={`main-sidebar elevation-3 ${
                                lightMode == true ? "sidebar-light-primary" : "sidebar-dark-primary"
                            }`}>
                            <div className="sidebar">
                                <div id="sidebar-logo"></div>
                                <PPSelectCustomers changePresetId={setPresetId} />
                                <div className="user-panel pt-3 pb-3 mb-3 d-flex">
                                    <div className="image">
                                        {authAvatarUrl ? (
                                            <img src={authAvatarUrl} className="img-circle elevation-2" alt="" />
                                        ) : (
                                            PPUtils.getDummyAvatarString(authName, 33, "img-circle elevation-2")
                                        )}
                                    </div>
                                    <div className="info">
                                        <a className="d-block">{authName}</a>
                                    </div>
                                </div>
                                <nav className="mt-2">
                                    <ul
                                        className="nav nav-pills nav-sidebar flex-column nav-flat"
                                        data-widget="treeview"
                                        role="menu"
                                        data-accordion="false">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link">
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>{t("app.menu.dashboard")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/pricetable" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>
                                                    {t("app.menu.pricetable")} {productCount && <b>({productCount})</b>}
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/extmatching" className="nav-link">
                                                <i className="nav-icon fas fa-check"></i>
                                                <p>{t("app.menu.matching")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/statistics" className="nav-link">
                                                <i className="nav-icon fas fa-chart-bar"></i>
                                                <p>{t("app.menu.statistics")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/reports" className="nav-link">
                                                <i className="nav-icon fas fa-chart-pie"></i>
                                                <p>{t("app.menu.reports")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/extpms" className="nav-link">
                                                <i className="nav-icon fas fa-tasks"></i>
                                                <p>{t("app.menu.pricemanagementsuite")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/pricealerts" className="nav-link">
                                                <i className="nav-icon fas fa-bell"></i>
                                                <p>{t("app.menu.pricealerts")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/settings" className="nav-link">
                                                <i className="nav-icon fas fa-cogs"></i>
                                                <p>{t("app.menu.settings")}</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/admin" className="nav-link">
                                                <i className="nav-icon fas fa-tools"></i>
                                                <p>{auth.isAdmin() ? "Admin" : t("single.shops")}</p>
                                            </NavLink>
                                        </li>
                                        {auth.isAdmin() ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/crawler" className="nav-link">
                                                        <i className="nav-icon fas fa-spider"></i>
                                                        <p>{t("app.menu.crawler")}</p>
                                                    </NavLink>
                                                </li>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <li className="nav-item">
                                            <NavLink
                                                to="/loginpage"
                                                onClick={() => auth.signout()}
                                                className="nav-link">
                                                <i className="nav-icon fas fa-sign-out-alt"></i>
                                                <p>{t("app.menu.logout")}</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="sidebarFooter">
                                <div className="normal_box">
                                    <strong>
                                        {t("pp.title")} {packageInfo.version} - {t("pp.copyright")} &copy; 2014-
                                        {new Date().getFullYear()} <a href="https://www.sylphen.com">Sylphen</a>.
                                    </strong>
                                    &nbsp;{t("pp.all_rights_reserved")}.
                                </div>
                                <div className="small_box">
                                    <strong>PP {packageInfo.version}</strong>
                                </div>
                            </div>
                            <ToastContainer />
                        </aside>

                        <div className="content-wrapper">
                            <div className="content pt-3 pb-2">
                                <div className="container-fluid">
                                    <Routes>
                                        <Route path="/statistics" element={<PPStatistics />} />
                                        <Route path="/reports" element={<PPReports userId={userId} />} />
                                        <Route
                                            path="/settings"
                                            element={
                                                <PPSettings
                                                    changePresetId={setPresetId}
                                                    presetId={presetId}
                                                    settingLocalstorage={settingLocalstorage}
                                                    setDataCurrentAuth={setDataCurrentAuth}
                                                />
                                            }
                                        />
                                        <Route path="/crawler" element={auth?.isAdmin() ? <PPCrawler /> : ""} />
                                        <Route path="/admin" element={<PPAdmin />} />
                                        {["/matching/:param1/:param2/:param3", "/extmatching"].map((path, index) => {
                                            let i = index + 1
                                            return (
                                                <Route
                                                    path={path}
                                                    element={<PPIframe url={"/matching/login/" + currentAuth?.token} />}
                                                    key={i}
                                                />
                                            )
                                        })}
                                        <Route
                                            path="/extpms"
                                            element={
                                                currentAuth?.user?.ruleEngineAccess ? (
                                                    <PPIframe url={"/RuleEngine/?token=" + currentAuth?.token} />
                                                ) : (
                                                    <RuleEngineNotEnabledPage />
                                                )
                                            }
                                        />
                                        <Route
                                            path="/pricealerts"
                                            element={
                                                <PPPriceAlertsSetting
                                                    userId={userId}
                                                    customerId={currentAuth?.user?.customerId}
                                                />
                                            }
                                        />
                                        {[
                                            "/pricetable",
                                            "/pricetable/s/:search",
                                            "/pricetable/:filter/:where/:sortProperty",
                                            "/pricetable/:filter/:where/generic/:genericFilterProperty"
                                        ].map((path, index) => {
                                            let i = index + 1
                                            return (
                                                <Route
                                                    path={path}
                                                    element={
                                                        <PPPriceTable
                                                            searchword={searchword}
                                                            setSearchword={setSearchword}
                                                            changeSelectedCell={setSelectedProductCell}
                                                            changeSelectedRow={setSelectedRowInfos}
                                                            productCountChange={setProductCount}
                                                            changePresetId={setPresetId}
                                                            presetId={presetId}
                                                            userId={userId}
                                                            setRefetchPricetable={setRefetchPricetable}
                                                        />
                                                    }
                                                    key={i}
                                                />
                                            )
                                        })}
                                        <Route path="/" element={<PPDashboard />} />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                        <aside
                            id="rightsidebar"
                            className="control-sidebar control-sidebar-light layout-fixed bg-light">
                            <RightTabContent
                                selectedProductCell={selectedProductCell}
                                selectedProductRow={selectedRowInfos}
                                presetId={presetId}
                                customerId={currentAuth?.user?.customerId}
                                userId={userId}
                                refetchPricetable={refetchPricetable}
                            />
                        </aside>
                    </div>
                }
            />
        </Routes>
    )
}

export default App
