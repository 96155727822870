import { useTranslation } from "react-i18next"

function ExportToastMsg(props) {
    const [t] = useTranslation()
    return (
        <div>
            <p>{t("subscription.export_ready", { name: props.exportData.name })}</p>
            <a
                role="button"
                href={props.exportData.downloadLink}
                className="btn btn-primary reports-button"
                onClick={props.closeToast}
            >
                {t("single.download")}
                <i className={"far fa-file-excel reports-icon"} />
            </a>
        </div>
    )
}

export default ExportToastMsg
