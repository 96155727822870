import React, { useEffect, useMemo } from "react"
import "../App.css"
import { gql, useQuery } from "@apollo/client"
import PPLoadingIndicator from "./PPLoadingIndicator"
import PPErrorDisplay from "./PPErrorDisplay"
import { useTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import countries from "i18n-iso-countries"
import PPExpandedRowStatTableCountPriceLower from "./PPExpandedRowStatTableCountPriceLower"

const QUERY = gql`
    query getStats($department: String) {
        stats(department: $department) {
            statTableCountPriceLower {
                shopId
                name
                shortName
                lang

                countAllPrice
                countPriceLower
                procentPriceLower
                procentAverageDiscount

                mostPriceChangeHotProducts {
                    customerId
                    id
                    lastPriceChangesOverAllShops {
                        price
                        shopName
                        lang
                        crawlDate
                    }
                    priceChangesLast5Days
                    priceTrendToCustomerPrice
                    productImage
                    productName
                    productnr
                    productProducer
                    price
                    minPrice
                }
            }
        }
    }
`

const CustomRowShopname = ({ row }) => {
    const [i18n] = useTranslation()

    return (
        <div className="mt-2 mb-2">
            <div>
                <span title={countries.getName(row.lang, i18n.language)} className={"fi fi-" + row.lang} /> {row.name}
            </div>
        </div>
    )
}

function PPStatTableCountPriceLower(props) {
    const [t] = useTranslation()
    let query = QUERY
    let variables = {
        department:
            props?.changeFilterDepartment != null && props?.changeFilterDepartment?.name != ""
                ? props?.changeFilterDepartment?.name
                : ""
    }

    const { loading, error, data, refetch } = useQuery(query, {
        displayName: "PPStatTableCountPriceLower",
        variables: variables,
        fetchPolicy: "no-cache"
    })

    useEffect(() => {
        refetch()
    }, [props.changeFilterDepartment])

    const columns = useMemo(() => [
        {
            name: t("dashboard.stattablecountpricelower_products.col_1"),
            selector: (row) => row.name,
            cell: (row) => <CustomRowShopname row={row} />
        },
        {
            name: t("dashboard.stattablecountpricelower_products.col_2"),
            selector: (row) => row.countAllPrice,
            sortable: true
        },
        {
            name: t("dashboard.stattablecountpricelower_products.col_3"),
            selector: (row) => row.countPriceLower,
            sortable: true
        },
        {
            name: t("dashboard.stattablecountpricelower_products.col_4"),
            selector: (row) => row.procentPriceLower,
            sortable: true,
            cell: (row) => row?.procentPriceLower?.toFixed(2)
        },
        {
            name: t("dashboard.stattablecountpricelower_products.col_5"),
            selector: (row) => row.procentAverageDiscount,
            sortable: true,
            cell: (row) => row?.procentAverageDiscount?.toFixed(2)
        }
    ])

    if (loading) return <PPLoadingIndicator />
    if (error) return <PPErrorDisplay error={error} />

    return (
        <div className="col-lg-12">
            <div className="card shadow d-flex flex-fill">
                <div className="card-header">
                    <h3>{t("dashboard.stattablecountpricelower_products.header")}</h3>
                </div>
                <div className="card-body datatable_headerbold">
                    {data?.stats?.statTableCountPriceLower?.length > 0 ? (
                        <DataTable
                            noHeader
                            columns={columns}
                            data={data?.stats?.statTableCountPriceLower}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30]}
                            defaultSortField="procentAverageDiscount"
                            expandOnRowClicked
                            expandableRows
                            expandableRowsComponent={PPExpandedRowStatTableCountPriceLower}
                            expandableRowsComponentProps={{ tariffComparisonMode: props?.tariffComparisonMode }}
                        />
                    ) : (
                        t("single.no_results")
                    )}
                </div>
            </div>
        </div>
    )
}

export default PPStatTableCountPriceLower
