import React, { useEffect, useRef } from "react"
import jquery from "jquery"
import PPrightTabAllShops from "./PPRightTabAllShops"
import PPrightTabMatching from "./PPRightTabMatching"
import PPrightTabShopInfo from "./PPRightTabShopInfo"

import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import GraphQL from "../utils/GraphQLClient"
import { useTranslation } from "react-i18next"

const START_INSTANT_CRAWL_MUTATION = gql`
    mutation StartInstantCrawl(
        $location: String!
        $shopId: Int!
        $productnr: String!
        $customerId: Long!
        $userId: Long!
        $isManualMatch: Int!
    ) {
        startInstantCrawl(
            location: $location
            shopId: $shopId
            productnr: $productnr
            customerId: $customerId
            userId: $userId
            isManualMatch: $isManualMatch
        ) {
            responseCode
            responseMessage
        }
    }
`
const START_PRICE_CONVERSION_MUTATION = gql`
    mutation StartPriceConversionByCustomFactor(
        $location: String!
        $shopId: Int!
        $productnr: String!
        $customerId: Long!
        $userId: Long!
        $conversionFormula: String!
    ) {
        startPriceConversionByCustomFactor(
            location: $location
            shopId: $shopId
            productnr: $productnr
            customerId: $customerId
            userId: $userId
            conversionFormula: $conversionFormula
        ) {
            responseCode
            responseMessage
        }
    }
`

const query_editinfomapping = `mutation($shopId: Int!, $productNrFrom: String, $editinfo: String) {
        editinfoMapping(
            shopId: $shopId
            productNrFrom: $productNrFrom
            editinfo: $editinfo
        )
    }
`

export function RightTabContent(props) {
    const [t] = useTranslation()

    const shopId = props.selectedProductRow?.shopId
    const productnr = props.selectedProductRow?.productNr
    const customerId = props.customerId !== null && props.customerId !== undefined ? props.customerId : -1
    const userId = props.userId !== null && props.userId !== undefined ? props.userId : null

    useEffect(() => {
        jquery("div#row-" + props?.selectedProductRow?.id).addClass("rdt_TableRow__righttabactiv")
        jquery(".content-wrapper, .main-sidebar, button#sidebarclose").click(function (event) {
            jquery("div#row-" + props?.selectedProductRow?.id).removeClass("rdt_TableRow__righttabactiv")
            if (jquery("body").hasClass("control-sidebar-slide-open")) {
                jquery("#rightsidebar").ControlSidebar("toggle")
                event.stopPropagation()
            }
        })
    })

    const notifySuccess = (productNr) => {
        toast("IC für " + productNr + " wurde gestartet!")
    }

    const notifyError = (responseMessage) => {
        toast.error(responseMessage)
    }

    let icBtnRef = useRef()
    let priceConversionBtnRef = useRef()

    const showErrorResponse = (_response, { data }) => {
        if (data !== undefined && data.startInstantCrawl.responseCode !== 200) {
            console.log(
                "Beim starten des Instant Crawls ist ein Fehler aufgetreten: " + data.startInstantCrawl.responseMessage
            )
            notifyError("Fehler beim Starten des InstantCrawls.\n Bitte erneut versuchen.")
        }
    }

    const [startIC] = useMutation(START_INSTANT_CRAWL_MUTATION, { update: showErrorResponse })

    const onIcBtnClick = (location, isManualMatch = 0) => {
        if (icBtnRef.current) {
            icBtnRef.current.setAttribute("disabled", true)
        }
        startIC({ variables: { location, shopId, productnr, customerId, userId, isManualMatch } })
        notifySuccess(productnr)
        if (icBtnRef.current) {
            setTimeout(() => icBtnRef.current.removeAttribute("disabled"), 5000)
        }
    }

    const [startPriceConversion] = useMutation(START_PRICE_CONVERSION_MUTATION, { update: showErrorResponse })

    const onConversionBtnClick = (conversionFormula) => {
        if (priceConversionBtnRef.current) {
            priceConversionBtnRef.current.setAttribute("disabled", "disabled")
        }
        startPriceConversion({
            variables: {
                location: props.selectedProductCell?.url,
                shopId: shopId,
                productnr: productnr,
                customerId: customerId,
                userId: userId,
                conversionFormula: conversionFormula
            }
        })
        notifySuccess(productnr)
        if (priceConversionBtnRef.current) {
            setTimeout(() => priceConversionBtnRef.current.removeAttribute("disabled"), 5000)
        }
    }

    const saveCommentMappingEditinfo = (editinfo) => {
        let variables = {
            shopId: shopId,
            productNrFrom: productnr,
            editinfo: editinfo
        }

        GraphQL.getClient()
            .request(query_editinfomapping, variables)
            .then(() => {
                toast(t("pricetable.righttab.comment_tab.toast_msg_saved"), {
                    autoClose: 500,
                    onClose: () => {
                        jquery("button#sidebarclose").trigger("click")
                        props?.refetchPricetable?.refetch()
                    }
                })
            })
            .catch((error) => console.error(error))
    }

    if (props.selectedProductCell === undefined && props.selectedProductCell !== null) {
        return <PPrightTabAllShops selectedProductRow={props.selectedProductRow} />
    } else if (props.selectedProductCell === null) {
        return (
            <PPrightTabMatching
                selectedProductRow={props.selectedProductRow}
                onIcButtonClick={onIcBtnClick}
                icBtnRef={icBtnRef}
                refetchPricetable={props.refetchPricetable}
                saveCommentMappingEditinfo={saveCommentMappingEditinfo}
            />
        )
    }

    //onclick Hack redraw siehe https://github.com/apexcharts/vue-apexcharts/issues/93
    return (
        <PPrightTabShopInfo
            customerId={customerId}
            selectedProductCell={props.selectedProductCell}
            selectedProductRow={props.selectedProductRow}
            onIcButtonClick={onIcBtnClick}
            onConversionBtnClick={onConversionBtnClick}
            icBtnRef={icBtnRef}
            priceConversionBtnRef={priceConversionBtnRef}
            refetchPricetable={props.refetchPricetable}
            saveCommentMappingEditinfo={saveCommentMappingEditinfo}
        />
    )
}
