import React from "react"
import { useTranslation } from "react-i18next"
import PPUtils from "../utils/PPUtils"
import { toast } from "react-toastify"

function PPUploadImport() {
    const [t] = useTranslation()

    let auth = PPUtils.getLogin()

    let url = "/api/feed/v1/importCSV"
    if (PPUtils.isDev()) {
        url = "/preispiranha.crawler_controller" + url
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            let file = e.target.files[0]
            if (file && file.type === "text/csv") {
                let data = new FormData()
                data.append("file", file)
                let token = auth?.user?.frontendUploadImport

                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`
                    },
                    body: data
                })
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.status === 200) {
                            toast(t("pricetable.file_import.msg_ok"))
                        } else {
                            toast.error(data.message + " " + data.description)
                        }
                    })
                    .catch((err) => toast.error(err))
            } else {
                toast.error(t("pricetable.file_import.error_file_type"))
            }
        }
    }

    if (
        auth?.user?.frontendUploadImport === undefined ||
        auth?.user?.frontendUploadImport === null ||
        auth?.user?.frontendUploadImport === ""
    ) {
        return <></>
    }

    return (
        <div className={"uploadimport"}>
            <div className={"file btn btn-lg btn-primary"}>
                Upload File
                <input type="file" name="file" onChange={handleFileChange} />
            </div>
        </div>
    )
}

export default PPUploadImport
