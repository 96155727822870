import React, { useState } from "react"
import "../App.css"
import ReactApexChart from "react-apexcharts"
import { gql, useQuery } from "@apollo/client"
import { PPDefaultSelector } from "../components/PPDefaultSelector"
import _ from "lodash"
import { MultiValueLabel, Option } from "../components/PPShopPreset"

import i18n_iso_countrie_de from "i18n-iso-countries/langs/de.json"
import i18n_iso_countrie_en from "i18n-iso-countries/langs/en.json"

import countries from "i18n-iso-countries"
import moment from "moment"
import PPUtils from "../utils/PPUtils"
import { useTranslation } from "react-i18next"
import useRequireAuth from "../hooks/use-require-auth"
import PPUtilsCatFilter from "../utils/PPUtilsCatFilter"

const STATS_QUERY = gql`
    query getStats($department: String, $id: Long!) {
        stats(department: $department) {
            shopStats {
                shopId
                name
                shortName
                lang
                countMappedProducts
                countPriceChangesLastTwoWeeks
                countProducts
                countStockGreenProducts
                countStockYellowProducts
                countStockRedProducts
                productsPriceLower
                productsPriceEqual
                productsPriceHigher
            }
            activeShops
            activeProducts
            crawlsToday
            productsPriceHigher
            productsPriceEqual
            productsPriceLower
            crawlerCountSeries
            shopsProductAllCounts {
                name
                lang
                countProducts
            }
            shopsProductMappedCounts {
                name
                lang
                countMappedProducts
            }
            lastUpdate
            filterDepartment
        }
        shops {
            id
            colorHex
        }
        baseData {
            mainLang
            categories {
                ...selectFields
                type
            }
        }
        preset(presetId: $id) {
            name
            id
            searchDepartment
        }
    }
    fragment selectFields on Category {
        name
        value: name
        label: name
    }
`

const options = {
    colors: [PPUtils.Colors.GreenStart, PPUtils.Colors.YellowStart, PPUtils.Colors.RedStart],
    chart: {
        type: "bar",
        height: "auto",
        stacked: true,
        stackType: "100%",
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true
        }
    },
    responsive: [
        {
            breakpoint: 480,
            options: {
                legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }
    ],
    dataLabels: {
        enabled: true,
        offsetY: 8,
        style: {
            colors: ["#ffffff"],
            fontSize: "1.4em",
            fontFamily:
                '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
            fontWeight: "bold"
        },
        dropShadow: {
            enabled: true,
            top: 2,
            left: 2,
            blur: 0.6,
            color: "#1c1c1c",
            opacity: 1
        }
    },
    plotOptions: {
        bar: {
            horizontal: true
        }
    },
    xaxis: {
        type: "category"
    },
    legend: {
        show: false,
        position: "right",
        offsetY: 40
    }
    // fill: { //Bug: https://apexcharts.com/react-chart-demos/bar-charts/patterned/
    //     type: "gradient",
    //     gradient: {
    //         shade: "dark",
    //         type: "vertical",
    //         shadeIntensity: 0.1,
    //         gradientToColors: [PPUtils.Colors.GreenEnd, PPUtils.Colors.YellowEnd, PPUtils.Colors.RedEnd],
    //         inverseColors: false,
    //         opacityFrom: 1,
    //         opacityTo: 0.8
    //     }
    // }
}

const state = {
    optionsAreaChart: {
        xaxis: {
            type: "category",
            labels: {
                show: true,
                // offsetX: -6.7,
                rotate: -30,
                rotateAlways: true
            }
        },
        chart: {
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        }
    },
    optionsBlockChart: {
        chart: {
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: true
        }
    }
}

function barHeight(seriesCount) {
    let vbarHeight = 38

    if (seriesCount == 1) {
        vbarHeight = 98
    } else if (seriesCount == 2) {
        vbarHeight = 65
    } else if (seriesCount == 3) {
        vbarHeight = 54
    } else if (seriesCount == 4 || seriesCount == 5) {
        vbarHeight = 48
    } else if (seriesCount == 6) {
        vbarHeight = 44
    }

    return seriesCount * vbarHeight
}

function LangFilterChart({ id, data, transformer, title, charttype, shops, i18n, mainLang }) {
    countries.registerLocale(i18n_iso_countrie_en)
    countries.registerLocale(i18n_iso_countrie_de)

    const [langFilter, setLangFilter] = useState([
        {
            label: countries.getName(mainLang, i18n.language),
            value: mainLang,
            lang: mainLang
        }
    ])

    let languages = _.uniqBy(
        data.map((x) => ({
            label: countries.getName(x.lang, i18n.language),
            value: x.lang,
            lang: x.lang
        })),
        (e) => e.value
    )

    let series = transformer(
        data.filter((shop) => {
            if (langFilter && langFilter.length > 0) {
                return langFilter?.map((x) => x.value).includes(shop.lang)
            }
            return true
        })
    )

    let height = barHeight(series[0]?.data?.length)

    let pie_series = []
    let pie_labels = []
    let pie_colors = []
    let options_pie = {}
    if (charttype == "pie") {
        series[0]?.data?.map((d) => {
            pie_series.push(d.y)
            pie_labels.push(d.x + " " + d.y)
            shops.filter((v) => {
                if (v.id === d.shopId) {
                    pie_colors.push(v.colorHex)
                }
            })
        })

        options_pie = {
            labels: pie_labels,
            colors: pie_colors,
            chart: {
                type: "pie"
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ],
            dataLabels: {
                enabled: true,
                style: {
                    colors: ["#ffffff"],
                    fontSize: "1.2em",
                    fontFamily:
                        '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
                    fontWeight: "bold"
                },
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 2,
                    blur: 0.6,
                    color: "#1c1c1c",
                    opacity: 1
                }
            }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col card-header ui-sortable-handle">
                    <h3 className="card-title">{title}</h3>
                </div>
                {charttype == "pie" && (
                    <PPDefaultSelector
                        id={id}
                        isMulti={true}
                        onChange={setLangFilter}
                        data={languages}
                        defaultValue={langFilter}
                        components={{ MultiValueLabel, Option }}
                    />
                )}
            </div>
            {charttype !== "pie" && (
                <PPDefaultSelector
                    id={id}
                    isMulti={true}
                    onChange={setLangFilter}
                    data={languages}
                    defaultValue={langFilter}
                    components={{ MultiValueLabel, Option }}
                />
            )}
            {charttype == "pie" ? (
                <ReactApexChart options={options_pie} series={pie_series} type="pie" height={300} />
            ) : (
                <div className="card-body">
                    <div className="tab-content p-0">
                        <ReactApexChart options={options} series={series} type="bar" height={height} />
                    </div>
                </div>
            )}
        </>
    )
}
//<ReactApexChart options={options} series={series} type="bar" height={height} />
export default function PPStatistics() {
    const [t, i18n] = useTranslation()
    const [categorieTree, setCategorieTree] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(null)

    let variables = {
        id: Number(localStorage.getItem("presetId")),
        department: selectedDepartment ? selectedDepartment.value : ""
    }

    const { loading, error, data } = useQuery(STATS_QUERY, {
        fetchPolicy: "no-cache",
        variables: variables,
        onCompleted: (data) => {
            data?.baseData?.categories.unshift(PPUtilsCatFilter.getEmptyObject())
            setCategorieTree(data?.baseData?.categories)
            if (selectedDepartment == null) {
                setSelectedDepartment(
                    PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchDepartment, data.baseData.categories)
                )
            }
        }
    })

    if (loading) return null
    if (error) return <p>Error :(</p>
    if (!data) return null

    function formSeries(shopStats) {
        return [
            {
                name: t("single.green"),
                data: shopStats.map((shop) => ({
                    x: shop.shopId == 0 ? `${shop.shortName}` : `${shop.shortName} (${shop.lang})`,
                    y: shop.countStockGreenProducts
                }))
            },
            {
                name: t("single.yellow"),
                data: shopStats.map((shop) => ({
                    x: shop.shopId == 0 ? `${shop.shortName}` : `${shop.shortName} (${shop.lang})`,
                    y: shop.countStockYellowProducts
                }))
            },
            {
                name: t("single.red"),
                data: shopStats.map((shop) => ({
                    x: shop.shopId == 0 ? `${shop.shortName}` : `${shop.shortName} (${shop.lang})`,
                    y: shop.countStockRedProducts
                }))
            }
        ]
    }

    function formSeries2(shopStats) {
        return [
            {
                name: t("single.green"),
                data: shopStats
                    .filter((shop) => shop.shopId !== 0)
                    .map((shop) => ({
                        x: `${shop.shortName} (${shop.lang})`,
                        y: shop.productsPriceLower
                    }))
            },
            {
                name: t("single.yellow"),
                data: shopStats
                    .filter((shop) => shop.shopId !== 0)
                    .map((shop) => ({
                        x: `${shop.shortName} (${shop.lang})`,
                        y: shop.productsPriceEqual
                    }))
            },
            {
                name: t("single.red"),
                data: shopStats
                    .filter((shop) => shop.shopId !== 0)
                    .map((shop) => ({
                        x: `${shop.shortName} (${shop.lang})`,
                        y: shop.productsPriceHigher
                    }))
            }
        ]
    }

    function formSeries3(shopStats) {
        return [
            {
                name: t("single.green"),
                data: shopStats
                    .filter((shop) => shop.shopId !== 0)
                    .map((shop) => ({
                        x: `${shop.shortName} (${shop.lang})`,
                        y: shop.countPriceChangesLastTwoWeeks,
                        shopId: shop.shopId //temp
                    }))
            }
        ]
    }

    function prepareSeriesDataForAreaChart() {
        let newVar = []
        if (data.stats.crawlerCountSeries !== null) {
            let series = []
            data.stats.crawlerCountSeries.forEach((entry) =>
                series.push({
                    x: moment().day(moment(entry.x, "DD.MM.YYYY").day()).format("dddd"),
                    y: entry.y
                })
            )

            // Wir wollen nur die letzten 14 Tage
            series = series.slice(Math.max(series.length - 14, 0), series.length)
            let chunked = _.chunk(series, 7)
            if (chunked.length > 1) {
                newVar = [
                    { name: "Diese Woche", data: chunked[1] },
                    { name: "Letzte Woche", data: chunked[0] }
                ]
            } else if (chunked.length == 1) {
                newVar = [{ name: "Diese Woche", data: chunked[0] }]
            } else {
                newVar = []
            }
        }
        return newVar
    }

    return (
        <>
            {data?.stats?.filterDepartment && (
                <div className="mb-3">
                    <PPDefaultSelector
                        id="selectStatsFilterDepartment"
                        data={categorieTree}
                        onChange={(evt) => {
                            setSelectedDepartment(evt)
                        }}
                        name="department"
                        placeholder={t("single.department")}
                        value={selectedDepartment}
                        isClearable={false}
                    />
                </div>
            )}
            <div className="card shadow card-primary card-outline card-outline-tabs mb-3">
                <div className="card-header p-0 border-bottom-0">
                    {data?.stats?.lastUpdate && (
                        <div className={"stat_lastdate"}>
                            {t("single.stat_last_update")}:{" "}
                            {new Intl.DateTimeFormat(i18n.language, {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit"
                            }).format(new Date(data?.stats?.lastUpdate))}
                        </div>
                    )}
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                id="custom-tabs-four-stat1-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-stat1"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-stat1"
                                aria-selected="true">
                                {t("single.products")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                id="custom-tabs-four-stat2-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-stat2"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-stat2"
                                aria-selected="false">
                                Crawler
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div
                            className="tab-pane fade active show"
                            id="custom-tabs-four-stat1"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-stat1-tab">
                            <div className="row">
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <LangFilterChart
                                                id="selectStatisticsStock"
                                                title={t("single.stock")}
                                                data={data?.stats?.shopStats}
                                                mainLang={data["baseData"]?.mainLang}
                                                transformer={formSeries}
                                                charttype={"bar"}
                                                i18n={i18n}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <LangFilterChart
                                                id="selectStatisticsPricedifference"
                                                title={t("single.price_difference")}
                                                data={data?.stats?.shopStats}
                                                mainLang={data["baseData"]?.mainLang}
                                                transformer={formSeries2}
                                                charttype={"bar"}
                                                i18n={i18n}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            <LangFilterChart
                                                id="selectStatisticsPriceChanges"
                                                title={t("single.price_changes")}
                                                data={data?.stats?.shopStats}
                                                mainLang={data["baseData"]?.mainLang}
                                                transformer={formSeries3}
                                                charttype={"pie"}
                                                shops={data?.shops}
                                                i18n={i18n}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="custom-tabs-four-stat2"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-stat2-tab">
                            <div className="row">
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <ChartProductMapping
                                                id="selectStatisticsChartProductMappingProducts"
                                                title={t("statistic.chart_product_mapping_products")}
                                                data={data?.stats?.shopStats}
                                                shopsProductAllCounts={data?.stats?.shopsProductAllCounts}
                                                pushCustomerProducts={data?.stats?.activeProducts}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <ChartProductMapping
                                                id="selectStatisticsChartProductMappingMappings"
                                                title={t("statistic.chart_product_mapping_mappings")}
                                                data={data?.stats?.shopStats}
                                                shopsProductAllCounts={data?.stats?.shopsProductMappedCounts}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header ui-sortable-handle border-0">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1" />
                                        Crawls
                                    </h3>
                                    <div className="card-tools">
                                        <ul className="nav nav-pills ml-auto" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    id="areaTab"
                                                    data-toggle="pill"
                                                    href="#area-diagram"
                                                    role="tab"
                                                    aria-controls="area-diagram"
                                                    aria-selected="true">
                                                    {t("single.area")}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="blockTab"
                                                    data-toggle="pill"
                                                    href="#block-diagram"
                                                    role="tab"
                                                    aria-controls="block-diagram"
                                                    aria-selected="false">
                                                    {t("single.bar")}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="area-diagram"
                                            role="tabpanel"
                                            aria-labelledby="areaTab">
                                            <div className="row">
                                                <div
                                                    className="col-md-12"
                                                    style={{
                                                        paddingRight: 20 + "px"
                                                    }}>
                                                    <ReactApexChart
                                                        options={state?.optionsAreaChart}
                                                        series={prepareSeriesDataForAreaChart()}
                                                        type="area"
                                                        height={200}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="block-diagram"
                                            role="tabpanel"
                                            aria-labelledby="blockTab">
                                            <div
                                                className="tab-pane fade show active"
                                                id="area-diagram"
                                                role="tabpanel"
                                                aria-labelledby="areaTab">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ReactApexChart
                                                            options={state?.optionsBlockChart}
                                                            series={prepareSeriesDataForAreaChart()}
                                                            type="bar"
                                                            height={200}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function ChartProductMapping(props) {
    const [t, i18n] = useTranslation()

    let defaultLanguageStats = localStorage.getItem("default_language_stats")
    const [langFilter, setLangFilter] = useState([
        {
            label: countries.getName(defaultLanguageStats, i18n.language),
            value: defaultLanguageStats,
            lang: defaultLanguageStats
        }
    ])

    if (props.shopsProductAllCounts === null || props.shopsProductAllCounts.length == 0) {
        return t("errors.no_correct_server_data")
    }

    let countKey = "countProducts" in props.shopsProductAllCounts[0] ? "countProducts" : "countMappedProducts"

    let languages = _.uniqBy(
        Array.from(props.data).map((x) => ({
            label: countries.getName(x.lang, "de"),
            value: x.lang,
            lang: x.lang
        })),
        (e) => e.value
    )

    let series = props.shopsProductAllCounts.filter((shop) => {
        if (langFilter && langFilter.length > 0) {
            return langFilter?.map((x) => x.value).includes(shop.lang)
        }
        return true
    })

    let series_data = [
        {
            data: series.map((x) => x[countKey])
        }
    ]

    const series_options = {
        chart: {
            type: "bar"
        },
        colors: ["#33b2df"],
        plotOptions: {
            bar: {
                barHeight: "100%",
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: "bottom"
                }
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
                colors: ["#111"]
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ": " + val
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 1,
            colors: ["#fff"]
        },
        xaxis: {
            categories: series.map((x) => "(" + x.lang + ") " + x.name)
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: false,
            theme: "dark"
        }
    }

    if (props.pushCustomerProducts) {
        const auth = useRequireAuth()

        let customerName = auth?.login?.customer?.name
        if (auth?.login?.token_name) {
            customerName = auth?.login?.token_name
        }

        series_data[0].data.unshift(props.pushCustomerProducts)
        series_options.xaxis.categories.unshift(customerName)
        series_options.colors = []
        series_data[0].data.forEach(() => {
            series_options.colors.push("#33b2df")
        })
        series_options.colors[0] = "#007bff"
        series_options.dataLabels.style.colors = []
        series_data[0].data.forEach(() => {
            series_options.dataLabels.style.colors.push("#111")
        })
    }

    let height = barHeight(series_data[0]?.data?.length)

    return (
        <>
            <div className="row">
                <div className="col card-header ui-sortable-handle">
                    <h3 className="card-title">
                        <i className="fas fa-cubes mr-1" />
                        {props.title}
                    </h3>
                </div>
            </div>
            <PPDefaultSelector
                id={props.id}
                isMulti={true}
                onChange={setLangFilter}
                data={languages}
                defaultValue={langFilter}
                components={{ MultiValueLabel, Option }}
            />
            <div className="card-body">
                <div className="tab-content p-0">
                    <ReactApexChart options={series_options} series={series_data} type="bar" height={height} />
                </div>
            </div>
        </>
    )
}
