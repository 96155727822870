import React, { useEffect, useState, useMemo } from "react"
import "../App.css"
import GraphQL from "../utils/GraphQLClient"
import PPUtilsCatFilter from "../utils/PPUtilsCatFilter"
import { PPDefaultSelector } from "./PPDefaultSelector"
import { useTranslation } from "react-i18next"
import { MultiValueLabel, Option } from "../components/PPShopPreset"
import DataTable from "react-data-table-component"
import jquery from "jquery"
import _ from "lodash"
import countries from "i18n-iso-countries"
import QUERY_PRODUCER from "../utils/const/QueryProducer"
import PPUtils from "../utils/PPUtils"

const query = `query getCats($customer: Long) {
  crawlerpriotable {
     crawlPriority {
        id
        createDate
        userId
        shopId
        producer
        department
        productGroup
        productCat
        createdJobs
        processedJobs
        customerId
     }
  }
  shops(onlyActive: true, customer: $customer) {
    label: name
    value: id
    lang
  }
  users(userStatus: "showAll") {
    id
    name
  }
  baseData(customer: $customer) {
    producers {
      label: name
      value: name
    }
    categories {
      ...selectFields
      type
      children {
        ...selectFields
        type
        children {
          ...selectFields
          type
          children {
            ...selectFields
            type
          }
        }
      }
    }
  }
}
fragment selectFields on Category {
    name
    value: name
    label: name
}
`

const query_counts = `
    query getQueryCounts($customer: Long, $department: String, $productGroup: String, $productCat: String, $producer: String, $shops: [PresetShopFlatInput]) {
        crawlerprioquerycount(
            customer: $customer
            department: $department
            productGroup: $productGroup
            productCat: $productCat
            producer: $producer
            shops: $shops
        ) {
            crawlerPriorityQueryCount {
                shopId
                shopName
                shopLang
                queryCount
            }
        }
    }
`

const query_insert = `mutation($customer: Long, $department: String, $productGroup: String, $productCat: String, $producer: String, $shops: [PresetShopFlatInput]) {
        crawlerprioinsert(
            customer: $customer
            department: $department
            productGroup: $productGroup
            productCat: $productCat
            producer: $producer
            shops: $shops
        )
    }
`

const query_delete = `mutation($crawlerJobId: Int!) {
        crawlerpriodelete(
            crawlerJobId: $crawlerJobId
        )
    }
`

const CustomRowSearchsettings = ({ row, customers }) => {
    const [t, i18n] = useTranslation()
    let html_shop, html_customer, html_producer, html_department, html_productGroup, html_productCat

    if (row.shopId) {
        if (row.shop_name == null) {
            html_shop = (
                <div>
                    {t("single.shop")}: {row.shopId}
                </div>
            )
        } else {
            html_shop = (
                <div>
                    {t("single.shop")}:{" "}
                    <span
                        title={countries.getName(row.shop_lang, i18n.language)}
                        className={"fi fi-" + row.shop_lang}
                    />{" "}
                    {row.shop_name}
                </div>
            )
        }
    }

    if (row.customerId) {
        const filteredResult = customers.find((e) => e.value == row.customerId)
        if (filteredResult) {
            html_customer = (
                <div>
                    {t("single.customer")}: {filteredResult?.label}
                </div>
            )
        }
    }
    if (row.producer) {
        html_producer = (
            <div>
                {t("single.manufacturer")}: {row.producer}
            </div>
        )
    }
    if (row.department) {
        html_department = (
            <div>
                {t("single.department")}: {row.department}
            </div>
        )
    }
    if (row.productGroup) {
        html_productGroup = (
            <div>
                {t("single.product_group")}: {row.productGroup}
            </div>
        )
    }
    if (row.productCat) {
        html_productCat = (
            <div>
                {t("single.product_category")}: {row.productCat}
            </div>
        )
    }

    return (
        <div className="mt-2 mb-2">
            {html_shop}
            {html_customer}
            {html_producer}
            {html_department}
            {html_productGroup}
            {html_productCat}
        </div>
    )
}

const CustomRowStatus = ({ row }) => {
    return (
        <div className="mt-2 mb-2">
            {row.processedJobs} / {row.createdJobs}
        </div>
    )
}

function refactorData(data, shops, users) {
    for (let k in data) {
        if (data[k]["userId"]) {
            let userID = data[k]["userId"]
            data[k]["user_name"] = users.find((e) => e.id == userID).name
        }
        if (data[k]["shopId"]) {
            let shopId = data[k]["shopId"]
            let result_shop = shops.find((e) => e.value == shopId)
            if (result_shop) {
                data[k]["shop_name"] = result_shop.label
                data[k]["shop_lang"] = result_shop.lang
            }
        }
    }
    return data
}

function PPCrawlerPrio() {
    let auth = PPUtils.getLogin()
    let customers = []

    const [t, i18n] = useTranslation()
    const [shops, setShops] = useState([])
    const [categorieTree, setCategorieTree] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedProductGroup, setSelectedProductGroup] = useState(null)
    const [selectedProductCat, setSelectedProductCat] = useState(null)
    const [selectedProducer, setSelectedProducer] = useState(null)
    const [selectedShops, setSelectedShops] = useState(null)

    const [dataformatGroups, setDataformatGroups] = useState(null)
    const [dataformatCats, setDataformatCats] = useState(null)
    const [dataProducer, setDataProducer] = useState(null)

    const [dataTableCrawlerPrio, setDataTableCrawlerPrio] = useState(null)

    const [selectedDatatableRow, setSelectedDatatableRow] = useState(null)

    if (auth.user.rights == "admin" && auth.customerUsers?.length >= 1) {
        customers = auth.customerUsers.reduce(function (previous, current) {
            return previous.concat({
                value: current.customer.id,
                label: current.customer.name
            })
        }, [])
    }

    useEffect(() => {
        if (selectedDepartment != null) {
            let variables = {
                department: selectedDepartment ? selectedDepartment.value : "",
                productGroup: selectedProductGroup ? selectedProductGroup.value : "",
                productCat: selectedProductCat ? selectedProductCat.value : ""
            }
            GraphQL.getClient()
                .request(QUERY_PRODUCER, variables)
                .then((data) => {
                    setDataProducer(data?.getProducerByQuery?.producerList)
                })
                .catch((error) => console.error(error))
        }
    }, [selectedDepartment, selectedProductGroup, selectedProductCat])

    const CustomRowDelete = ({ row }) => {
        let html_button
        if (row.processedJobs < row.createdJobs || row.processedJobs == 0) {
            html_button = (
                <button
                    className="btn btn-danger"
                    data-toggle="modal"
                    data-target="#deletePresetModal"
                    type="button"
                    onClick={() => setSelectedDatatableRow(row)}>
                    {t("single.delete")}
                </button>
            )
        }
        return <div className="mt-2 mb-2">{html_button}</div>
    }

    const columns = useMemo(() => [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "80px"
        },
        {
            name: t("single.name"),
            selector: (row) => row.user_name
        },
        {
            name: t("single.create_date"),
            selector: (row) => row.createDate,
            cell: (row) => {
                return new Intl.DateTimeFormat(i18n.language, {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                }).format(new Date(row.createDate))
            }
        },
        {
            name: t("single.settings"),
            selector: (row) => row.id,
            cell: (row) => <CustomRowSearchsettings row={row} customers={customers} />
        },
        {
            name: "Status",
            selector: (row) => row.id,
            cell: (row) => <CustomRowStatus row={row} />,
            width: "120px"
        },
        {
            name: "",
            selector: (row) => row.id,
            cell: (row) => <CustomRowDelete row={row} />,
            width: "120px"
        }
    ])

    useEffect(() => {
        refreshData()
    }, [selectedCustomer])

    function refreshData() {
        let variables = {
            customer: selectedCustomer ? parseInt(selectedCustomer.value) : 0
        }
        GraphQL.getClient()
            .request(query, variables)
            .then((data) => {
                data?.shops.sort(function (o1, o2) {
                    let t1 = o1.label.toLowerCase(),
                        t2 = o2.label.toLowerCase()
                    return t1 > t2 ? 1 : t1 < t2 ? -1 : 0
                })
                let shops_grouped = _.mapValues(_.groupBy(data?.shops, "label"), (clist) =>
                    clist.map((option) => _.omit(option, "label"))
                )
                let shops_options = []
                _.each(shops_grouped, function (value, key) {
                    if (value.length > 1) {
                        shops_options.push({ label: "ALL:" + key, value: "ALL:" + key })
                    }
                })

                // console.log([...shops_options, ...data?.shops])

                setShops([...shops_options, ...(data?.shops || 0)])
                setCategorieTree(data?.baseData?.categories)
                setDataProducer(data?.baseData?.producers)

                let vDataformatGroups = PPUtilsCatFilter.formatGroups(data?.baseData?.categories, "PRODUCTGROUP")
                setDataformatGroups(vDataformatGroups)
                setDataformatCats(PPUtilsCatFilter.formatGroups(vDataformatGroups, "PRODUCTCAT"))

                setDataTableCrawlerPrio(refactorData(data.crawlerpriotable?.crawlPriority, data?.shops, data?.users))
            })
            .catch((error) => console.error(error))
    }

    if (categorieTree.length === 0 || dataTableCrawlerPrio === null) {
        return null
    }

    function getVariables() {
        return {
            customer: selectedCustomer ? parseInt(selectedCustomer.value) : 0,
            department: selectedDepartment ? selectedDepartment.value : "",
            productGroup: selectedProductGroup ? selectedProductGroup.value : "",
            productCat: selectedProductCat ? selectedProductCat.value : "",
            producer: selectedProducer ? selectedProducer.value : "",
            shops:
                selectedShops === null || selectedShops === undefined
                    ? []
                    : selectedShops.map((shop, index) => ({ order: index, shopId: shop.value }))
        }
    }

    function crawlerPrioQueryCount(vi18n) {
        jquery("#submit_looding").removeClass("d-none").addClass("d-flex")
        jquery("#button_submit_check").hide()
        GraphQL.getClient()
            .request(query_counts, getVariables())
            .then((data) => {
                let html = ""
                let count_query = 0
                if (data?.crawlerprioquerycount?.crawlerPriorityQueryCount?.length > 0) {
                    _.each(data?.crawlerprioquerycount?.crawlerPriorityQueryCount, function (value) {
                        count_query += value.queryCount
                        html +=
                            '<div><span title="' +
                            countries.getName(value.shopLang, vi18n.language) +
                            '" class="fi fi-' +
                            value.shopLang +
                            '"></span> ' +
                            value.shopName +
                            " - " +
                            t("single.count") +
                            ": " +
                            value.queryCount +
                            "</div>"
                    })
                    jquery("#PreCheckQueryCount .result").html(html)
                    jquery("#PreCheckQueryCount").collapse("show")
                    if (count_query == 0) {
                        jquery("#button_submit_insert").hide()
                    } else {
                        jquery("#button_submit_insert").show()
                    }
                } else {
                    jquery("#PreCheckQueryCount").collapse("hide")
                }
                jquery("#submit_looding").addClass("d-none").removeClass("d-flex")
                jquery("#button_submit_check").show()
            })
            .catch((error) => console.error(error))
    }

    function crawlerPrioInsert() {
        GraphQL.getClient()
            .request(query_insert, getVariables())
            .then(() => {
                resetElements()
                refreshData()
            })
            .catch((error) => console.error(error))
    }

    function crawlerPrioDelete(row) {
        if (row?.id > 0) {
            GraphQL.getClient()
                .request(query_delete, { crawlerJobId: row.id })
                .then(() => {
                    refreshData()
                })
                .catch((error) => console.error(error))
        }
    }

    function resetElements() {
        jquery("#PreCheckQueryCount .result").html("")
        jquery("#PreCheckQueryCount").collapse("hide")
        setSelectedCustomer(null)
        setSelectedDepartment(null)
        setSelectedProductGroup(null)
        setSelectedProductCat(null)
        setSelectedProducer(null)
        setSelectedShops(null)
    }

    function formatProductGroup() {
        if (selectedDepartment === null || selectedDepartment.children === undefined) {
            return dataformatGroups
        }
        return selectedDepartment.children.length > 0 ? selectedDepartment.children : []
    }

    function formatProductCat() {
        if (
            selectedDepartment === null ||
            selectedProductGroup === null ||
            selectedProductGroup.children === undefined
        ) {
            return dataformatCats
        }
        return selectedProductGroup.children.length > 0 ? selectedProductGroup.children : []
    }

    return (
        <div>
            <div className="row">
                <div className="col-3">
                    <PPDefaultSelector
                        id="selectCrawlerPrioDepartment"
                        data={categorieTree}
                        onChange={(evt) => {
                            setSelectedDepartment(evt)
                            setSelectedProductGroup(null)
                            setSelectedProductCat(null)
                        }}
                        name="department"
                        placeholder={t("single.department")}
                        value={selectedDepartment}
                    />
                </div>
                <div className="col-3">
                    <PPDefaultSelector
                        id="selectCrawlerPrioProductgroups"
                        data={formatProductGroup()}
                        onChange={(evt) => {
                            setSelectedProductGroup(evt)
                            setSelectedProductCat(null)
                        }}
                        name="productgroups"
                        placeholder={t("single.product_group")}
                        value={selectedProductGroup}
                    />
                </div>
                <div className="col-3">
                    <PPDefaultSelector
                        id="selectCrawlerPrioProductcats"
                        data={formatProductCat()}
                        onChange={(evt) => setSelectedProductCat(evt)}
                        name="productcats"
                        placeholder={t("single.product_category")}
                        value={selectedProductCat}
                    />
                </div>
                <div className="col-3">
                    <PPDefaultSelector
                        id="selectCrawlerPrioProducer"
                        data={dataProducer}
                        onChange={(evt) => setSelectedProducer(evt)}
                        name="producer"
                        placeholder={t("single.manufacturer")}
                        value={selectedProducer}
                    />
                </div>
            </div>
            <div className="row mt-2">
                {customers.length > 0 && (
                    <div className="col-3">
                        <PPDefaultSelector
                            id="inputCrawlerPrioCustomer"
                            data={customers}
                            onChange={(evt) => {
                                setSelectedCustomer(evt)
                            }}
                            name="customer"
                            placeholder={t("single.customer")}
                            value={selectedCustomer}
                        />
                    </div>
                )}
                <div className={customers.length > 0 ? "col-6" : "col-9"}>
                    <PPDefaultSelector
                        id="selectCrawlerPrioShop"
                        isMulti={true}
                        data={shops}
                        components={{ MultiValueLabel, Option }}
                        placeholder={t("single.shops")}
                        onChange={(selected) => {
                            if (selected != null && selected.length > 0) {
                                if (selected[0].label.includes("ALL:")) {
                                    let shopName = selected[0].label.replace("ALL:", "")
                                    let shops_selected = []
                                    _.each(shops, function (opt) {
                                        if (shopName == opt.label) {
                                            shops_selected.push(opt)
                                        }
                                    })
                                    selected = shops_selected
                                }
                            }
                            setSelectedShops(selected)
                        }}
                        value={selectedShops}
                    />
                </div>
                <div className="col-3">
                    <div id="submit_looding" className="justify-content-center d-none">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    <button
                        id="button_submit_check"
                        className="category-button-go btn btn-primary btn-block"
                        type="button"
                        onClick={() => crawlerPrioQueryCount(i18n)}>
                        {"PreCheck"}
                    </button>
                </div>
            </div>
            <div id="PreCheckQueryCount" className="row mt-3 mb-3 collapse">
                <div className="col-3 result"></div>
                <div className="col-2 align-bottom">
                    <button
                        id="button_submit_insert"
                        className="category-button-go btn btn-secondary btn-block"
                        type="button"
                        onClick={crawlerPrioInsert}>
                        {t("single.create")}
                    </button>
                </div>
            </div>
            <div className="mt-3 datatable_headerbold">
                <DataTable noHeader columns={columns} data={dataTableCrawlerPrio} />
            </div>
            <div
                className="modal fade"
                id="deletePresetModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="deletePresetModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deletePresetModalLabel">
                                {t("single.delete")} ?
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {t("crawler.prioritized_crawling_modal_delete_text", { id: selectedDatatableRow?.id })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.cancel")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    crawlerPrioDelete(selectedDatatableRow)
                                }}>
                                {t("single.delete")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PPCrawlerPrio
