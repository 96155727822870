import React, { useEffect, useState, useRef } from "react"
import "../App.css"
import { PPDefaultSelector } from "./PPDefaultSelector"
import { useTranslation } from "react-i18next"
import countries from "i18n-iso-countries"
import PPUtils from "../utils/PPUtils"

function PPShopFilter(props) {
    const [t] = useTranslation()
    const [selectedShop, setSelectedShop] = useState(null)
    const [selectedFilterMethod, setSelectedFilterMethod] = useState(null)
    const [selectedFilterOption, setSelectedFilterOption] = useState(null)
    const userlang = PPUtils.getLogin().user.configLanguage
    let addFilterBtnRef = useRef()

    function filterPricetableData() {
        if (selectedShop != null) {
            // Filtern nach Pricediff oder stock
            let sortObject = { shopId: selectedShop.id, propertyName: selectedFilterMethod }
            let sortOption = selectedFilterOption
            if (selectedFilterMethod == "foreignShopStock") {
                switch (selectedFilterOption) {
                    case "GREEN":
                        sortObject.propertyName = "foreignShopStockGreen"
                        break
                    case "YELLOW":
                        sortObject.propertyName = "foreignShopStockYellow"
                        break
                    case "RED":
                        sortObject.propertyName = "foreignShopStockRed"
                        break
                }
                sortOption = "ASC"
            }
            props.handleSort(sortObject, sortOption)
        } else {
            props.handleSort(null, null)
        }
    }

    useEffect(() => {
        if (
            (addFilterBtnRef.current &&
                selectedShop != null &&
                selectedFilterMethod != null &&
                selectedFilterOption != null) ||
            (selectedShop == null && selectedFilterMethod == null && selectedFilterOption == null)
        ) {
            addFilterBtnRef.current.removeAttribute("disabled")
        } else {
            addFilterBtnRef.current.setAttribute("disabled", true)
        }
    }, [selectedShop, selectedFilterMethod, selectedFilterOption])

    function prepareShopData() {
        let shopOptions = []
        props.preset?.shops.map((shop) => {
            let flag = shop.shop.lang && (
                <div>
                    <span
                        title={countries.getName(shop.shop.lang, userlang)}
                        className={"fi fi-" + shop.shop.lang}></span>{" "}
                    {shop.shop.name}
                </div>
            )
            shopOptions.push({
                label: flag,
                value: shop.shop
            })
        })
        return shopOptions
    }

    function prepareFilterMethodOptions() {
        return [
            { value: "foreignShopPriceDiff", label: t("shop_filter.sort_by_pricediff") },
            { value: "foreignShopStock", label: t("shop_filter.sort_by_stock") }
        ]
    }

    function prepareFilterOptionOptions() {
        if (selectedFilterMethod == "foreignShopPriceDiff") {
            return [
                {
                    value: "ASC",
                    label: t("single.asc")
                },
                {
                    value: "DESC",
                    label: t("single.desc")
                }
            ]
        } else if (selectedFilterMethod == "foreignShopStock") {
            return [
                {
                    value: "GREEN",
                    label: t("single.green")
                },
                {
                    value: "YELLOW",
                    label: t("single.yellow")
                },
                {
                    value: "RED",
                    label: t("single.red")
                }
            ]
        }
        return []
    }

    return (
        <div>
            <div className="row">
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterShopShops"
                        data={prepareShopData()}
                        name="shopFilter"
                        placeholder={t("single.shops")}
                        onChange={(e) => {
                            setSelectedShop(e?.value)
                        }}
                        value={selectedShop != null ? selectedShop?.value : null}
                    />
                </div>
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterShopMethods"
                        data={prepareFilterMethodOptions()}
                        name="sortFilter"
                        placeholder={t("shop_filter.filter_methods")}
                        onChange={(e) => {
                            setSelectedFilterMethod(e?.value)
                            setSelectedFilterOption(null)
                        }}
                        value={selectedFilterMethod != null ? selectedFilterMethod?.value : null}
                    />
                </div>
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterShopOption"
                        data={prepareFilterOptionOptions()}
                        name="FilterOptions"
                        placeholder={t("shop_filter.filter_options")}
                        onChange={(e) => setSelectedFilterOption(e?.value)}
                        value={selectedFilterOption != null ? selectedFilterMethod.value : null}
                    />
                </div>
                <div className="col-0">
                    <button
                        id="buttonPricetableFilterShopApplyFilter"
                        className="category-button-go btn btn-primary btn-block"
                        type="button"
                        ref={addFilterBtnRef}
                        onClick={filterPricetableData}>
                        {t("single.apply_filter")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PPShopFilter
