import React, { createContext, useContext, useEffect, useState } from "react"
import PPUtils from "../utils/PPUtils"
import axios from "axios"

const authContext = createContext()

export function ProvideAuth({ children }) {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext)
}

function useProvideAuth() {
    const [login, setLogin] = useState(PPUtils.getLogin())
    const [failed, setFailed] = useState(false)

    useEffect(() => {
        if (login) {
            let json = JSON.stringify(login)
            if (localStorage.getItem("login")) {
                localStorage.setItem("login", json)
            } else if (sessionStorage.getItem("login")) {
                sessionStorage.setItem("login", json)
            }
        }
    }, [login])

    const signin = (username, password, remember) => {
        setFailed(false)
        return axios
            .post(
                "/login",
                {
                    username: username,
                    password: password
                },
                {
                    headers: { "content-type": "application/json" }
                }
            )
            .then(function (response) {
                if (response.data) {
                    let storage
                    if (remember) {
                        storage = localStorage
                    } else {
                        storage = sessionStorage
                    }
                    storage.setItem("login", JSON.stringify(response.data))
                    setLogin(response.data)
                    setFailed(false)

                    return response.data
                }
            })
            .catch(function (error) {
                if (error.response?.status === 401) {
                    setFailed(true)
                } else {
                    console.error(error)
                }
            })
    }

    const signout = () => {
        PPUtils.removeLogin()
        return setLogin(false)
    }

    const isExpired = () => {
        let token = PPUtils.getLogin().token
        if (token && wt_decode(token)) {
            const expiry = wt_decode(token).exp
            const now = new Date()
            return now.getTime() > expiry * 1000
        }
        return false
    }

    /**
     * Eigene Decode Funktion, da das jsonwebtoken Package stream verwendet und ein Fallback eingerichtet werden muesste.
     * Wenn wir das Package an mehreren Stellen brauchen, kann man das mal angehen.
     * Mit jsonwebtoken package kommt beim build folgender Error:
     * Can't resolve 'stream' in '/home/fplatte/git/frontend2/node_modules/jws/lib'
     * BREAKING CHANGE: webpack < 5 used to include polyfills for node.js core modules by default.
     * This is no longer the case. Verify if you need this module and configure a polyfill for it.
     */
    const wt_decode = (token) => {
        let base64Url = token.split(".")[1]
        let base64 = base64Url.replace("-", "+").replace("_", "/")
        return JSON.parse(window.atob(base64))
    }

    const isAdmin = () => {
        if (PPUtils.getLogin().user.rights == "admin" && PPUtils.getLogin().customerUsers?.length > 1) {
            if (PPUtils.getLogin().token_rights == "user") {
                return false
            }
        }

        return PPUtils.getLogin().user.rights === "admin"
    }

    return {
        login,
        failed,
        isExpired,
        signin,
        signout,
        isAdmin,
        setLogin
    }
}
