import React, { useEffect, useState } from "react"
import "../App.css"
import { gql, useQuery } from "@apollo/client"
import PPLoadingIndicator from "./PPLoadingIndicator"
import PPHotProductItem from "./PPHotProductItem"
import PPErrorDisplay from "./PPErrorDisplay"
import { useTranslation } from "react-i18next"

const QUERY = gql`
    query getStats($department: String) {
        stats(department: $department) {
            hotProducts {
                customerId
                id
                lastPriceChangesOverAllShops {
                    price
                    shopName
                    lang
                    crawlDate
                }
                priceChangesLast5Days
                priceTrendToCustomerPrice
                productImage
                productName
                productnr
                productProducer
                price
                minPrice
            }
        }
    }
`

function PPHotProducts(props) {
    const [t, i18n] = useTranslation()
    let variables = {
        department:
            props?.changeFilterDepartment != null && props?.changeFilterDepartment?.name != ""
                ? props?.changeFilterDepartment?.name
                : ""
    }

    const { loading, error, data, refetch } = useQuery(QUERY, {
        displayName: "PPHotProductsQuery",
        variables: variables,
        fetchPolicy: "no-cache"
    })
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        refetch()
    }, [props.changeFilterDepartment])

    if (loading) return <PPLoadingIndicator />
    if (error) return <PPErrorDisplay error={error} />

    return (
        <div className="col-lg-12 connectedSortable ui-sortable" id="divHotProducts">
            <div className="card shadow d-flex flex-fill">
                <div className="card-header">
                    <h3>{t("dashboard.volatile_products")}</h3>
                </div>
                <div className="card-body">
                    {data.stats.hotProducts != null &&
                        data.stats.hotProducts
                            .slice(0, showMore ? data.stats.hotProducts.length : 3)
                            .map((product) => (
                                <PPHotProductItem
                                    key={product.id}
                                    product={product}
                                    t={t}
                                    i18n={i18n}
                                    tariffComparisonMode={props?.tariffComparisonMode}
                                />
                            ))}
                </div>
                <div className="card-footer text-center">
                    <a
                        href="#"
                        style={{
                            cursor: "pointer",
                            borderRadius: "0px 0px 3px 3px",
                            color: "grey"
                        }}
                        className={"small-box-footer"}
                        onClick={() => setShowMore(!showMore)}>
                        {showMore ? t("single.less") : t("single.more")}{" "}
                        <i className={showMore ? "fas fa-arrow-circle-up" : "fas fa-arrow-circle-right"} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PPHotProducts
