import gql from "graphql-tag"

class ExportSubscription {
    static get States() {
        return {
            READY: "READY",
            INPROGRESS: "IN_PROGRESS",
            FAILED: "FAILED"
        }
    }

    static get GQL() {
        return {
            EXPORTS_SUBSCRIPTION: gql`
                subscription startExportSub($channel: String!) {
                    exportSub(channel: $channel) {
                        exportId
                        state
                        name
                        downloadLink
                    }
                }
            `
        }
    }
}

export default ExportSubscription
