import React from "react"
import "../App.css"
import ReactApexChart from "react-apexcharts"
import PPUtils from "../utils/PPUtils"
import { useTranslation } from "react-i18next"

function PPTripleBarStats({
    cheaperValue,
    cheaperUrl,
    equalValue,
    equalUrl,
    greaterValue,
    greaterUrl,
    totalCount,
    caption
}) {
    const [t] = useTranslation()

    const seriesData = [
        {
            name: t("dashboard.PPDonutStatsBox_lowerPrices"),
            data: [cheaperValue]
        },
        {
            name: t("dashboard.PPDonutStatsBox_equalsPrices"),
            data: [equalValue]
        },
        {
            name: t("dashboard.PPDonutStatsBox_higherPrices"),
            data: [greaterValue]
        }
    ]

    let urlArray = [cheaperUrl, equalUrl, greaterUrl]

    let config = {
        series: seriesData,
        options: {
            colors: [PPUtils.Colors.GreenStart, PPUtils.Colors.YellowStart, PPUtils.Colors.RedStart],
            chart: {
                type: "bar",
                height: "auto",
                stacked: true,
                stackType: "100%",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        if (config.seriesIndex >= 0 && urlArray.length > config.seriesIndex) {
                            window.location.href = urlArray[config.seriesIndex]
                        }
                    },
                    dataPointMouseEnter: function (event) {
                        event.target.style.cursor = "pointer"
                        // or
                        //event.fromElement.style.cursor = "pointer"
                    }
                }
            },
            xaxis: {
                categories: [caption],
                show: false
            },
            yaxis: {
                show: false,
                max: totalCount
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: "center",
                        style: {
                            colors: ["#000000"]
                        }
                    }
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ["#ffffff"],
                    fontSize: "1.2em",
                    fontFamily:
                        '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
                    fontWeight: "bold"
                },
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 2,
                    blur: 0.6,
                    color: "#1c1c1c",
                    opacity: 1
                }
            },
            legend: {
                show: true
            },
            grid: {
                show: false
            }
        }
    }

    return <ReactApexChart options={config.options} series={config.series} type="bar" width={150} height={200} />
}

export default PPTripleBarStats
