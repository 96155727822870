import React from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { PPShopSettings } from "../components/PPShopSettings"
import { useAuth } from "../hooks/use-auth"
import PPLoadingIndicator from "../components/PPLoadingIndicator"
import { PPUserAdministration } from "../components/PPUserAdministration"

const SETTINGS_QUERY_ADMIN = gql`
    query getDataForAdminSettings {
        shops {
            id
            name
            lang
            domain
            shouldBeActive
            active
            deactivationTime
            deactivationReason
        }
        users(userStatus: "showAll", userSortOrder: "DESC") {
            id
            customerId
            hidden
            username
            name
            email
            rights
            configLanguage
            configShopsActivated
            ruleEngineAccess
            avatarUrl
        }
        activeCustomers {
            id
            name
        }
    }
`

const SETTINGS_QUERY_USER = gql`
    query getDataForAdminSettings {
        shops {
            id
            name
            lang
            domain
            shouldBeActive
            active
        }
    }
`

export default function PPAdmin() {
    const [t] = useTranslation()
    const auth = useAuth()

    const { loading, data, refetch } = useQuery(auth?.isAdmin() ? SETTINGS_QUERY_ADMIN : SETTINGS_QUERY_USER, {
        fetchPolicy: "no-cache"
    })

    if (loading) return <PPLoadingIndicator />

    return (
        <>
            <div className="card shadow card-primary card-outline card-outline-tabs mb-3">
                <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        {auth.isAdmin() ? (
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="custom-tabs-four-usersettings-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-four-usersettings"
                                    role="tab"
                                    onClick={() => window.dispatchEvent(new Event("resize"))}
                                    aria-controls="custom-tabs-four-usersettings"
                                    aria-selected="true"
                                >
                                    {t("single.user")} {t("single.settings")}
                                </a>
                            </li>
                        ) : (
                            <></>
                        )}
                        <li className="nav-item">
                            <a
                                className={!auth.isAdmin() ? "nav-link active" : "nav-link"}
                                id="custom-tabs-four-shopsettings-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-shopsettings"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-shopsettings"
                                aria-selected="true"
                            >
                                {!auth.isAdmin() ? t("single.shops") : t("settings.shops.header")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        {auth.isAdmin() ? (
                            <div
                                className="tab-pane fade active show"
                                id="custom-tabs-four-usersettings"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-four-usersettings-tab"
                            >
                                <PPUserAdministration
                                    users={data?.users}
                                    shops={data?.shops}
                                    customers={data?.activeCustomers}
                                    refetchData={refetch}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div
                            className={!auth.isAdmin() ? "tab-pane fade active show" : "tab-pane fade"}
                            id="custom-tabs-four-shopsettings"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-shopsettings-tab"
                        >
                            <PPShopSettings shops={data?.shops} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
