import React, { useEffect, useMemo, useState } from "react"
import GraphQL from "../utils/GraphQLClient"
import { useTranslation } from "react-i18next"
import { PPDefaultSelector } from "../components/PPDefaultSelector"
import { MultiValueLabel, Option } from "../components/PPShopPreset"
import { gql, useQuery } from "@apollo/client"
import DataTable from "react-data-table-component"
import PPUtils from "../utils/PPUtils"
import { toast } from "react-toastify"
import validator from "validator"
import CurrencyInput from "react-currency-input-field"
import { Grid, Switch } from "@mui/material"
import PPUtilsCatFilter from "../utils/PPUtilsCatFilter"

const query = gql`
    query getCats {
        shops(onlyActive: true) {
            label: name
            value: id
            lang
        }
        baseData {
            categories {
                ...selectFields
                type
                children {
                    ...selectFields
                    type
                    children {
                        ...selectFields
                        type
                        children {
                            ...selectFields
                            type
                        }
                    }
                }
            }
        }
    }
    fragment selectFields on Category {
        name
        value: name
        label: name
    }
`

const QUERY_PRODUCER = `query getProducer($department: String) {
  getProducerByQuery(
    department: $department
  ) {
    producerList {
        label: name
        value: id
    }
  }
}
`

const query_loadalerts = `mutation getLoadAlerts($userId: Int!) {
        loadAlerts(userId: $userId){
            id
            customerId
            name
            rule {
                shopIds
                productIds
                producerIds
                department
                productGroup
                productCat
                ruleCheck
                ruleValue
                rulePercent
                priceDiffToCustomerPriceInPercent
                minCustomerPriceForProductValidation
                priceDifferenceType
                exportType
                lowerPriceLimit
                stockCheckType
            }
            allowProductGroupingByProductNr
            checkType
            channel
            mails
            userId
            createDate
            active
        }
    }
`

const query_createalert = `mutation getCreateAlert($alert : AlertInput) {
        createAlert(alert : $alert) {
            customerId
            name
            rule {
                shopIds
                productIds
                producerIds
                department
                productGroup
                productCat
                ruleCheck
                ruleValue
                rulePercent
                priceDiffToCustomerPriceInPercent
                minCustomerPriceForProductValidation
                priceDifferenceType
                exportType
                lowerPriceLimit
                stockCheckType
            }
            allowProductGroupingByProductNr
            channel
            mails
            userId
            active
        }
    }
`

const query_deletealert = `mutation getDeleteAlert($alertId : Int!) {
        deleteAlert(alertId : $alertId)
    }
`

const CustomRowPriceAlertRulename = ({ row }) => {
    const [t] = useTranslation()

    let html_email

    let emaillisthtml = []
    row?.mails?.forEach((element) => {
        emaillisthtml.push(<div>{element}</div>)
    })
    html_email = <div>E-Mail: {emaillisthtml}</div>

    return (
        <div className="mt-2 mb-2">
            <div>
                <strong>{row?.name}</strong>
            </div>
            {html_email}
            <div>
                {t("single.checkType")}: {t("single." + row?.checkType)}
            </div>
        </div>
    )
}

const CustomRowPriceAlertRule = ({ row }) => {
    const [t] = useTranslation()

    return (
        <div className="mt-2 mb-2">
            <div>
                {t("single.rule")}: {t("single." + row?.rule?.ruleCheck)}
            </div>
            {row?.rule?.ruleValue && (
                <div>
                    {t("single.ruleValue")}: {row?.rule?.ruleValue}
                </div>
            )}
            {row?.rule?.rulePercent && (
                <div>
                    {t("single.rulePercent")}: {row?.rule?.rulePercent}
                </div>
            )}
            {row?.rule?.minCustomerPriceForProductValidation && (
                <div>
                    {t("single.minCustomerPrice")}: {row?.rule?.minCustomerPriceForProductValidation}
                </div>
            )}
            {row?.rule?.priceDiffToCustomerPriceInPercent && (
                <div>
                    {t("single.priceDiffToCustomerPrice")}: {row?.rule?.priceDiffToCustomerPriceInPercent}
                </div>
            )}
            {row?.rule?.priceDifferenceType && (
                <div>
                    {t("single.priceDiffType")}: {t("single." + row?.rule?.priceDifferenceType)}
                </div>
            )}
            {row?.rule?.exportType && (
                <div>
                    {t("single.exportType")}: {t("single." + row?.rule?.exportType)}
                </div>
            )}
            {row?.rule?.lowerPriceLimit !== 0 && (
                <div>
                    {t("single.lowerPriceLimit")} {row?.rule?.lowerPriceLimit}
                </div>
            )}
        </div>
    )
}

const CustomRowPriceAlertSettings = ({ row, shops, producers }) => {
    const [t] = useTranslation()

    let html_shops, html_department, html_product_group, html_product_category, html_producer, html_products

    if (row?.rule?.shopIds?.length > 0) {
        let shopslisthtml = []
        row?.rule?.shopIds?.forEach((element) => {
            let shop = shops?.find((e) => e.value == element)
            if (shop?.label) {
                shopslisthtml.push(
                    <div>
                        <span className={"fi fi-" + shop.lang} /> {shop.label}
                    </div>
                )
            }
        })

        html_shops = (
            <div>
                {t("single.shops")}: {shopslisthtml}
            </div>
        )
    }

    if (row?.rule?.producerIds?.length > 0) {
        let producerslisthtml = []
        row?.rule?.producerIds?.forEach((element) => {
            let producer = producers?.find((e) => e.value == element)
            if (producer?.label) {
                producerslisthtml.push(producer.label)
            }
        })
        html_producer = (
            <div>
                {t("single.manufacturer")}: {producerslisthtml.join(", ")}
            </div>
        )
    }
    if (row?.rule?.department) {
        html_department = (
            <div>
                {t("single.department")}: {row.rule.department.join(", ")}
            </div>
        )
    }
    if (row?.rule?.productGroup) {
        html_product_group = (
            <div>
                {t("single.product_group")}: {row.rule.productGroup.join(", ")}
            </div>
        )
    }
    if (row?.rule?.productCat) {
        html_product_category = (
            <div>
                {t("single.product_category")}: {row.rule.productCat.join(", ")}
            </div>
        )
    }
    if (row?.rule?.productIds?.length > 0) {
        html_products = (
            <div>
                {t("single.productnr")}: {row.rule.productIds.join(", ")}
            </div>
        )
    }

    return (
        <div className="mt-2 mb-2">
            {html_shops}
            {html_department}
            {html_producer}
            {html_products}
            {html_product_group}
            {html_product_category}
        </div>
    )
}

function PPPriceAlertsSetting(props) {
    const [t, i18n] = useTranslation()

    const [buttonCreateDisabled, setButtonCreateDisabled] = useState(true)

    const [name, setName] = useState("")
    const [emails, setEMails] = useState("")
    // const [selectedChannel, setSelectedChannel] = useState(null)
    const [selectedRule, setSelectedRule] = useState("price_changed")
    const [rulevalue, setRuleValue] = useState("1")
    const [selectedRulePercent, setSelectedRulePercent] = useState(null)
    const [selectedCheckType, setSelectedCheckType] = useState(null)
    const [selectedShops, setSelectedShops] = useState(null)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedProductGroup, setSelectedProductGroup] = useState(null)
    const [selectedProductCat, setSelectedProductCat] = useState(null)
    const [selectedProducer, setSelectedProducer] = useState(null)
    const [productnrs, setProductnrs] = useState("")
    const [selectedPriceDiffToCustomerPrice, setSelectedPriceDiffToCustomerPrice] = useState("")
    const [selectedMinCustomerPrice, setSelectedMinCustomerPrice] = useState("")
    const [selectedPriceDiffType, setSelectedPriceDiffType] = useState(null)
    const [selectedExportType, setSelectedExportType] = useState("csv")
    const [selectedLowerPriceLimit, setSelectedLowerPriceLimit] = useState("")
    const [selectedStockCheckType, setSelectedStockCheckType] = useState(null)
    const [isProductGroupByProdNr, setIsProductGroupByProdNr] = useState(false)

    const [dataShops, setDataShops] = useState([])
    const [dataDepartment, setDataDepartment] = useState([])
    const [dataProductGroup, setDataProductGroup] = useState([])
    const [dataProductCat, setDataProductCat] = useState([])
    const [dataProducer, setDataProducer] = useState([])
    // const [dataCannel] = useState([{ value: "mail", label: "E-Mail" }])
    const [dataCheckType] = useState([
        { value: "daily", label: t("single.daily") },
        { value: "instant", label: t("single.instant") }
    ])
    const [dataPriceDiffType] = useState([
        { value: "higher", label: t("single.higher") },
        { value: "both", label: t("single.both") },
        { value: "lower", label: t("single.lower") }
    ])
    const [dataStockCheckType] = useState([
        { value: "out_of_stock", label: t("single.outofstock") },
        { value: "back_in_stock", label: t("single.backinstock") },
        { value: "stock_changed", label: t("single.stockchanged") }
    ])

    const dataRulePercent = PPUtils.rangeNumber(1, 100).map((index) => ({ value: index, label: index + " %" }))

    const [dataTablePriceAlert, setDataTablePriceAlert] = useState(null)
    const [selectedDatatableRow, setSelectedDatatableRow] = useState(null)
    const [loadedPriceAlert, setLoadedPriceAlert] = useState(null)

    useEffect(() => {
        let rulechecked = false
        if (selectedRule == "price_changed" && rulevalue?.length > 0) {
            rulechecked = true
        }
        if (selectedRule == "price_diff" && rulevalue?.length > 0 && selectedRulePercent?.value > 0) {
            rulechecked = true
        }
        if (selectedRule == "stock" && rulevalue?.length > 0 && selectedStockCheckType?.value) {
            rulechecked = true
        }

        if (
            name?.length > 0 &&
            emails?.length > 0 &&
            selectedRule?.length > 0 &&
            selectedCheckType?.value.length > 0 &&
            rulechecked
        ) {
            setButtonCreateDisabled(false)
        } else {
            setButtonCreateDisabled(true)
        }
    }, [name, emails, selectedRule, rulevalue, selectedRulePercent, selectedCheckType, selectedStockCheckType])

    useEffect(() => {
        let variables = {
            department: selectedDepartment ? selectedDepartment.value : ""
        }
        GraphQL.getClient()
            .request(QUERY_PRODUCER, variables)
            .then((data) => {
                setDataProducer(data?.getProducerByQuery?.producerList)
            })
            .catch((error) => console.error(error))

        if(selectedDepartment !== null && typeof selectedDepartment !== 'undefined')   {
            let productGroups = []
            selectedDepartment.forEach((item) => {
                if (item?.children?.length > 0) {
                    productGroups = productGroups.concat(item?.children)
                }
            })
            if (selectedDepartment?.length === 0 && productGroups.length === 0) {
                GraphQL.getClient()
                .request(query, variables)
                .then((data) => {
                    let productGroups = PPUtilsCatFilter.formatGroups(data?.baseData?.categories, "PRODUCTGROUP")
                    setDataProductGroup(productGroups)
                    setDataProductCat(PPUtilsCatFilter.formatGroups(productGroups, "PRODUCTCAT"))
                })
                .catch((error) => console.error(error))
               
            } else {
                setDataProductCat([])
                setDataProductGroup(productGroups)
            }
        }
    }, [selectedDepartment])

    useEffect(() => {
        if(selectedProductGroup !== null && typeof selectedProductGroup !== 'undefined')   {
            let productCats = []
            selectedProductGroup.forEach((item) => {
                if (item?.children?.length > 0) {
                    productCats = productCats.concat(item?.children)
                }
            })
            if (selectedDepartment?.length === 0 && selectedProductGroup.length === 0) {
                let variables = {
                    department: selectedDepartment ? selectedDepartment.value : ""
                }
                GraphQL.getClient()
                .request(query, variables)
                .then((data) => {
                    let productGroups = PPUtilsCatFilter.formatGroups(data?.baseData?.categories, "PRODUCTGROUP")
                    setDataProductCat(PPUtilsCatFilter.formatGroups(productGroups, "PRODUCTCAT"))
                })
                .catch((error) => console.error(error))
               
            } else {
                setDataProductCat(productCats)
            }
        }
    }, [selectedProductGroup])

    
    useQuery(query, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setDataShops(data?.shops)
            setDataDepartment(data?.baseData?.categories)
            let vDataformatGroups = PPUtilsCatFilter.formatGroups(data.baseData.categories, "PRODUCTGROUP")
            setDataProductGroup(vDataformatGroups)
            let vDataformatCats = PPUtilsCatFilter.formatGroups(vDataformatGroups, "PRODUCTCAT")
            setDataProductCat(vDataformatCats)
        
        }
    })

    const CustomRowActive = ({ row }) => {
        const [vName] = useState("id-" + row.id)
        const [isChecked, setIsChecked] = useState(row.active)
        const defaultChecked = false
        return (
            <div className="mt-2 mb-2">
                <div className="form-group">
                    <div className="custom-control custom-switch">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id={vName}
                            defaultChecked={defaultChecked}
                            checked={isChecked ? isChecked !== "false" : defaultChecked}
                            onChange={(event) => {
                                setIsChecked(event.target.checked.toString())
                                row.active = event.target.checked.toString()
                                updatePriceAlert(row)
                            }}
                        />
                        <label className="custom-control-label" htmlFor={vName}>
                            {""}
                        </label>
                    </div>
                    <div className="smallinfo">{""}</div>
                </div>
            </div>
        )
    }

    const CustomRowDelete = ({ row }) => {
        return (
            <div className="mt-2 mb-2">
                <button className="btn btn-primary" type="button" onClick={() => setValues(row)}>
                    {t("single.change")}
                </button>{" "}
                <button
                    className="btn btn-danger mt-2"
                    data-toggle="modal"
                    data-target="#deleteAlertModal"
                    type="button"
                    onClick={() => setSelectedDatatableRow(row)}>
                    {t("single.delete")}
                </button>
            </div>
        )
    }

    const columns = useMemo(() => [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "80px"
        },
        {
            name: t("single.create_date"),
            selector: (row) => row.createDate,
            cell: (row) => {
                return new Intl.DateTimeFormat(i18n.language, {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(new Date(row.createDate))
            },
            width: "140px"
        },
        {
            name: t("single.rulename"),
            selector: (row) => row.name,
            cell: (row) => <CustomRowPriceAlertRulename row={row} />
        },
        {
            name: t("single.rule"),
            selector: (row) => row.name,
            cell: (row) => <CustomRowPriceAlertRule row={row} />
        },

        {
            name: t("single.settings"),
            selector: (row) => row.id,
            cell: (row) => <CustomRowPriceAlertSettings row={row} shops={dataShops} producers={dataProducer} />
        },
        {
            name: t("single.active"),
            selector: (row) => row.id,
            cell: (row) => <CustomRowActive row={row} />,
            width: "80px"
        },
        {
            name: "",
            selector: (row) => row.id,
            cell: (row) => <CustomRowDelete row={row} />,
            width: "120px"
        }
    ])

    function getVariables() {
        return {
            alert: {
                id: 0,
                userId: props?.userId,
                customerId: props?.customerId !== null && props.customerId !== undefined ? props?.customerId : 1,
                active: true,
                name: name,
                mails: emails ? emails.split(",") : null,
                checkType: selectedCheckType ? selectedCheckType.value : "",
                channel: "mail",
                allowProductGroupingByProductNr: isProductGroupByProdNr,
                rule: {
                    ruleCheck: selectedRule,
                    ruleValue: rulevalue,
                    rulePercent: selectedRulePercent ? selectedRulePercent.value : "",
                    department: selectedDepartment === null || selectedDepartment === undefined
                            ? null
                            : selectedDepartment.map((department) => department.value),
                    productGroup: selectedProductGroup === null || selectedProductGroup === undefined
                    ? null
                    : selectedProductGroup.map((productGroup) => productGroup.value),
                    productCat: selectedProductCat === null || selectedProductCat === undefined
                    ? null
                    : selectedProductCat.map((productCat) => productCat.value),
                    producerIds:
                        selectedProducer === null || selectedProducer === undefined
                            ? null
                            : selectedProducer.map((producer) => producer.value),
                    shopIds:
                        selectedShops === null || selectedShops === undefined
                            ? null
                            : selectedShops.map((shop) => shop.value),
                    productIds: productnrs ? productnrs.split(",") : null,
                    priceDiffToCustomerPriceInPercent: selectedPriceDiffToCustomerPrice
                        ? selectedPriceDiffToCustomerPrice
                        : "",
                    minCustomerPriceForProductValidation: selectedMinCustomerPrice ? selectedMinCustomerPrice : "",
                    priceDifferenceType: selectedPriceDiffType?.value,
                    exportType: selectedExportType,
                    lowerPriceLimit: selectedLowerPriceLimit ? selectedLowerPriceLimit : 0,
                    stockCheckType: selectedStockCheckType?.value
                }
            }
        }
    }

    function resetValues() {
        setLoadedPriceAlert(null)

        setName("")
        setEMails("")
        setSelectedCheckType("")
        setSelectedRule("price_changed")
        setRuleValue("")
        setSelectedRulePercent(null)
        setSelectedPriceDiffToCustomerPrice("")
        setSelectedMinCustomerPrice("")
        setSelectedShops(null)
        setSelectedDepartment(null)
        setSelectedProductGroup(null)
        setSelectedProductCat(null)
        setSelectedProducer(null)
        setProductnrs("")
        setSelectedPriceDiffType(null)
        setSelectedExportType("csv")
        setSelectedLowerPriceLimit("")
        setIsProductGroupByProdNr(false)
    }
    function setValues(row) {
        resetValues()
        setLoadedPriceAlert(row)

        setName(row?.name)
        setEMails(row?.mails?.join(","))
        setSelectedCheckType(dataCheckType?.find((e) => e.value == row?.checkType))
        setSelectedRule(row?.rule?.ruleCheck)
        setRuleValue(row?.rule?.ruleValue)
        setSelectedRulePercent(dataRulePercent?.find((e) => e.value == row?.rule?.rulePercent))
        setSelectedPriceDiffToCustomerPrice(row?.rule?.priceDiffToCustomerPriceInPercent)
        setSelectedMinCustomerPrice(row?.rule?.minCustomerPriceForProductValidation)
        setSelectedPriceDiffType(dataPriceDiffType?.find((e) => e.value == row?.rule?.priceDifferenceType))
        setSelectedExportType(row?.rule?.exportType === null ? "csv" : row?.rule?.exportType)
        setSelectedLowerPriceLimit(row?.rule?.lowerPriceLimit !== 0 ? row?.rule?.lowerPriceLimit : "")
        setSelectedStockCheckType(dataStockCheckType?.find((e) => e.value == row?.rule?.stockCheckType))
        setIsProductGroupByProdNr(row?.allowProductGroupingByProductNr)

        if (row?.rule?.producerIds?.length > 0) {
            let producers = []
            row?.rule?.producerIds?.forEach((element) => {
                let producer = dataProducer?.find((e) => e.value == element)
                producers.push(producer)
            })
            if (producers.length > 0 && producers[0] !== undefined) {
                setSelectedProducer(producers)
            }
        }

        if (row?.rule?.department?.length > 0) {
            let departments = []
            row?.rule?.department?.forEach((element) => {
                let department = dataDepartment?.find((e) => e.value == element)
                departments.push(department)
            })
            if (departments.length > 0 && departments[0] !== undefined) {
                setSelectedDepartment(departments)
            }
        }

        if (row?.rule?.productGroup?.length > 0) {
            let productGroups = []
            row?.rule?.productGroup?.forEach((element) => {
                let productGroup = dataProductGroup?.find((e) => e.value == element)
                productGroups.push(productGroup)
            })
            if (productGroups.length > 0 && productGroups[0] !== undefined) {
                setSelectedProductGroup(productGroups)
            }
        }

        if (row?.rule?.productCat?.length > 0) {
            let productCats = []
            row?.rule?.productCat?.forEach((element) => {
                let productCat = dataProductCat?.find((e) => e.value == element)
                productCats.push(productCat)
            })
            if (productCats.length > 0 && productCats[0] !== undefined) {
                setSelectedProductCat(productCats)
            }
        }

        if (row?.rule?.shopIds?.length > 0) {
            let shops = []
            row?.rule?.shopIds?.forEach((element) => {
                let shop = dataShops?.find((e) => e.value == element)
                shops.push(shop)
            })
            if (shops.length > 0 && shops[0] !== undefined) {
                setSelectedShops(shops)
            }
        }

        setProductnrs(row?.rule?.productIds?.join(","))
    }

    function checkValidation() {
        let formIsValid = true

        if (emails?.length > 0) {
            let check_emails = emails.split(",")
            check_emails.forEach(function (email) {
                if (!validator.isEmail(email)) {
                    formIsValid = false
                    toast.error(t("single.mandatoryfield_error", { field: "E-Mail" }))
                }
            })
        }

        if (rulevalue?.length > 0) {
            if (!rulevalue.match(/^\d+$/)) {
                formIsValid = false
                toast.error(t("single.mandatoryfield_error", { field: t("single.ruleValue") }))
            } else if (rulevalue > 5000) {
                formIsValid = false
                toast.error(t("pricealerts.error.rule_value_too_high"))
            }
        }

        if (productnrs?.length > 0) {
            if (!PPUtils.checkStringInputProductNrs(productnrs)) {
                formIsValid = false
                toast.error(t("single.mandatoryfield_error", { field: t("single.productnr") }))
            }
        }

        if (
            checkIfEmpty(selectedShops) &&
            checkIfEmpty(productnrs) &&
            checkIfEmpty(selectedProducer) &&
            checkIfEmpty(selectedCheckType)
        ) {
            formIsValid = false
            toast.error(t("pricealerts.error.no_filter_set"))
        }

        return formIsValid
    }

    function checkIfEmpty(object) {
        return object === undefined || object === null || object === ""
    }

    function createPriceAlert(saveasnew) {
        if (checkValidation()) {
            let variables = getVariables()
            if (loadedPriceAlert?.id > 0) {
                variables.alert.id = loadedPriceAlert?.id
                variables.alert.active = loadedPriceAlert?.active
            }
            if (saveasnew) {
                variables.alert.id = 0
            }
            GraphQL.getClient()
                .request(query_createalert, variables)
                .then(() => {
                    refreshData()
                    resetValues()
                })
                .catch((error) => console.error(error))
        }
    }

    function updatePriceAlert(row) {
        let variables = {
            alert: row
        }
        delete variables.alert["createDate"]
        GraphQL.getClient()
            .request(query_createalert, variables)
            .then(() => {
                refreshData()
            })
            .catch((error) => console.error(error))
    }

    function deletePriceAlert(row) {
        if (row?.id > 0) {
            GraphQL.getClient()
                .request(query_deletealert, { alertId: row?.id })
                .then((/*data*/) => {
                    refreshData()
                })
                .catch((error) => console.error(error))
        }
    }

    useEffect(() => {
        refreshData()
    }, [])

    function refreshData() {
        GraphQL.getClient()
            .request(query_loadalerts, {
                userId: props?.userId
            })
            .then((data) => {
                setDataTablePriceAlert(data?.loadAlerts)
            })
            .catch((error) => console.error(error))
    }

    if (dataShops.length === 0 || props.userId === undefined) {
        return null
    }

    return (
        <div>
            <div className="card card-default">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <label>{t("single.rulename")}*</label>
                            <input
                                type="input"
                                className="form-control"
                                id="inputPricealertsName"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                value={name}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t("single.checkType")}*</label>
                            <PPDefaultSelector
                                id="selectPricealertsCheckType"
                                data={dataCheckType}
                                onChange={setSelectedCheckType}
                                value={selectedCheckType}
                                defaultValue={dataCheckType[0]}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t("single.ruleValue")} *</label>
                            <input
                                type="input"
                                className="form-control"
                                id="inputPricealertsRuleValue"
                                onChange={(e) => {
                                    setRuleValue(e.target.value)
                                }}
                                value={rulevalue}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>{"E-Mail (" + t("single.comma_separated") + ")"}*</label>
                            <input
                                type="input"
                                className="form-control"
                                id="inputPricealertsEMails"
                                onChange={(e) => {
                                    setEMails(e.target.value)
                                }}
                                value={emails}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-default">
                <div className="card-body">
                    <label className="row col-6">{t("single.checkType")}*</label>
                    <div className="btn-group btn-group-toggle mb-2" data-toggle="buttons">
                        <label
                            className={`btn btn-outline-primary ${
                                selectedRule === "price_changed" || selectedRule === "" ? "active" : ""
                            }`}>
                            <input
                                type="radio"
                                name="options"
                                id="option_a1"
                                autoComplete="off"
                                checked={selectedRule === "price_changed" || selectedRule === ""}
                                onChange={() => {
                                    setSelectedRule("price_changed")
                                }}
                            />{" "}
                            {t("single.price_changed")}
                        </label>
                        <label className={`btn btn-outline-primary ${selectedRule === "price_diff" ? "active" : ""}`}>
                            <input
                                type="radio"
                                name="options"
                                id="option_a2"
                                autoComplete="off"
                                checked={selectedRule === "price_diff"}
                                onChange={() => {
                                    setSelectedRule("price_diff")
                                }}
                            />{" "}
                            {t("single.price_diff")}
                        </label>
                        <label className={`btn btn-outline-primary ${selectedRule === "stock" ? "active" : ""}`}>
                            <input
                                type="radio"
                                name="options"
                                id="option_a3"
                                autoComplete="off"
                                checked={selectedRule === "stock"}
                                onChange={() => {
                                    setSelectedRule("stock")
                                }}
                            />{" "}
                            {t("single.stock")}
                        </label>
                    </div>

                    <div className="row" hidden={selectedRule !== "price_changed" && selectedRule !== ""}>
                        <div className="col-6">
                            <label>{t("single.priceDiffToCustomerPrice")}</label>
                            <CurrencyInput
                                id="selectPriceDiffToCustomerPrice"
                                intlConfig={{
                                    locale: "en-US"
                                }}
                                className={`form-control`}
                                onValueChange={setSelectedPriceDiffToCustomerPrice}
                                decimalsLimit={2}
                                suffix={"%"}
                                allowNegativeValue={false}
                                value={selectedPriceDiffToCustomerPrice}
                                step={1}
                                maxLength={13}
                            />
                        </div>
                    </div>

                    <div className="row" hidden={selectedRule !== "stock"}>
                        <div className="col-4">
                            <label>{t("single.stockCheckType")}</label>
                            <i
                                className="fa fa-info-circle text-primary ml-1 text-md"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={t("single.hintStockCheckType")}></i>
                            <PPDefaultSelector
                                id="selectStockCheckType"
                                data={dataStockCheckType}
                                onChange={(optionSelected) => {
                                    setSelectedStockCheckType(optionSelected)
                                }}
                                value={selectedStockCheckType}
                            />
                        </div>
                    </div>

                    <div className="row" hidden={selectedRule !== "price_diff"}>
                        <div className="col-6">
                            <label>{t("single.rulePercent")} *</label>
                            <PPDefaultSelector
                                id="selectPricealertsRulePercent"
                                data={dataRulePercent}
                                onChange={setSelectedRulePercent}
                                value={selectedRulePercent}
                                className="form-control"
                            />
                        </div>
                        <div className="col-6">
                            <label>{t("single.priceDiffType")}</label>
                            <PPDefaultSelector
                                id="selectPricediffType"
                                data={dataPriceDiffType}
                                onChange={(optionSelected) => {
                                    setSelectedPriceDiffType(optionSelected)
                                }}
                                value={selectedPriceDiffType}
                            />
                        </div>
                        <div className="col-6">
                            <label>{t("single.priceDiffToCustomerPrice")}</label>
                            <CurrencyInput
                                id="selectPriceDiffToCustomerPrice"
                                intlConfig={{
                                    locale: "en-US"
                                }}
                                className={`form-control`}
                                onValueChange={setSelectedPriceDiffToCustomerPrice}
                                decimalsLimit={2}
                                suffix={"%"}
                                allowNegativeValue={false}
                                value={selectedPriceDiffToCustomerPrice}
                                step={1}
                                maxLength={13}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-default">
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <label>{t("single.shops")}</label>
                            <PPDefaultSelector
                                id="selectPricealertsShops"
                                isMulti={true}
                                data={dataShops}
                                components={{ MultiValueLabel, Option }}
                                onChange={setSelectedShops}
                                value={selectedShops}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t("single.manufacturer")}</label>
                            <PPDefaultSelector
                                id="selectPricealertsManufacturer"
                                isMulti={true}
                                data={dataProducer}
                                components={{ MultiValueLabel, Option }}
                                onChange={(evt) => setSelectedProducer(evt)}
                                name="producer"
                                value={selectedProducer}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t("single.lowerPriceLimit")}</label>
                            <CurrencyInput
                                id="selectLowerPriceLimit"
                                intlConfig={{
                                    //EUR um die englische Schreibweise mit . beizubehalten und Symbol €
                                    //Für andere Währungen muss die Stelle noch angepasst werden.
                                    locale: "en-US"
                                }}
                                className={`form-control`}
                                onValueChange={setSelectedLowerPriceLimit}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                value={selectedLowerPriceLimit}
                                step={1}
                                maxLength={13}
                            />
                        </div>
                        <div className="col-3 text-lg-right">
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                data-toggle="modal"
                                data-target="#helpModal">
                                {t("single.help")}
                            </button>
                            <div
                                className="modal fade"
                                id="helpModal"
                                tabIndex="-1"
                                aria-labelledby="helpModal"
                                aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="row pt-3">
                                                <div className="col-12 text-left">
                                                    <strong>{t("single.notes")}</strong>
                                                    <ul>
                                                        <li>{t("pricealerts.text_information_1")}</li>
                                                        <li>{t("pricealerts.text_information_2")}</li>
                                                        <li>{t("pricealerts.text_information_3")}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" data-dismiss="modal">
                                                {t("single.close")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label>{t("single.department")}</label>
                            <PPDefaultSelector
                                id="selectPricealertsDepartments"
                                isMulti={true}
                                data={dataDepartment}
                                components={{ MultiValueLabel, Option }}
                                onChange={setSelectedDepartment}
                                value={selectedDepartment}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t("single.product_group")}</label>
                            <PPDefaultSelector
                                id="selectPricealertsProductGroups"
                                isMulti={true}
                                data={dataProductGroup}
                                components={{ MultiValueLabel, Option }}
                                onChange={setSelectedProductGroup}
                                value={selectedProductGroup}
                            />
                        </div>
                        <div className="col-3">
                            <label>{t("single.product_category")}</label>
                            <PPDefaultSelector
                                id="selectPricealertsProductCats"
                                isMulti={true}
                                data={dataProductCat}
                                components={{ MultiValueLabel, Option }}
                                onChange={setSelectedProductCat}
                                value={selectedProductCat}
                            />
                        </div>
                    </div>    
                    <div className="row">
                        <div className="col-6 mt-2">
                            <label>{t("single.productnr") + " (" + t("single.comma_separated") + ")"}</label>
                            <input
                                type="input"
                                className="form-control"
                                id="inputPricealertsProductnrs"
                                onChange={(e) => {
                                    setProductnrs(e.target.value)
                                }}
                                value={productnrs}
                            />
                            <div className="col form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="allow_product_grouping_by_productnr"
                                    onChange={(event) => {
                                        setIsProductGroupByProdNr(event.target.checked)
                                    }}
                                    checked={
                                        isProductGroupByProdNr
                                            ? isProductGroupByProdNr !== "false"
                                            : isProductGroupByProdNr
                                    }
                                />
                                <label className="form-check-label" htmlFor="allow_product_grouping_by_productnr">
                                    {t("single.allow_product_grouping_by_productnr")}
                                </label>
                                <i
                                    className="fa fa-info-circle text-primary ml-1 text-md"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={t("single.hintGrouping")}></i>
                            </div>
                        </div>
                        <div className="col-3 mt-2">
                            <label>{t("single.minCustomerPrice")}</label>
                            <CurrencyInput
                                id="selectMinCustomerPrice"
                                intlConfig={{
                                    //EUR um die englische Schreibweise mit . beizubehalten und Symbol €
                                    //Für andere Währungen muss die Stelle noch angepasst werden.
                                    locale: "en-US"
                                }}
                                className={`form-control`}
                                onValueChange={setSelectedMinCustomerPrice}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                value={selectedMinCustomerPrice}
                                step={1}
                                maxLength={13}
                            />
                        </div>

                        <div className="col-3 mt-2 custom-control custom-switch">
                            <label className="mb-0">{t("single.exportType")}</label>
                            <Grid component="label" container alignItems="center" spacing={0}>
                                <Grid item>{t("single.csv")}</Grid>
                                <Grid item>
                                    <Switch
                                        checked={selectedExportType === "csv" ? false : true}
                                        onChange={(event) => {
                                            setSelectedExportType(event.target.checked === true ? "excel" : "csv")
                                        }}
                                        value={selectedExportType}
                                    />
                                </Grid>
                                <Grid item>{t("single.excel")}</Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 text-lg-right">
                    <button
                        id="button_submit_reset"
                        className="category-button-go btn btn-outline-primary mr-2"
                        type="button"
                        onClick={() => resetValues()}>
                        {t("single.reset")}
                    </button>
                    {loadedPriceAlert?.id > 0 && (
                        <button
                            id="button_submit_check"
                            className="category-button-go btn btn-primary mx-2"
                            type="button"
                            disabled={buttonCreateDisabled}
                            onClick={() => createPriceAlert(true)}>
                            {t("single.saveasnew")}
                        </button>
                    )}
                    <button
                        id="button_submit_check"
                        className="category-button-go btn btn-primary ml-2"
                        type="button"
                        disabled={buttonCreateDisabled}
                        onClick={() => createPriceAlert()}>
                        {loadedPriceAlert?.id > 0 ? t("single.save") : t("single.create")}
                    </button>
                </div>
            </div>

            <div className="mt-3 datatable_headerbold">
                {dataTablePriceAlert && <DataTable noHeader columns={columns} data={dataTablePriceAlert} />}
            </div>
            <div
                className="modal fade"
                id="deleteAlertModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="deletePresetModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deletePresetModalLabel">
                                {t("single.delete")} ?
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {t("crawler.prioritized_crawling_modal_delete_text", { id: selectedDatatableRow?.id })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.cancel")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    deletePriceAlert(selectedDatatableRow)
                                }}>
                                {t("single.delete")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PPPriceAlertsSetting
