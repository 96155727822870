import React, { useRef, useState, useEffect } from "react"
import { useAuth } from "../hooks/use-auth"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { ChangePassword } from "./ChangePassword"
import { gql, useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { AvatarEditor } from "./AvatarEditor"
import PPUtils from "../utils/PPUtils"

// SettingsService!
const UPDATE_USER_QUERY = gql`
    mutation UpdateSettingsForUser($user: UserInput!) {
        updateSettingsForUser(user: $user) {
            id
            email
            username
            name
            avatarUrl
        }
    }
`

export function PPUserSettings(props) {
    const auth = useAuth()
    const [t, i18n] = useTranslation()
    const currentAuth =
        auth?.login?.customerUsers?.length > 0 &&
        auth?.login?.token_original !== undefined &&
        auth?.login?.token !== auth?.login?.token_original
            ? auth.login.customerUsers.find((customerUser) => customerUser.token === auth.login.token)
            : auth.login
    const emailRef = useRef()
    const nameRef = useRef()

    const [languages] = useState(
        Object.keys(i18n.services.resourceStore.data).map((x) => ({
            value: x,
            label: t("app.langs." + x)
        }))
    )

    const [choosenLanguage, setChoosenLanguage] = useState(languages.find((x) => x.value === i18n.language))
    const [choosenPreset, setChoosenPreset] = useState(
        props?.presets.find((e) => e.value === currentAuth?.user?.configShowTablePreset)
    )
    const avatarConfig = useState(currentAuth?.user?.avatarUrl ? currentAuth?.user?.avatarUrl : "")

    useEffect(() => {
        if (props?.defaultPresetId == 0 && props?.presets?.length > 0) {
            setChoosenPreset(props?.presets[0])
        }
    }, [props])

    const [updateUser] = useMutation(UPDATE_USER_QUERY, {
        onCompleted: (data) => {
            let currentAuthLogin = PPUtils.getLogin()
            if (currentAuthLogin?.user?.id == data?.updateSettingsForUser?.id) {
                currentAuthLogin.user.name = data?.updateSettingsForUser?.name
                currentAuthLogin.user.avatarUrl = data?.updateSettingsForUser?.avatarUrl
                currentAuthLogin.user.configLanguage = choosenLanguage?.value
                PPUtils.updateLogin(currentAuthLogin)
            }

            let dataCurrentAuth = {
                name: data?.updateSettingsForUser.name,
                avatarUrl: data?.updateSettingsForUser.avatarUrl
            }
            props.setDataCurrentAuth(dataCurrentAuth)

            i18n.changeLanguage(choosenLanguage?.value)

            toast.success(t("settings.user.toast_save_ok", { name: data?.updateSettingsForUser?.username }))

            props.refetchSettingsData()
        },
        onError: (error) => {
            if (error.message.toString().length > 0) {
                toast.error(t("settings.user.toast_error", { msg: "" }))
            }
        }
    })

    const submitData = () => {
        let userUpdateData = {
            variables: {
                user: {
                    id: currentAuth?.user?.id,
                    avatarUrl: avatarConfig[0],
                    email: emailRef.current.value,
                    name: nameRef.current.value,
                    configLanguage: choosenLanguage.value,
                    configShowTablePreset: choosenPreset.value
                }
            }
        }
        updateUser(userUpdateData)

        if (!auth?.login.customerUsers?.length > 0) {
            auth.setLogin((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    avatarUrl: avatarConfig[0],
                    email: userUpdateData.variables.user.email,
                    name: userUpdateData.variables.user.name,
                    configLanguage: choosenLanguage.value,
                    configShowTablePreset: choosenPreset.value
                }
            }))
        } else {
            auth?.login?.customerUsers?.map((customerUser) => {
                if (customerUser.token === auth?.login?.token) {
                    customerUser.user.avatarUrl = avatarConfig[0]
                    customerUser.user.email = userUpdateData.variables.user.email
                    customerUser.user.name = userUpdateData.variables.user.name
                    customerUser.user.configLanguage = choosenLanguage.value
                    customerUser.user.configShowTablePreset = choosenPreset.value
                }
            })
            auth.setLogin((prev) => ({
                ...prev,
                customerUsers: auth?.login?.customerUsers
            }))
        }
    }

    return (
        <div className="card shadow">
            <div className="card-header">
                <h2 className="card-title">{t("single.user") + " " + t("single.settings")}</h2>
            </div>
            <div className="card-body">
                <div className="row my-4">
                    <div
                        className="col d-xl-flex flex-column flex-sm-grow-1 flex-xl-grow-0 justify-content-xl-start mx-4"
                        style={{ marginBottom: "15px" }}>
                        <AvatarEditor avatarState={avatarConfig} name={currentAuth?.user?.name} />
                        <ChangePassword avatarState={avatarConfig} />
                    </div>
                    <div className="col-sm-12 col-xl-4 flex-grow-1">
                        <div className="form-group">
                            <label>{t("single.username")}</label>
                            <input
                                id="inputUserSettingsUsername"
                                type="text"
                                className="form-control"
                                readOnly
                                defaultValue={currentAuth?.user?.username}
                            />
                        </div>
                        <div className="form-group">
                            <label>E-Mail</label>
                            <input
                                id="inputUserSettingsEmail"
                                type="text"
                                className="form-control"
                                ref={emailRef}
                                defaultValue={currentAuth?.user?.email}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t("single.displayname")}</label>
                            <input
                                id="inputUserSettingsDisplayname"
                                type="text"
                                className="form-control"
                                ref={nameRef}
                                defaultValue={currentAuth?.user?.name}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t("single.language")}</label>
                            <Select
                                id="selectUserSettingsLanguage"
                                options={languages}
                                onChange={(selected) => {
                                    setChoosenLanguage(selected)
                                }}
                                value={choosenLanguage}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t("single.defaultPreset")}</label>
                            <Select
                                id="selectUserSettingsPreset"
                                options={props.presets}
                                onChange={(selected) => {
                                    setChoosenPreset(selected)
                                }}
                                value={choosenPreset}
                            />
                        </div>
                        <div className="text-right">
                            <button
                                id="selectUserSettingsSave"
                                className="btn btn-primary"
                                style={{ minWidth: "100px" }}
                                type="button"
                                onClick={() => submitData()}>
                                {t("single.save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
