import React from "react"
import jquery from "jquery"
import Cookies from "universal-cookie"

export function SearchBar(props) {
    const cookies = new Cookies()
    let vjbody = jquery("body")
    let vdm = "dark-mode"

    function handleChangeDarkmode(e) {
        props.setLightMode(!e.target.checked)
        if (vjbody.hasClass(vdm)) {
            vjbody.removeClass(vdm)
            cookies.remove("ppfrontend2_darkmode", { path: "/" })
        } else {
            vjbody.addClass(vdm)
            let expires = new Date()
            expires.setTime(expires.getTime() + 60 * 60 * 24 * 365 * 10 * 1000)
            cookies.set("ppfrontend2_darkmode", true, { path: "/", expires: expires })
        }
    }

    return (
        <form className="form-inline ml-3" onSubmit={props.onSubmit}>
            <div className="input-group input-group-sm">
                <input
                    id="inputSearch"
                    name="searchinput"
                    className="form-control form-control-navbar"
                    type="search"
                    placeholder="EAN, Name, .."
                    aria-label="Search"
                    defaultValue={props.searchword}
                />
                <div className="input-group-append input-group-sm">
                    <div className="input-group-append text-md">
                        <button id="inputSearchButton" type="submit" className="btn btn-primary btn-flat btn-xs">
                            <i className="fas fa-search" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mx-3 custom-control custom-switch custom-switch-off-light custom-switch-on-secondary">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch3"
                    onChange={handleChangeDarkmode}
                    defaultChecked={!props.lightMode}
                />
                <label className="custom-control-label font-weight-normal" htmlFor="customSwitch3">
                    Darkmode
                </label>
            </div>
        </form>
    )
}
