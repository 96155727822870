import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const fallbackLng = "de"
const availableLanguages = []

let context = require.context("./locales/", false)
let resources = {}

context.keys().forEach((filename) => {
    if (filename.endsWith(".json")) {
        const locale = filename.replace(".json", "").replace("./", "")
        const fileContext = context(filename)
        resources[locale] = {
            translation: fileContext
        }
        availableLanguages.push(locale)
    }
})

i18n
    //.use(Backend) // load translations using http (default public/assets/locals/en/translations)
    //.use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        resources,
        fallbackLng: fallbackLng,
        whitelist: availableLanguages,
        debug: false,
        detection: {
            checkWhitelist: false // options for language detection
        },
        interpolation: {
            escapeValue: false // no need for react. it escapes by default
        }
    })

export default i18n
