import React from "react"
import Select from "react-select"

const customStyles = {
    control: (base) => ({
        ...base,
        height: "35px",
        minHeight: "35px"
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 4
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0px 6px"
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0
    })
}

export function PPPresetSelector(props) {
    if (!props.presetId) return null

    return (
        <div className="col">
            <Select
                id={props.id}
                options={props.presets}
                styles={customStyles}
                placeholder={"Preset auswählen"}
                onChange={(selected) => props.changePresetId(selected.value)}
                value={props.presets.filter((option) => option.value === props.presetId)}
                defaultValue={props.presets.filter((option) => option.value === props.presetId)}
            />
        </div>
    )
}
