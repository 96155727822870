import React from "react"
import Select from "react-select"
import PPUtils from "../utils/PPUtils"

function PPSelectCustomers(props) {
    let auth = PPUtils.getLogin()
    let customers = []

    if (auth.user.rights == "admin" && auth.customerUsers?.length >= 1) {
        if (!auth.token_original) {
            auth.token_original = auth.token
            PPUtils.updateLogin(auth)
        }

        customers = auth.customerUsers.reduce(function (previous, current) {
            return previous.concat({
                value: current.token,
                label: current.customer.name,
                rights: current.user.rights,
                configShowTablePreset: current.user.configShowTablePreset,
                frontendUploadImport: current.user.frontendUploadImport
            })
        }, [])
        customers.unshift({
            value: auth.token_original,
            label: auth.user.name,
            rights: auth.user.rights,
            configShowTablePreset: auth.user.configShowTablePreset,
            frontendUploadImport: auth.user.frontendUploadImport
        })
        let selectedValue = customers.find((e) => e.value == auth.token)

        return (
            <div id="sidebar-customers">
                <form>
                    <Select
                        id="selectCustomers"
                        options={customers}
                        onChange={(event) => {
                            if (event) {
                                auth = PPUtils.getLogin()
                                auth.token = event.value
                                auth.token_name = event.label
                                auth.token_rights = event.rights
                                if (event.label.toLocaleLowerCase().indexOf("sylphen") >= 0) {
                                    auth.user.frontendUploadImport = ""
                                } else {
                                    auth.user.frontendUploadImport = event.frontendUploadImport
                                }
                                PPUtils.updateLogin(auth)
                                props.changePresetId(event.configShowTablePreset)
                                window.location.reload()
                            }
                        }}
                        isClearable={false}
                        name="selectcustomers"
                        value={selectedValue}
                    />
                </form>
            </div>
        )
    }

    return ""
}

export default PPSelectCustomers
