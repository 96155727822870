import "../App.css"
import moment from "moment"
import React from "react"
import i18n_iso_countrie_de from "i18n-iso-countries/langs/de.json"
import i18n_iso_countrie_en from "i18n-iso-countries/langs/en.json"
import countries from "i18n-iso-countries"
import Cookies from "universal-cookie"
import jquery from "jquery"
import _ from "lodash"
import { Avatar } from "@mui/material"

countries.registerLocale(i18n_iso_countrie_de)
countries.registerLocale(i18n_iso_countrie_en)

class PPUtils {
    static isDev() {
        if (window.location.hostname == "localhost" && window.location.port == "3000") {
            return true
        }
        return false
    }

    static isTariffComparisonMode(modus) {
        if (modus === "Y") {
            return true
        }
        return false
    }

    static get Colors() {
        return {
            GreenStart: "#56b455",
            GreenEnd: "#30b314",
            YellowStart: "#f8de52",
            YellowEnd: "#ffc939",
            RedStart: "#ff7451",
            RedEnd: "#ff3636",
            NeutralStart: "#007bff",
            NeutralEnd: "#005cbd",
            OrangeStart: "#ff963a",
            OrangeEnd: "#ff5529",
            GreyLight: "#bbbbbb",
            Black: "#000000"
        }
    }

    static getColor(value, opacity = "0.2") {
        const hue = (((value + 1.0) / 2) * 120).toString(10)
        return ["hsla(", hue, ",100%,50%," + opacity + ")"].join("")
    }

    static getPriceMood(pricediff) {
        let colorhex = PPUtils.Colors.YellowStart
        if (localStorage.getItem("showPriceDifferenceThumb") === "false") {
            colorhex = PPUtils.Colors.Black
        }
        let arrow = ""
        if (pricediff > 0) {
            colorhex = PPUtils.Colors.GreenStart
            arrow = "fas fa-thumbs-up"
        } else if (pricediff < 0) {
            colorhex = PPUtils.Colors.RedStart
            arrow = "fas fa-thumbs-down"
        }
        return { color: colorhex, arrow: arrow }
    }

    static getCrawlAgo(crawlDate, i18n) {
        moment.locale(i18n.language)
        return moment(crawlDate).fromNow(true)
    }

    static getPercentDifference(price1, price2) {
        let price1f = parseFloat(price1)
        let price2f = parseFloat(price2)

        let percentage = (price2f - price1f) / Math.abs(price1f)
        let number = Math.min(1, Math.max(-1, percentage * 4))
        return Math.round(number * 1e2) / 1e2
    }

    static setSettingsLocalstorage(settings) {
        let auth = PPUtils.getLogin()
        let user = auth?.user

        let jsonArr = {}
        if (user?.configSettingsLocalstorage !== undefined && user?.configSettingsLocalstorage !== null) {
            jsonArr = JSON.parse(user?.configSettingsLocalstorage)
            if (Object.keys(jsonArr).length > 0) {
                for (const [key, value] of Object.entries(jsonArr)) {
                    if (key != "presetId") {
                        if (!localStorage.getItem(key)) {
                            localStorage.setItem(key, value)
                        }
                    }
                }
            }
        }

        //default localstorage data -> App.js settingLocalstorage
        _.each(settings, function (value) {
            _.each(value, function (value2) {
                if (!localStorage.getItem(value2.name)) {
                    localStorage.setItem(value2.name, value2.defaultValue)
                }
            })
        })
    }

    static getLogin() {
        if (localStorage.getItem("login")) {
            return JSON.parse(localStorage.getItem("login"))
        } else {
            return JSON.parse(sessionStorage.getItem("login"))
        }
    }

    static updateLogin(auth) {
        if (localStorage.getItem("login")) {
            return localStorage.setItem("login", JSON.stringify(auth))
        } else {
            return sessionStorage.setItem("login", JSON.stringify(auth))
        }
    }

    static removeLogin() {
        sessionStorage.removeItem("login")
        return localStorage.removeItem("login")
    }

    static addZeroesIfNeeded(num) {
        return num.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2 })
    }

    static getPriceDifference(price, secondPrice) {
        //return ((price - secondPrice) / price) * 100
        return ((price - secondPrice) * 100.0) / price
    }

    static getMappedColumnName(label, t, i18n, tariffComparisonMode) {
        const columnNamesMapping = {
            avgPrice: "AVG " + t("single.price"),
            minPrice: "MIN " + t("single.price"),
            maxPrice: "MAX " + t("single.price"),
            productnr: t("single.productnr"),
            extartnr: t("single.external_item_number"),
            checked: "Checked",
            producer: PPUtils.isTariffComparisonMode(tariffComparisonMode)
                ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                : t("single.manufacturer"),
            department: t("single.department"),
            productCat: t("single.product_category"),
            productGroup: t("single.product_group"),
            productName: t("single.productname"),
            ean: "EAN",
            //Rule Engine Preisvorschlag Spalten - erstmal vom Namen hartcodiert - Bei anderen Kunden mit mehreren Channels müsste man sich hier nochmal was überlegen
            PMS_Price_Onlineshop: "Preisvorschlag",
            PMS_MargeProzent_Onlineshop: "Preisvorschlag Marge",
            ranking: t("single.ranking"),
            stockCount: t("single.stock_count"),
            appointed: "Appointed",
            discontinuedItem: t("single.discontinued_item"),
            packageunit: t("single.packageunit"),
            priceEk: "EK " + t("single.price"),
            imageUrl: t("single.image")
        }

        if (!label?.toLowerCase().includes("pms_") && label?.toLowerCase().includes("price_")) {
            return (
                <>
                    <span>{t("single.price")}</span>
                    <span
                        title={countries.getName(label.split("_")[1], i18n.language)}
                        className={"ml-1 align-center fi fi-" + label.split("_")[1]}
                    />
                </>
            )
        }
        return columnNamesMapping[label] !== undefined ? columnNamesMapping[label] : label
    }

    static isBooleanColumn(column) {
        const booleanColumns = ["checked", "appointed", "discontinuedItem"]
        return booleanColumns.includes(column)
    }

    static isFixedColumn(column) {
        const fixedColumns = ["imageUrl", "productName"]
        return fixedColumns.includes(column)
    }

    static getBooleanValue(val, t) {
        return val.toString() === "false" ? t("pricetable.boolean.false") : t("pricetable.boolean.true")
    }

    static getCustomerProductURL(customer, customerProduct, priceLang='default') {
        let singleLanguageCustomer = false;
        const productUrlVar = customer?.productUrl;
        if (PPUtils.isJSON(productUrlVar)) {
            try {
                const jsonProductUrl = JSON.parse(productUrlVar)
                const url =  jsonProductUrl[priceLang] + customerProduct.productnr
                return url;
            } catch (error) {
                singleLanguageCustomer = true;
            }            
        } else {
            singleLanguageCustomer = true;
        }
        if (singleLanguageCustomer) {
            if (
                customerProduct.urls !== undefined &&
                customerProduct.urls.de !== undefined &&
                customerProduct.urls.de !== null &&
                customerProduct.urls.de !== ""
            ) {
                return customerProduct.urls.de
            } else if (customer?.productUrl !== null && customer?.productUrl !== undefined && customer?.productUrl !== "") {
                return customer?.productUrl + customerProduct.productnr
            }
        }

        return ""
    }

    static isJSON(str) {
        try {
          JSON.parse(str);
          return true;
        } catch (error) {
          return false;
        }
      }

    static activDarkmode() {
        const cookies = new Cookies()
        var darkmodeactiv = false

        if (cookies.get("ppfrontend2_darkmode") == true) {
            jquery("body").addClass("dark-mode")
            darkmodeactiv = true
        }

        return darkmodeactiv
    }

    static getLinkIcon() {
        return <i className="fas fa-link mx-auto" style={{ color: "gray" }} />
    }

    static rangeNumber(start, end) {
        return Array(end - start + 1)
            .fill()
            .map((_i, idx) => start + idx)
    }

    static checkStringInputProductNrs(input) {
        var regex = new RegExp(/[a-zA-Z0-9 #.,_-]/g)
        if (input.match(regex)) {
            return true
        }
        return false
    }

    static getDummyAvatarString(name, width, fontSize) {
        return (
            <Avatar sx={{ width: width, height: width, bgColor: "#bcc3c7", fontSize: fontSize }}>
                {name.substring(0, 1).toUpperCase()}
            </Avatar>
        )
    }

    static setGenericTranslation(auth, i18n) {
        if (auth?.login?.customer?.genericTranslation?.length > 0) {
            auth?.login?.customer?.genericTranslation?.map((translation) => {
                if (translation?.name != "" && translation?.propertyName?.indexOf(":") > 0) {
                    var t_string = translation?.propertyName.split(":")
                    i18n.addResource(t_string[0], "translation", t_string[1], translation.name)
                }
            })
        }
    }

    static getCurrentAuthFromAuth(auth) {
        return auth?.login?.customerUsers?.length > 0 &&
            auth?.login?.token_original !== undefined &&
            auth?.login?.token !== auth?.login?.token_original
            ? auth.login.customerUsers.find((customerUser) => customerUser.token === auth.login.token)
            : auth.login
    }
}

export default PPUtils
