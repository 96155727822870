import React from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"

export function PPDefaultSelector({
    id,
    name,
    data,
    placeholder,
    onChange,
    value,
    defaultValue,
    isMulti = false,
    isClearable = true,
    components = null,
    noOptionsMessage,
    customStyles,
    isDisabled = false
}) {
    const [t] = useTranslation()
    if (placeholder == "") {
        placeholder = t("select") + "..."
    }

    let classname = ""
    if (isDisabled) {
        classname += "main-css-container-isdisabled"
    }

    return (
        <Select
            id={id}
            className={classname}
            name={name}
            options={data}
            styles={customStyles}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            isMulti={isMulti}
            components={components}
            isClearable={isClearable}
            noOptionsMessage={noOptionsMessage}
            isDisabled={isDisabled}
        />
    )
}
