import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export function PPRightTabComment(props) {
    const [t] = useTranslation()

    const [mappingEditinfo, setMappingEditinfo] = useState("")
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setDisabled(false)
        setMappingEditinfo(props.comment)
    }, [props])

    return (
        <>
            <div>
                <textarea
                    id="btn_save_comment_editinfo"
                    className="form-control"
                    onChange={(e) => {
                        setMappingEditinfo(e.target.value)
                    }}
                    value={mappingEditinfo}></textarea>
            </div>
            <br />
            <button
                id="btn_save_comment"
                type="button"
                disabled={disabled}
                onClick={() => {
                    setDisabled(true)
                    props.saveCommentMappingEditinfo(mappingEditinfo)
                }}
                className="btn btn-block btn-primary">
                {t("pricetable.righttab.comment_tab.btn_save_comment")}
            </button>
        </>
    )
}
