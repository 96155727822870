import React from "react"
import "../App.css"
import { useTranslation } from "react-i18next"
import PPHotProductItem from "./PPHotProductItem"

function PPExpandedRowStatTableCountPriceLower(props) {
    const [t, i18n] = useTranslation()

    return (
        <>
            <div className="mt-1 mb-1">
                <h5>{t("dashboard.stattablecountpricelower_products.col_6")}</h5>
                <br />
                {props?.data?.mostPriceChangeHotProducts?.length > 0 &&
                    props?.data?.mostPriceChangeHotProducts.map((product) => (
                        <PPHotProductItem
                            key={product.id}
                            product={product}
                            t={t}
                            i18n={i18n}
                            tariffComparisonMode={props?.tariffComparisonMode}
                        />
                    ))}
            </div>
        </>
    )
}

export default PPExpandedRowStatTableCountPriceLower
