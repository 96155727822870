import React from "react"
import "../App.css"

function PPLoadingIndicator({ leftSide }) {
    return (
        <div className={"spinner-box d-flex m-3" + (leftSide ? " w-100" : " justify-content-center")}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default PPLoadingIndicator
