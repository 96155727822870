import React from "react"
import "../App.css"

function PPTarifView(props) {
    if (props.tarifData.name === "url" || (props.tarifData.name === "logo") | (props.tarifData.name === "marke")) {
        return ""
    }
    if (props.tarifData.value === "") {
        return ""
    }
    return (
        <li className="tarif-data">
            {props.tarifData.name} : {props.tarifData.value}
            {props.tarifData.type === "price" && <span> €</span>}
        </li>
    )
}

export default PPTarifView
