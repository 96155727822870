import React from "react"
import { useTranslation } from "react-i18next"
import PPCrawlerPrio from "../components/PPCrawlerPrio"
import { useAuth } from "../hooks/use-auth"

export default function PPCrawler() {
    const [t] = useTranslation()
    const auth = useAuth()

    if (!auth.isAdmin()) {
        return <>No Admin!</>
    }

    return (
        <>
            <div className="card shadow card-primary card-outline card-outline-tabs mb-3">
                <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                id="custom-tabs-four-crawlerprio-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-crawlerprio"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-crawlerprio"
                                aria-selected="true"
                            >
                                {t("crawler.prioritized_crawling")}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div
                            className="tab-pane fade active show"
                            id="custom-tabs-four-crawlerprio"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-crawlerprio-tab"
                        >
                            <PPCrawlerPrio />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
