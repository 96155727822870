import React, { useEffect, useState } from "react"
import "../App.css"
import GraphQL from "../utils/GraphQLClient"
import PPUtilsCatFilter from "../utils/PPUtilsCatFilter"
import { gql, useQuery } from "@apollo/client"
import { PPDefaultSelector } from "./PPDefaultSelector"
import { useTranslation } from "react-i18next"
import QUERY_PRODUCER from "../utils/const/QueryProducer"
import PPUtils from "../utils/PPUtils"
// import $ from "jquery"

const query = `query getCats {
  baseData {
    producers {
      label: name
      value: name
    }
    mainLang
    tariffComparisonMode
    categories {
      ...selectFields
      type
      children {
        ...selectFields
        type
        children {
          ...selectFields
          type
          children {
            ...selectFields
            type
          }
        }
      }
    }
  }
}
fragment selectFields on Category {
    name
    value: name
    label: name
}
`

const SETTINGS_QUERY = gql`
    query getpreset($id: Long!) {
        preset(presetId: $id) {
            name
            id
            searchDepartment
            searchGroup
            searchCategory
            searchSubcategory
            searchProducer
            searchTitle
            shops {
                order
                shop {
                    label: name
                    shortName
                    lang
                    value: id
                }
            }
            columns {
                order
                label: propertyName
                value: propertyName
            }
        }
    }
`

function PPCategorieFilter(props) {
    const [t] = useTranslation()
    const [tariffComparisonMode, setTariffComparisonMode] = useState("N")
    const [datapreset, setDatapreset] = useState([])
    const [categorieTree, setCategorieTree] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedProductGroup, setSelectedProductGroup] = useState(null)
    const [selectedProductCat, setSelectedProductCat] = useState(null)
    const [selectedProductSubCat, setSelectedProductSubCat] = useState(null)
    const [selectedProducer, setSelectedProducer] = useState(null)

    const [dataformatGroups, setDataformatGroups] = useState(null)
    const [dataformatCats, setDataformatCats] = useState(null)
    const [dataformatSubCats, setDataformatSubCats] = useState(null)
    const [dataProducer, setDataProducer] = useState(null)
    const [tmpPresetId, setTmpPresetId] = useState(0)

    useEffect(() => {
        GraphQL.getClient()
            .request(query)
            .then((data) => {
                setCategorieTree(data?.baseData?.categories)
                setDataProducer(data?.baseData?.producers)
                setTariffComparisonMode(data.baseData.tariffComparisonMode)

                let vDataformatGroups = PPUtilsCatFilter.formatGroups(data.baseData.categories, "PRODUCTGROUP")
                setDataformatGroups(vDataformatGroups)
                let vDataformatCats = PPUtilsCatFilter.formatGroups(vDataformatGroups, "PRODUCTCAT")
                setDataformatCats(vDataformatCats)
                let vDataformatSubCats = PPUtilsCatFilter.formatGroups(vDataformatCats, "PRODUCTSUBCAT")
                if (vDataformatSubCats.length > 0) {
                    setDataformatSubCats(vDataformatSubCats)
                }
            })
            .catch((error) => console.error(error))
    }, [])

    useEffect(() => {
        filterDataProducer({
            selectedDepartment: selectedDepartment,
            selectedProductGroup: selectedProductGroup,
            selectedProductCat: selectedProductCat,
            selectedProductSubCat: selectedProductSubCat
        })
    }, [selectedDepartment, selectedProductGroup, selectedProductCat, selectedProductSubCat])

    function filterDataProducer(vProps) {
        let variables = {
            department: vProps.selectedDepartment ? vProps.selectedDepartment.value : "",
            productGroup: vProps.selectedProductGroup ? vProps.selectedProductGroup.value : "",
            productCat: vProps.selectedProductCat ? vProps.selectedProductCat.value : "",
            productSubCat: vProps.selectedProductSubCat ? vProps.selectedProductSubCat.value : ""
        }
        GraphQL.getClient()
            .request(QUERY_PRODUCER, variables)
            .then((data) => {
                setDataProducer(data?.getProducerByQuery?.producerList)
                if (vProps.presetProducer && vProps.presetProducer !== "") {
                    setSelectedProducer(
                        PPUtilsCatFilter.setSelectDefaultValue(
                            vProps.presetProducer,
                            data?.getProducerByQuery?.producerList
                        )
                    )
                } else if (vProps.presetProducer !== undefined && vProps.presetProducer === "") {
                    setSelectedProducer(null)
                }
            })
            .catch((error) => console.error(error))
    }

    useQuery(SETTINGS_QUERY, {
        skip: props.presetId === null || props.presetId === undefined || props.presetId === tmpPresetId,
        variables: { id: Number(props.presetId) },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setDatapreset(data.preset)
            setTmpPresetId(props.presetId)
            let department = null
            let productGroup = null
            let productCat = null
            let productSubCat = null
            if (Object.keys(props?.filters).length > 0 && props?.filters?.department != "") {
                department = PPUtilsCatFilter.setSelectDefaultValue(props.filters.department, categorieTree)
            } else {
                department = PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchDepartment, categorieTree)
                productGroup = PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchGroup, dataformatGroups)
                productCat = PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchCategory, dataformatCats)
                productSubCat = PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchCategory, dataformatSubCats)
            }
            setSelectedDepartment(department)
            setSelectedProductGroup(productGroup)
            setSelectedProductCat(productCat)
            setSelectedProductSubCat(productSubCat)
            filterDataProducer({
                selectedDepartment: department,
                selectedProductGroup: productGroup,
                selectedProductCat: productCat,
                selectedProductSubCat: productSubCat,
                presetProducer: data.preset.searchProducer
            })

            //Für DEBUG: Klappt automatisch den Kategorie Filter aus
            // $("#collapseCatfilter").collapse("show")
        }
    })

    if (categorieTree.length === 0 || datapreset.length === 0) {
        return null
    }

    function updateParentListener() {
        props.onChangeCatFilter({
            department: selectedDepartment ? selectedDepartment.value : null,
            productgroup: selectedProductGroup ? selectedProductGroup.value : null,
            productcat: selectedProductCat ? selectedProductCat.value : null,
            productsubcat: selectedProductSubCat ? selectedProductSubCat.value : null,
            producer: selectedProducer ? selectedProducer.value : null,
            buttoncatsearch: true
        })
    }

    function formatProductGroup() {
        if (selectedDepartment === null || selectedDepartment.children === undefined) {
            return dataformatGroups
        }
        return selectedDepartment.children.length > 0 ? selectedDepartment.children : []
    }

    function formatProductCat() {
        if (
            (selectedDepartment === null && selectedProductGroup === null) ||
            selectedProductGroup?.children === undefined
        ) {
            return dataformatCats
        }
        return selectedProductGroup.children.length > 0 ? selectedProductGroup.children : []
    }

    function formatProductSubCat() {
        if (
            (selectedDepartment === null && selectedProductGroup === null && selectedProductCat === null) ||
            selectedProductCat?.children === undefined
        ) {
            return dataformatSubCats
        }
        return selectedProductCat.children.length > 0 ? selectedProductCat.children : []
    }

    return (
        <div>
            <div className="row">
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterDepartment"
                        data={categorieTree}
                        onChange={(evt) => {
                            setSelectedDepartment(evt)
                            setSelectedProductGroup(null)
                            setSelectedProductCat(null)
                            setSelectedProductSubCat(null)
                            setSelectedProducer(null)
                        }}
                        name="department"
                        placeholder={t("single.department")}
                        value={selectedDepartment}
                    />
                </div>
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterProductgroups"
                        data={formatProductGroup()}
                        onChange={(evt) => {
                            setSelectedProductGroup(evt)
                            setSelectedProductCat(null)
                            setSelectedProductSubCat(null)
                        }}
                        name="productgroups"
                        placeholder={t("single.product_group")}
                        value={selectedProductGroup}
                    />
                </div>
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterProductcats"
                        data={formatProductCat()}
                        onChange={(evt) => {
                            setSelectedProductCat(evt)
                            setSelectedProductSubCat(null)
                        }}
                        name="productcats"
                        placeholder={t("single.product_category")}
                        value={selectedProductCat}
                    />
                </div>
                {dataformatSubCats && (
                    <div className="col-2">
                        <PPDefaultSelector
                            id="selectPricetableFilterProductsubcats"
                            data={formatProductSubCat()}
                            onChange={(evt) => setSelectedProductSubCat(evt)}
                            name="productcats"
                            placeholder={t("single.product_sub_category")}
                            value={selectedProductSubCat}
                        />
                    </div>
                )}
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterProducer"
                        data={dataProducer}
                        onChange={(evt) => {
                            setSelectedProducer(evt)
                        }}
                        name="producer"
                        placeholder={
                            PPUtils.isTariffComparisonMode(tariffComparisonMode)
                                ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                                : t("single.manufacturer")
                        }
                        value={selectedProducer}
                    />
                </div>
                <div className="col-0">
                    <button
                        id="buttonPricetableFilterApplyFilter"
                        className="category-button-go btn btn-primary btn-block"
                        type="button"
                        onClick={updateParentListener}>
                        {t("single.apply_filter")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PPCategorieFilter
