import React, { useMemo } from "react"
import "../App.css"
import dummyReport1 from "../img/dummyReport1.png"
import { gql, useQuery, useSubscription } from "@apollo/client"
import { useTranslation } from "react-i18next"
import DataTable from "react-data-table-component"
import ExportSubscription from "../utils/const/ExportSub"
import PPExportTile from "../components/PPExportTile"

const LOAD_REPORTS_DATA_QUERY = gql`
    query loadReportsData($lang: String!) {
        completeExport(lang: $lang) {
            downloadUrl
        }
        getExports {
            id
            downloadLink
            name
            state
            createDate
            userId
        }
        getCustomExports {
            id
            downloadLink
            name
            description
            createDate
        }
    }
`

export default function PPReports(props) {
    const [t, i18n] = useTranslation()
    const { loading, data, refetch } = useQuery(LOAD_REPORTS_DATA_QUERY, {
        variables: { lang: "de" },
        fetchPolicy: "no-cache",
        onError: (error) => console.error(error)
    })

    useSubscription(ExportSubscription.GQL.EXPORTS_SUBSCRIPTION, {
        variables: { channel: "Exports" + props?.userId },
        onData: ({ data: { dataSub } }) => {
            if (dataSub !== undefined && dataSub.exportSub.state !== ExportSubscription.States.INPROGRESS) {
                refetch()
            }
        }
    })

    const CustomCellDownloadButton = ({ row }) => {
        let html_button
        if (row.state === ExportSubscription.States.READY) {
            html_button = (
                <a role="button" href={row.downloadLink} className="btn btn-primary reports-button">
                    {t("single.download")}
                    <i className={"far fa-file-excel reports-icon"} />
                </a>
            )
        } else if (row.state === ExportSubscription.States.INPROGRESS) {
            html_button = (
                <span>
                    <span className={"font-weight-bold"}>{t("reports.export_table.inprogress")}</span>
                    <i className={"fas fa-microchip orange reports-icon"} />
                </span>
            )
        } else if (row.state === ExportSubscription.States.FAILED) {
            html_button = (
                <span>
                    <span className={"font-weight-bold"}>{t("reports.export_table.failed")}</span>
                    <i className={"fas fa-exclamation-triangle red reports-icon"} />
                </span>
            )
        }
        return <div className="mt-2 mb-2">{html_button}</div>
    }

    const columns = useMemo(() => [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "80px"
        },
        {
            name: t("single.name"),
            selector: (row) => row.name
        },
        {
            name: t("single.create_date"),
            selector: (row) => row.createDate,
            cell: (row) => {
                return new Intl.DateTimeFormat(i18n.language, {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                }).format(new Date(row.createDate))
            }
        },
        {
            name: "",
            selector: (row) => row.id,
            cell: (row) => <CustomCellDownloadButton row={row} />,
            width: "250px"
        }
    ])

    if (loading) return null

    let boxsize_css = ''
    if(data?.getCustomExports?.length>3) {
        boxsize_css = 'small'
    }

    return (
        <div id="reports">
            <div className="content pt-3 pb-2">
                <div className="row">
                    <div className={"card shadow " + boxsize_css}>
                        <div className="card-header">
                            <h3 className="card-title">{t("reports.nightly_data_export.header")}</h3>
                        </div>
                        <div className="card-body">
                            <img
                                width={"256px"}
                                className={"mx-auto d-block"}
                                height={"256px"}
                                src={dummyReport1}
                                alt={"dummy"}
                            />
                            <p>{t("reports.nightly_data_export.text")}</p>
                        </div>

                        <div className="card-footer">
                            <a
                                role="button"
                                href={data?.completeExport?.downloadUrl}
                                className="btn btn-primary float-right reports-button"
                            >
                                Download
                                <i className={"far fa-file-excel reports-icon"} />
                            </a>
                        </div>
                    </div>

                    {data?.getCustomExports != null &&
                        data?.getCustomExports.map((obj) => (
                            <PPExportTile key={obj.id} data={obj} language={i18n.language} t={t} boxsize_css={boxsize_css} />
                        ))}
                </div>
                <div className="row">
                    <div className="card shadow d-flex flex-fill">
                        <div className="card-header">
                            <h3 className="card-title">{t("reports.export_table.header")}</h3>
                        </div>
                        <div className="datatable_headerbold">
                            <DataTable noHeader pagination={true} columns={columns} data={data?.getExports} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
