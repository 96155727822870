import React from "react"
import "../App.css"
import PPTripleBarStats from "./PPTripleBarStats"

function PPTripleBarStatsBox({ items }) {
    if (items == undefined || items.length === 0) {
        return ""
    }

    let foreignShops = items.filter((item) => item.tarifComparisonStats !== null)

    return (
        <div className="col-lg col">
            {foreignShops.map((foreignShop, indexA) => (
                <div key={indexA} className="small-box shadow pp-stats-box">
                    <div className="row">
                        {foreignShop.tarifComparisonStats.map((item, index) => (
                            <div key={index} className="col-3 triple-stats-box">
                                <PPTripleBarStats
                                    text=""
                                    caption={item.name}
                                    cheaperValue={item.valueA}
                                    cheaperUrl={item.urlA}
                                    equalValue={item.valueB}
                                    equalUrl={item.urlB}
                                    greaterValue={item.valueC}
                                    greaterUrl={item.urlC}
                                    totalCount={item.totalCount}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-column flex-fill justify-content-around align-items-center">
                        <h5 className="text-center">
                            {foreignShop.name} ({foreignShop.lang})
                        </h5>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default PPTripleBarStatsBox
