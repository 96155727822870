import React, { useState } from "react"
import "../App.css"
import ReactApexChart from "react-apexcharts"
import PPUtils from "../utils/PPUtils"
import PPPictureBoxLoad from "./PPPictureBoxLoad"

function PPHotProductItem({ product, t, i18n, tariffComparisonMode }) {
    const [config] = useState({
        options: {
            chart: {
                type: "area",
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                // curve: "straight"
            },
            grid: {
                padding: {
                    top: 10,
                    right: 50,
                    bottom: 10,
                    left: 50
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    type: "horizontal",
                    gradientToColors: ["#035492"],
                    shadeIntensity: 1,
                    opacityFrom: 0.3,
                    opacityTo: 0.7,
                    stops: [0, 90, 100]
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val.toFixed(2) + "€"
                }
            },
            xaxis: {
                type: "datetime"
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class="custom_tooltip">' +
                        '<div class="tooltip_headline">' +
                        formatUnixTimestamp(w.config.series[0].data[dataPointIndex].x) +
                        '</div><div class="tooltip_content"><p>' +
                        t("single.price") +
                        ": <strong>" +
                        series[seriesIndex][dataPointIndex] +
                        "€</strong><br>Shop: <strong>" +
                        w.config.series[0].data[dataPointIndex].shopName +
                        " (" +
                        w.config.series[0].data[dataPointIndex].lang +
                        ")</strong></p>" +
                        "</div>"
                    )
                }
            },
            colors: ["#007bff"]
        }
    })

    let vSeries = [{ name: "Preis", data: prepareSeriesDataForTopProduct() }]

    function formatUnixTimestamp(timestamp) {
        return new Intl.DateTimeFormat(i18n.language, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        }).format(new Date(timestamp))
    }

    function prepareSeriesDataForTopProduct() {
        let dataArr = []
        product.lastPriceChangesOverAllShops.forEach((e) => {
            let data = {}
            data.x = e.crawlDate
            data.y = e.price.toFixed(2)
            data.shopName = e.shopName
            data.lang = e.lang
            dataArr.push(data)
        })
        return dataArr
    }

    function makeDiagramColors(trend) {
        if (trend > 0) {
            config.options.colors = [PPUtils.Colors.GreenStart]
            config.options.fill.gradient.gradientToColors = [PPUtils.Colors.GreenEnd]
        } else if (trend < 0) {
            config.options.colors = [PPUtils.Colors.RedStart]
            config.options.fill.gradient.gradientToColors = [PPUtils.Colors.RedEnd]
        } else if (trend === 0) {
            config.options.colors = [PPUtils.Colors.NeutralStart]
            config.options.fill.gradient.gradientToColors = [PPUtils.Colors.NeutralEnd]
        }
    }

    makeDiagramColors(product.priceTrendToCustomerPrice)

    let price_difference = 0
    if (product.price > 0 && product.minPrice > 0) {
        price_difference = PPUtils.getPriceDifference(product.price, product.minPrice).toFixed(2)
    }

    return (
        <div className="row border-bottom my-1 hotProductItemBox">
            <div className="col-2 text-center hotProductItemBoxImg">
                <div className="productimgbox dashboard">
                    <a href={`/pricetable/s/${product.productnr}`} className="hotProductItemLink">
                        <PPPictureBoxLoad
                            image={product.productImage}
                            className={"img-fluid"}
                            alt={product.productName}
                        />
                    </a>
                </div>
            </div>
            <div className="col-4 hotProductItemBoxText">
                <h2 className="lead">
                    <a href={`/pricetable/s/${encodeURIComponent(product.productnr)}`} className="hotProductItemLink">
                        <b>{product.productName}</b>
                    </a>
                </h2>
                <p className="text-muted text-sm mb-1">
                    <b>
                        {PPUtils.isTariffComparisonMode(tariffComparisonMode)
                            ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                            : t("single.manufacturer")}
                        :
                    </b>{" "}
                    {product.productProducer}
                </p>
                <p className="text-muted text-sm mb-1">
                    <b>{t("single.productnr")}:</b> {product.productnr}
                </p>
                {product.price > 0 && (
                    <>
                        <p className="text-muted text-sm mb-1">
                            <b>{t("single.price")}:</b> {Number(PPUtils.addZeroesIfNeeded(product.price)).toFixed(2)} €
                        </p>
                        <p className="text-muted text-sm mb-1">
                            <b>{t("single.price_min")}</b>{" "}
                            {Number(PPUtils.addZeroesIfNeeded(product.minPrice)).toFixed(2)} €
                        </p>
                        <p className="text-muted text-sm mb-1">
                            <b>{t("single.price_difference")}</b> {product.minPrice > 0 ? price_difference + " %" : "-"}
                        </p>
                    </>
                )}
            </div>
            <div className="col-6 hotProductItemBoxChart">
                <ReactApexChart options={config.options} series={vSeries} type="area" height={125} />
            </div>
        </div>
    )
}

export default PPHotProductItem
