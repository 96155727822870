import { useTranslation } from "react-i18next"
import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import countries from "i18n-iso-countries"
import moment from "moment"
import { useAuth } from "../hooks/use-auth"

const SET_SHOP_QUERY = gql`
    mutation ($id: Long!, $active: Boolean!) {
        setShopActive(id: $id, active: $active)
    }
`

export function PPShopSettings(props) {
    const [t, i18n] = useTranslation()
    const auth = useAuth()

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Shop</th>
                                <th>Domain</th>
                                <th className="text-center">{t("settings.shops.th_should_be_active")}</th>
                                {auth.isAdmin() ? (
                                    <>
                                        <th className="text-center">
                                            {t("single.enable")}/{t("single.disable")}
                                        </th>
                                        <th>{t("single.error")}</th>
                                        <th></th>
                                    </>
                                ) : (
                                    <>
                                        <th className="text-center">{t("single.active")}</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {props?.shops?.map((shop) => (
                                <ShopRow key={shop.id} shop={shop} i18n={i18n} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

function ShopRow(props) {
    const auth = useAuth()
    const [isShopActive, setShopActive] = useState(props.shop.active)
    const [isShouldBeActive, setShouldBeActive] = useState(props.shop.shouldBeActive)
    const [setUpdateShop] = useMutation(SET_SHOP_QUERY, {})

    let opts = {}
    opts["checked"] = ""
    if (isShopActive) {
        opts["checked"] = "checked"
    }

    function ActivateOrDeactivateShop() {
        setShopActive(!isShopActive)
        let variables = { id: props.shop.id, active: !isShopActive }
        setUpdateShop({ variables: variables })
        setShouldBeActive(!isShopActive)
    }

    return (
        <tr>
            <td>{props.shop.id}.</td>
            <td>
                <span
                    title={countries.getName(props.shop.lang, props.i18n.language)}
                    className={"fi fi-" + props.shop.lang}
                    alt={props.shop.lang}
                />{" "}
                <span>{props.shop.name}</span>
            </td>
            <td>{props.shop.domain}</td>
            <td className="text-center">
                {" "}
                <i
                    className={
                        "fa " + (props.shop.deactivationReason ? "fa-exclamation-circle fa-2x" : "fa-circle fa-2x")
                    }
                    style={{ color: isShouldBeActive ? "green" : "red" }}
                />
            </td>
            {auth.isAdmin() ? (
                <>
                    <td className="text-center">
                        <div className="col-auto">
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={props.shop.id}
                                    {...opts}
                                    onChange={() => ActivateOrDeactivateShop()}
                                />
                                <label className="custom-control-label" htmlFor={props.shop.id}></label>
                            </div>
                        </div>
                    </td>
                    <td>
                        {props.shop.deactivationTime
                            ? moment(props.shop.deactivationTime).format("MMMM Do YYYY, HH:mm")
                            : ""}
                    </td>
                    <td>{props.shop.deactivationReason}</td>
                </>
            ) : (
                <>
                    <td className="text-center">
                        <i className={"fa fa-circle fa-2x"} style={{ color: isShopActive ? "green" : "red" }} />
                    </td>
                </>
            )}
        </tr>
    )
}
