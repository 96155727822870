import React, { useState } from "react"
import "../App.css"
import ReactApexChart from "react-apexcharts"
import _ from "lodash"
import PPLoadingIndicator from "../components/PPLoadingIndicator"
import Collapse from "react-bootstrap/Collapse"
import countries from "i18n-iso-countries"
import i18n_iso_countrie_de from "i18n-iso-countries/langs/de.json"
import { MultiValueLabel, Option } from "../components/PPShopPreset"
import { PPDefaultSelector } from "../components/PPDefaultSelector"
import "moment/locale/de"
import { gql, useQuery } from "@apollo/client"
import PPDonutStatsBox from "../components/PPDonutStatsBox"
import PPHotProducts from "../components/PPHotProducts"
import PPTopProducts from "../components/PPTopProducts"
import PPStatTableCountPriceLower from "../components/PPStatTableCountPriceLower"
import PPUtils from "../utils/PPUtils"
import { useTranslation } from "react-i18next"
import PPUtilsCatFilter from "../utils/PPUtilsCatFilter"
import md5 from "md5-hash"
import PPTripleBarStatsBox from "../components/PPTripleBarStatsBox"

countries.registerLocale(i18n_iso_countrie_de)

const QUERY = gql`
    query dashboardData($department: String, $id: Long!) {
        stats(department: $department) {
            shopStats {
                name
                lang
                countPriceChangesLastTwoWeeks
                tarifComparisonStats {
                    name
                    shopName
                    valueA
                    urlA
                    valueB
                    urlB
                    valueC
                    urlC
                    totalCount
                }
            }
            competitorWithMostPriceChanges
            competitorsWithLowestPrice
            competitorWithLowestPrices
            competitorsMappingHistory
            activeShops
            crawlsToday
            productsPriceHigher
            productsPriceEqual
            productsPriceLower
            productsPriceHigherByCategory {
                name
                count
                totalCount
                percentage
            }
            productsPriceLowerByCategory {
                name
                count
                totalCount
                percentage
            }
            productsPriceEqualByCategory {
                name
                count
                totalCount
                percentage
            }
            productsPriceHigherByProducer {
                name
                count
                totalCount
                percentage
            }
            productsPriceLowerByProducer {
                name
                count
                totalCount
                percentage
            }
            productsPriceEqualByProducer {
                name
                count
                totalCount
                percentage
            }
            productsPriceHigherByShops {
                id
                name
                lang
                count
                totalCount
                percentage
            }
            productsPriceLowerByShops {
                id
                name
                lang
                count
                totalCount
                percentage
            }
            productsPriceEqualByShops {
                id
                name
                lang
                count
                totalCount
                percentage
            }
            crawlerCountSeries
            shopsProductAllCounts {
                name
                lang
                countProducts
            }
            shopsProductMappedCounts {
                name
                lang
                countMappedProducts
            }
            lastUpdate
            filterDepartment
        }
        baseData {
            mainLang
            tariffComparisonMode
            categories {
                ...selectFields
                type
            }
        }
        preset(presetId: $id) {
            name
            id
            searchDepartment
        }
        settings {
            presets {
                id
                name
                userId
                shops {
                    shop {
                        lang
                        name
                        value: id
                    }
                }
            }
        }
    }
    fragment selectFields on Category {
        name
        value: name
        label: name
    }
`

const queryActiveShopInfos = gql`
    query activeShopInfos {
        shops(onlyActive: true) {
            id
            name
            lang
            domain
        }
    }
`

const state = {
    optionsLineChart: {
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 5,
            curve: "smooth",
            dashArray: 0
        },
        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ""
            }
        },
        markers: {
            size: 4,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: getDatesFromLastTwoWeeks()
        },
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            ]
        },
        grid: {
            borderColor: "#f1f1f1"
        },
        theme: {
            mode: "light",
            palette: "palette2",
            monochrome: {
                enabled: false,
                color: "#255aee",
                shadeTo: "light",
                shadeIntensity: 0.65
            }
        }
    }
}

function getDatesFromLastTwoWeeks() {
    let start = Date.now()
    let days = 14
    let dates = []
    for (let i = 0; i < days; i++) dates.push(new Date(start - i * 1000 * 60 * 60 * 24).toLocaleDateString("de-DE"))
    dates.reverse()
    return dates
}

function prepareMappingHistoryData(series, entry, shops) {
    if (
        shops &&
        shops.length > 0 &&
        shops.map((x) => x.value).includes(entry.name + "." + entry.lang) &&
        entry.mappingCounts !== null &&
        entry.mappingCounts !== undefined
    ) {
        series.push({
            name: entry.name + "." + entry.lang,
            data: entry.mappingCounts.replace("[", "").replace("]", "").split`,`.map((x) => +x)
        })
    }
}

function ShopFilterMappingHistoryChart(props) {
    const [t] = useTranslation()

    const selectorCustomStyle = {
        control: (styles) => ({
            ...styles,
            minWidth: "15em",
            background: "white"
        })
    }

    const maxShops = 4

    const [shopFilter, setShopFilter] = useState(
        _.uniqBy(
            props.data?.settings?.presets[0]?.shops.map((x) => ({
                label: x.shop.name + "." + x.shop.lang,
                value: x.shop.name + "." + x.shop.lang,
                name: x.shop.name,
                lang: x.shop.lang
            })),
            (e) => ({ value: e.value, lang: e.lang })
        )
    )

    let shops = _.uniqBy(
        props.data?.stats?.shopStats?.map((x) => ({
            label: x.name + "." + x.lang,
            value: x.name + "." + x.lang,
            name: x.name,
            lang: x.lang
        })),
        (e) => ({ value: e.value, lang: e.lang })
    )

    if (shopFilter?.length > maxShops) {
        shopFilter.splice(maxShops, shopFilter.length - maxShops)
    }

    let series = []
    if (props?.data?.stats?.competitorsMappingHistory !== null) {
        props.data.stats.competitorsMappingHistory.forEach((entry) =>
            prepareMappingHistoryData(series, entry, shopFilter)
        )
        series.forEach((entry) => entry.data.reverse())
    }

    return (
        <>
            <div className={"row "}>
                <div className={"col "}>
                    <h4 className={"color-dark-grey"}>
                        <i className="fas fa-chart-pie mr-1" />
                        {props.title}
                    </h4>
                </div>
                <div className={"col"}>
                    <PPDefaultSelector
                        id="selectDashboardShopFilterMappingHistoryChart"
                        isMulti={true}
                        onChange={setShopFilter}
                        data={shopFilter?.length === maxShops ? [] : shops}
                        defaultValue={shopFilter}
                        components={{ MultiValueLabel, Option }}
                        noOptionsMessage={() => {
                            return shopFilter?.length === maxShops && shopFilter?.length < shops.length
                                ? t("dashboard.filter_limit")
                                : t("dashboard.filter_nooption")
                        }}
                        customStyles={selectorCustomStyle}
                    />
                </div>
                <div className={"w-100"}></div>
                <div className={"col chartDashboardMappingHistoryChart"}>
                    <ReactApexChart options={state.optionsLineChart} series={series} type="line" height={220} />
                </div>
            </div>
        </>
    )
}

function PPDashboard() {
    const [t] = useTranslation()

    const [shopStatsOpen, setShopStatsOpen] = useState(false)
    const [lowerPricesOpen, setLowerPricesOpen] = useState(false)
    const [equalPricesOpen, setEqualPricesOpen] = useState(false)
    const [higherPricesOpen, setHigherPricesOpen] = useState(false)
    const [competitorStatsMostPriceChangesOpen, setCompetitorStatsMostPriceChangesOpen] = useState(false)
    const [competitorStatsLowestPriceOpen, setCompetitorStatsLowestPriceOpen] = useState(false)
    const [querydata, setQuerydata] = useState()
    const [querydataAll, setQuerydataAll] = useState()
    const [querydataShopInfos, setQuerydataShopInfos] = useState()
    const [categorieTree, setCategorieTree] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [tariffComparisonMode, setTariffComparisonMode] = useState("N")

    const TAB_NAME_KATEGORIE = t("single.category")
    const TAB_NAME_HERSTELLER = PPUtils.isTariffComparisonMode(tariffComparisonMode)
        ? t("overwrite.tariffcomparisonmode.single.manufacturer")
        : t("single.manufacturer")
    const TAB_NAME_SHOP = "Shop"

    let variables = {
        id: Number(localStorage.getItem("presetId")),
        department: selectedDepartment ? selectedDepartment.value : ""
    }

    useQuery(QUERY, {
        displayName: "DashboardQuery",
        fetchPolicy: "no-cache",
        variables: variables,
        onCompleted: (data) => {
            setTariffComparisonMode(data.baseData.tariffComparisonMode)

            setQuerydata(data)
            data?.baseData?.categories.unshift(PPUtilsCatFilter.getEmptyObject())
            setCategorieTree(data?.baseData?.categories)
            if (selectedDepartment == null) {
                setSelectedDepartment(
                    PPUtilsCatFilter.setSelectDefaultValue(data.preset.searchDepartment, data.baseData.categories)
                )
            }

            //reset
            if (selectedDepartment == null || selectedDepartment.value == "") {
                setQuerydataAll([])
            } else {
                setQuerydataAll(querydata)
            }
        },
        onError: (error) => console.error(error)
    })

    useQuery(queryActiveShopInfos, {
        displayName: "DashboardQuery2",
        fetchPolicy: "no-cache",
        onCompleted: (data2) => setQuerydataShopInfos(data2),
        onError: (errorShopInfos) => console.error(errorShopInfos)
    })

    function showMoreClick(clickedComponent) {
        if (clickedComponent === "activeShops") {
            setShopStatsOpen(!shopStatsOpen)
            setLowerPricesOpen(false)
            setHigherPricesOpen(false)
            setEqualPricesOpen(false)
            setCompetitorStatsMostPriceChangesOpen(false)
            setCompetitorStatsLowestPriceOpen(false)
        } else if (clickedComponent === "lowerPrices") {
            setLowerPricesOpen(!lowerPricesOpen)
            setHigherPricesOpen(false)
            setEqualPricesOpen(false)
            setShopStatsOpen(false)
            setCompetitorStatsMostPriceChangesOpen(false)
            setCompetitorStatsLowestPriceOpen(false)
        } else if (clickedComponent === "equalsPrices") {
            setEqualPricesOpen(!equalPricesOpen)
            setLowerPricesOpen(false)
            setHigherPricesOpen(false)
            setShopStatsOpen(false)
            setCompetitorStatsMostPriceChangesOpen(false)
            setCompetitorStatsLowestPriceOpen(false)
        } else if (clickedComponent === "higherPrices") {
            setHigherPricesOpen(!higherPricesOpen)
            setLowerPricesOpen(false)
            setEqualPricesOpen(false)
            setShopStatsOpen(false)
            setCompetitorStatsMostPriceChangesOpen(false)
            setCompetitorStatsLowestPriceOpen(false)
        } else if (clickedComponent === "topCompetitorPriceChanges") {
            setCompetitorStatsMostPriceChangesOpen(!competitorStatsMostPriceChangesOpen)
            setHigherPricesOpen(false)
            setLowerPricesOpen(false)
            setEqualPricesOpen(false)
            setShopStatsOpen(false)
            setCompetitorStatsLowestPriceOpen(false)
        } else if (clickedComponent === "topCompetitorLowestPrice") {
            setCompetitorStatsLowestPriceOpen(!competitorStatsLowestPriceOpen)
            setHigherPricesOpen(false)
            setLowerPricesOpen(false)
            setEqualPricesOpen(false)
            setShopStatsOpen(false)
            setCompetitorStatsMostPriceChangesOpen(false)
        }
    }

    if (querydata === undefined) {
        return <PPLoadingIndicator />
    }

    return (
        <div>
            {querydata?.stats?.filterDepartment && (
                <div className="mb-3">
                    <PPDefaultSelector
                        id="selectStatsFilterDepartment"
                        data={categorieTree}
                        onChange={(evt) => {
                            setSelectedDepartment(evt)
                        }}
                        name="department"
                        placeholder={t("single.department")}
                        value={selectedDepartment}
                        isClearable={false}
                    />
                </div>
            )}
            <div className="row">
                <PPTripleBarStatsBox items={querydata?.stats?.shopStats} />
            </div>
            <div className="row">
                <PPDonutStatsBox
                    onOpenClick={() => showMoreClick("lowerPrices")}
                    value={querydata?.stats?.productsPriceLower?.toFixed(2)}
                    valueAll={querydataAll?.stats?.productsPriceLower?.toFixed(2)}
                    text={t("dashboard.PPDonutStatsBox_lowerPrices")}
                    startColor={PPUtils.Colors.GreenStart}
                    endColor={PPUtils.Colors.GreenEnd}
                    showMore={lowerPricesOpen}
                />
                <PPDonutStatsBox
                    onOpenClick={() => showMoreClick("equalsPrices")}
                    value={querydata?.stats?.productsPriceEqual?.toFixed(2)}
                    valueAll={querydataAll?.stats?.productsPriceEqual?.toFixed(2)}
                    text={t("dashboard.PPDonutStatsBox_equalsPrices")}
                    startColor={PPUtils.Colors.YellowStart}
                    endColor={PPUtils.Colors.YellowEnd}
                    showMore={equalPricesOpen}
                />
                <PPDonutStatsBox
                    onOpenClick={() => showMoreClick("higherPrices")}
                    value={querydata?.stats?.productsPriceHigher?.toFixed(2)}
                    valueAll={querydataAll?.stats?.productsPriceHigher?.toFixed(2)}
                    text={t("dashboard.PPDonutStatsBox_higherPrices")}
                    startColor={PPUtils.Colors.RedStart}
                    endColor={PPUtils.Colors.RedEnd}
                    showMore={higherPricesOpen}
                />
            </div>

            <Collapse in={lowerPricesOpen || equalPricesOpen || higherPricesOpen}>
                <div className="card shadow card-primary card-outline card-outline-tabs">
                    <div className="card-header p-0 border-bottom-0">
                        <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="custom-tabs-four-price1-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-four-price1"
                                    role="tab"
                                    onClick={() => window.dispatchEvent(new Event("resize"))}
                                    aria-controls="custom-tabs-four-stat1"
                                    aria-selected="true">
                                    {t("single.categories")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-four-price2-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-four-price2"
                                    role="tab"
                                    onClick={() => window.dispatchEvent(new Event("resize"))}
                                    aria-controls="custom-tabs-four-stat2"
                                    aria-selected="false">
                                    {PPUtils.isTariffComparisonMode(tariffComparisonMode)
                                        ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                                        : t("single.manufacturer")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-four-price3-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-four-price3"
                                    role="tab"
                                    onClick={() => window.dispatchEvent(new Event("resize"))}
                                    aria-controls="custom-tabs-four-stat3"
                                    aria-selected="false">
                                    Shops
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content" id="custom-tabs-four-tabContent">
                            <div
                                className="tab-pane fade active show"
                                id="custom-tabs-four-price1"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-four-price1-tab">
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceLowerByCategory}
                                    collapseBoxId={"productsPriceLower-show-more"}
                                    open={lowerPricesOpen}
                                    sortProperty={"foreignShopPriceLower"}
                                    tabName={TAB_NAME_KATEGORIE}
                                    searchName={"Kategorie"}
                                />
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceEqualByCategory}
                                    collapseBoxId={"productsPriceEqual-show-more"}
                                    open={equalPricesOpen}
                                    sortProperty={"foreignShopPriceEqual"}
                                    tabName={TAB_NAME_KATEGORIE}
                                    searchName={"Kategorie"}
                                />
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceHigherByCategory}
                                    collapseBoxId={"productsPriceHigher-show-more"}
                                    open={higherPricesOpen}
                                    sortProperty={"foreignShopPriceHigher"}
                                    tabName={TAB_NAME_KATEGORIE}
                                    searchName={"Kategorie"}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-four-price2"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-four-price2-tab">
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceLowerByProducer}
                                    collapseBoxId={"productsPriceLower-show-more"}
                                    open={lowerPricesOpen}
                                    sortProperty={"foreignShopPriceLower"}
                                    tabName={TAB_NAME_HERSTELLER}
                                    searchName={"Hersteller"}
                                    tariffComparisonMode={tariffComparisonMode}
                                />
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceEqualByProducer}
                                    collapseBoxId={"productsPriceEqual-show-more"}
                                    open={equalPricesOpen}
                                    sortProperty={"foreignShopPriceEqual"}
                                    tabName={TAB_NAME_HERSTELLER}
                                    searchName={"Hersteller"}
                                    tariffComparisonMode={tariffComparisonMode}
                                />
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceHigherByProducer}
                                    collapseBoxId={"productsPriceHigher-show-more"}
                                    open={higherPricesOpen}
                                    sortProperty={"foreignShopPriceHigher"}
                                    tabName={TAB_NAME_HERSTELLER}
                                    searchName={"Hersteller"}
                                    tariffComparisonMode={tariffComparisonMode}
                                />
                            </div>
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-four-price3"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-four-price3-tab">
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceLowerByShops}
                                    collapseBoxId={"productsPriceLower-show-more"}
                                    open={lowerPricesOpen}
                                    sortProperty={"foreignShopPriceLower"}
                                    tabName={TAB_NAME_SHOP}
                                    searchName={"Shop"}
                                />
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceEqualByShops}
                                    collapseBoxId={"productsPriceEqual-show-more"}
                                    open={equalPricesOpen}
                                    sortProperty={"foreignShopPriceEqual"}
                                    tabName={TAB_NAME_SHOP}
                                    searchName={"Shop"}
                                />
                                <DetailStatsPrices
                                    items={querydata?.stats?.productsPriceHigherByShops}
                                    collapseBoxId={"productsPriceHigher-show-more"}
                                    open={higherPricesOpen}
                                    sortProperty={"foreignShopPriceHigher"}
                                    tabName={TAB_NAME_SHOP}
                                    searchName={"Shop"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className="row">
                <PPTopProducts
                    changeFilterDepartment={selectedDepartment}
                    tariffComparisonMode={tariffComparisonMode}
                />
            </div>

            <div className="row">
                <PPHotProducts
                    changeFilterDepartment={selectedDepartment}
                    tariffComparisonMode={tariffComparisonMode}
                />
            </div>

            <div className="row">
                <PPStatTableCountPriceLower
                    changeFilterDepartment={selectedDepartment}
                    tariffComparisonMode={tariffComparisonMode}
                />
            </div>

            <div className="row">
                <div className="col-lg col">
                    <PPStatsBox
                        value={querydata?.stats?.competitorWithMostPriceChanges
                            ?.replaceAll('"', "")
                            .replaceAll("\\", "")}
                        text={t("dashboard.PPStatsBox_topCompetitorPriceChanges")}
                        cssClass={"pp-stats-box"}
                        className={"topCompetitorPriceChanges"}
                        icon={"fa-clipboard"}
                        collapseBoxId={"topCompetitorPriceChanges-show-more"}
                        onChildClick={showMoreClick}
                        showMore={!competitorStatsMostPriceChangesOpen}
                    />
                </div>
                <div className="col-lg col">
                    <PPStatsBox
                        value={querydata?.stats?.competitorWithLowestPrices?.replaceAll('"', "").replaceAll("\\", "")}
                        text={t("dashboard.PPStatsBox_topCompetitorLowestPrice")}
                        cssClass={"pp-stats-box"}
                        className={"topCompetitorLowestPrice"}
                        icon={"fa-chart-line"}
                        collapseBoxId={"topCompetitorLowestPrice-show-more"}
                        onChildClick={showMoreClick}
                        showMore={!competitorStatsLowestPriceOpen}
                    />
                </div>
            </div>
            {querydata && (
                <DetailStatsCompetitorMostPriceChanges
                    items={querydata?.stats?.shopStats}
                    collapseBoxId={"topCompetitorPriceChanges-show-more"}
                    open={competitorStatsMostPriceChangesOpen}
                />
            )}
            {querydata && (
                <DetailStatsCompetitorLowestPrice
                    items={querydata?.stats?.competitorsWithLowestPrice}
                    collapseBoxId={"topCompetitorLowestPrice-show-more"}
                    open={competitorStatsLowestPriceOpen}
                />
            )}
            <div className="row">
                <div className="col-lg col">
                    <PPStatsBox
                        // value={querydata?.stats?.activeShops}
                        value={querydataShopInfos?.shops?.length}
                        text={t("dashboard.PPStatsBox_activeShops")}
                        cssClass={"pp-stats-box"}
                        className={"activeShops"}
                        icon={"fa-eye"}
                        collapseBoxId={"activeShops-show-more"}
                        onChildClick={showMoreClick}
                        showMore={!shopStatsOpen}
                    />
                </div>
                <div className="col-lg col">
                    <PPStatsBox
                        value={querydata?.stats?.crawlsToday}
                        text={t("dashboard.PPStatsBox_dailyCrawls")}
                        cssClass={"pp-stats-box"}
                        className={"dailyCrawls"}
                        icon={"fa-fish"}
                        collapseBoxId={"crawlsToday-show-more"}
                        onChildClick={showMoreClick}
                        showMore={false}
                        hasNoShowMore={true}
                    />
                </div>
            </div>
            {querydataShopInfos && (
                <DetailStatsShops
                    items={querydataShopInfos?.shops}
                    collapseBoxId={"activeShops-show-more"}
                    open={shopStatsOpen}
                />
            )}
            <div className="row">
                <div className="col ">
                    <div className="card shadow">
                        <div className="card-body">
                            {querydata && (
                                <ShopFilterMappingHistoryChart title={t("dashboard.mapping_histoy")} data={querydata} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PPStatsBox(props) {
    const [t] = useTranslation()

    const icon = props.icon ? <i className={"fas " + props.icon + " dashboard-icon"} /> : ""

    function showMoreClick() {
        props.onChildClick(props.className)
    }

    return (
        <div className={"small-box shadow " + props.cssClass}>
            <div className="inner min-height">
                <h3>{props.value}</h3>
                <div
                    className={"text-and-icon"}
                    style={{
                        display: "flex",
                        gridTemplateColumns: "auto 1fr",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <p>{props.text}</p>
                    <p>{icon}</p>
                </div>
            </div>
            {props.hasNoShowMore ? (
                <a
                    style={{
                        cursor: "auto",
                        borderRadius: "0px 0px 3px 3px",
                        color: "grey"
                    }}
                    className={"small-box-footer "}>
                    &nbsp;
                </a>
            ) : (
                <a
                    style={{
                        cursor: "pointer",
                        borderRadius: "0px 0px 3px 3px",
                        color: "grey"
                    }}
                    onClick={() => showMoreClick()}
                    className={"small-box-footer "}
                    aria-expanded="false"
                    aria-controls={"collapse" + props.collapseBoxId}>
                    {props.showMore ? t("single.more") : t("single.less")}{" "}
                    <i className={props.showMore ? "fas fa-arrow-circle-right" : "fas fa-arrow-circle-up"} />
                </a>
            )}
        </div>
    )
}

function DetailStatsPricesRow(props) {
    let linkId = props.searchName === "Shop" ? props.obj.id : props.categoryName

    return (
        <tr>
            <td>{props.rowNumber}.</td>
            <td>
                <a href={`/pricetable/${linkId}/${props.searchName}/${props.sortProperty}`}>{props.categoryName}</a>
            </td>
            {props.searchName === "Shop" && (
                <td>
                    <span
                        title={countries.getName(props.lang, props.i18n.language)}
                        className={"fi fi-" + props.lang}
                    />
                </td>
            )}
            <td>{props.totalCount}</td>
            <td>{props.count}</td>
            <td>
                <div className="progress progress-xs">
                    <div
                        className="progress-bar progress-bar-danger"
                        style={{ width: Number(props.percent.toFixed(2)) + "%" }}
                    />
                </div>
            </td>
            <td>{Number(props.percent.toFixed(2))}%</td>
        </tr>
    )
}

function DetailStatsPrices(props) {
    const [t, i18n] = useTranslation()

    if (props.items === null) {
        return t("errors.no_correct_server_data")
    }

    let rows = Array.from(props.items).map((obj, index) => (
        <DetailStatsPricesRow
            key={index + "_" + md5(JSON.stringify(obj))}
            rowNumber={index + 1}
            categoryName={obj.name}
            totalCount={obj.totalCount}
            count={obj.count}
            percent={obj.percentage}
            lang={obj.lang}
            tabName={props.tabName}
            searchName={props.searchName}
            sortProperty={props.sortProperty}
            obj={obj}
            i18n={i18n}
        />
    ))

    return (
        <Collapse in={props.open}>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive" overflow="auto">
                        <table className="table table-striped dt-responsive">
                            <thead>
                                <tr>
                                    <th className={"width-10"}>#</th>
                                    <th>{props.tabName}</th>
                                    {props.tabName === "Shop" && <th>{t("single.language")}</th>}
                                    <th>{t("single.total_count")}</th>
                                    <th>{t("single.count")}</th>
                                    <th>{t("single.proportion")}</th>
                                    <th style={{ width: "40px" }}></th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

function DetailStatsShops(props) {
    const [t, i18n] = useTranslation()

    if (props.items === null) {
        return t("errors.no_correct_server_data")
    }

    let rows = Array.from(props.items).map((obj) => (
        <DetailStatsShopsRow key={obj.id} id={obj.id} name={obj.name} lang={obj.lang} domain={obj.domain} i18n={i18n} />
    ))

    return (
        <Collapse in={props.open}>
            <div className="card shadow">
                <div className="card-body p-0">
                    <div className="col" style={{ marginTop: 0.5 + "rem" }}>
                        <a role="button" href="./settings" className="btn btn-outline-primary nav-link">
                            {t("dashboard.show_all_shops")}
                        </a>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className={"width-10"}>#</th>
                                    <th>{t("single.name")}</th>
                                    <th>{t("single.language")}</th>
                                    <th>Domain</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

function DetailStatsCompetitorMostPriceChanges(props) {
    const [t, i18n] = useTranslation()

    if (props.items === null) {
        return t("errors.no_correct_server_data")
    }

    let rows = Array.from(props.items)
        .sort((a, b) => a.countPriceChangesLastTwoWeeks - b.countPriceChangesLastTwoWeeks)
        .reverse()
        .map((obj, index) => (
            <DetailStatsCompetitorMostPriceChangesRow
                key={obj.name + obj.lang}
                rowNumber={index + 1}
                competitor={obj.name}
                priceChanges={obj.countPriceChangesLastTwoWeeks}
                lang={obj.lang}
                i18n={i18n}
            />
        ))

    return (
        <Collapse in={props.open}>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className={"width-10"}>#</th>
                                    <th>{t("single.name")}</th>
                                    <th>{t("single.language")}</th>
                                    <th>{t("dashboard.price_changes")}</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

function DetailStatsCompetitorMostPriceChangesRow(props) {
    return (
        <tr>
            <td>{props.rowNumber}.</td>
            <td>{props.competitor}</td>
            <td>
                <span title={countries.getName(props.lang, props.i18n.language)} className={"fi fi-" + props.lang} />
            </td>
            <td>{props.priceChanges}</td>
        </tr>
    )
}

function DetailStatsCompetitorLowestPrice(props) {
    const [t, i18n] = useTranslation()

    if (props.items === null) {
        return t("errors.no_correct_server_data")
    }

    let rows = Array.from(props.items)
        .sort((a, b) => a.count - b.count)
        .reverse()
        .map((obj, index) => (
            <DetailStatsCompetitorLowestPriceRow
                key={obj.name + obj.lang}
                rowNumber={index + 1}
                competitor={obj.name}
                lowestPriceCount={obj.count}
                lang={obj.lang}
                i18n={i18n}
            />
        ))

    return (
        <Collapse in={props.open}>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className={"width-10"}>#</th>
                                    <th>{t("single.name")}</th>
                                    <th>{t("single.language")}</th>
                                    <th>{t("dashboard.lowest_price")}</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}

function DetailStatsCompetitorLowestPriceRow(props) {
    return (
        <tr>
            <td>{props.rowNumber}.</td>
            <td>{props.competitor}</td>
            <td>
                <span title={countries.getName(props.lang, props.i18n.language)} className={"fi fi-" + props.lang} />
            </td>
            <td>{props.lowestPriceCount}</td>
        </tr>
    )
}

function DetailStatsShopsRow(props) {
    return (
        <tr>
            <td>{props.id}.</td>
            <td>{props.name}</td>
            <td>
                <span title={countries.getName(props.lang, props.i18n.language)} className={"fi fi-" + props.lang} />
            </td>
            <td>{props.domain}</td>
        </tr>
    )
}

export default PPDashboard
