const QUERY_PRODUCER = `query getProducer($department: String, $productGroup: String, $productCat: String, $productSubCat: String) {
  getProducerByQuery(
    department: $department
    productGroup: $productGroup
    productCat: $productCat
    productSubCat: $productSubCat
  ) {
    producerList {
        label: name
        value: name
    }
  }
}
`

export default QUERY_PRODUCER
