import { useTranslation } from "react-i18next"
import React, { useMemo, useRef, useState } from "react"
import DataTable from "react-data-table-component"
import { PPDefaultSelector } from "./PPDefaultSelector"
import { gql, useMutation } from "@apollo/client"
import jquery from "jquery"
import { toast } from "react-toastify"
import PPExpandedRowUserAdministration from "./PPExpandedRowUserAdministration"
import PPUtils from "../utils/PPUtils"

const rights = [
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" }
]

const CREATE_USER_QUERY = gql`
    mutation ($user: UserInput!) {
        createUser(user: $user) {
            email
        }
    }
`
const UPDATE_USER_QUERY = gql`
    mutation ($user: UserInput!) {
        UpdateUser(user: $user) {
            email
        }
    }
`

export function PPUserAdministration(props) {
    const [t, i18n] = useTranslation()
    let auth = PPUtils.getLogin()
    let customer_activ = false
    let customers = []
    if (auth?.user?.rights == "admin" && auth.customerUsers?.length >= 1 && props?.customers?.length > 0) {
        customer_activ = true
        customers = props?.customers.reduce(function (previous, current) {
            return previous.concat({ value: current.id, label: current.name })
        }, [])
    }

    const [buttonCreateDisabled, setButtonCreateDisabled] = useState(true)

    const [languages] = useState(
        Object.keys(i18n.services.resourceStore.data).map((x) => ({
            value: x,
            label: t("app.langs." + x)
        }))
    )

    const [rightsRef, setRights] = useState(rights.find((e) => e.value == "user"))
    const usernameRef = useRef()
    const passwordRef = useRef()
    const nameRef = useRef()
    const emailRef = useRef()
    const [languageRef, setLanguage] = useState(languages.find((e) => e.value == "de"))

    const columns = useMemo(() => [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "60px"
        },
        {
            name: t("single.rights"),
            cell: (row) => {
                return (
                    <>
                        <PPDefaultSelector
                            name="rights"
                            data={rights}
                            defaultValue={rights.find((e) => e.value == row.rights)}
                            isClearable={false}
                        />
                    </>
                )
            }
        },
        {
            name: t("single.username"),
            cell: (row) => {
                return (
                    <>
                        <input type="text" className="form-control" name="username" defaultValue={row.username} />
                    </>
                )
            }
        },
        {
            name: t("single.password"),
            cell: () => {
                return (
                    <>
                        <input type="text" className="form-control" name="password" />
                    </>
                )
            }
        },
        {
            name: t("single.name"),
            cell: (row) => {
                return (
                    <>
                        <input type="text" className="form-control" name="name" defaultValue={row.name} />
                    </>
                )
            }
        },
        {
            name: "E-Mail",
            cell: (row) => {
                return (
                    <>
                        <input type="text" className="form-control" name="email" defaultValue={row.email} />
                    </>
                )
            }
        },
        {
            name: t("single.language"),
            cell: (row) => {
                return (
                    <>
                        <PPDefaultSelector
                            name="configLanguage"
                            data={languages}
                            defaultValue={languages.find((e) => e.value == row.configLanguage)}
                            isClearable={false}
                        />
                    </>
                )
            }
        },
        {
            name: t("single.hidden"),
            width: "60px",
            cell: (row) => {
                return (
                    <>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                id={"custom-control-input-hidden-row-" + row.id}
                                className="custom-control-input"
                                name="hidden"
                                defaultChecked={row.hidden}
                                onChange={(e) => {
                                    row.hidden = e.target.checked
                                }}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"custom-control-input-hidden-row-" + row.id}></label>
                        </div>
                    </>
                )
            }
        },
        {
            name: t("single.ruleEngineAccess"),
            width: "60px",
            cell: (row) => {
                return (
                    <>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                id={"custom-control-input-reaccess-row-" + row.id}
                                className="custom-control-input"
                                name="ruleEngineAccess"
                                defaultChecked={row.ruleEngineAccess}
                                onChange={(e) => {
                                    row.ruleEngineAccess = e.target.checked
                                }}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={"custom-control-input-reaccess-row-" + row.id}></label>
                        </div>
                    </>
                )
            }
        },
        {
            name: "",
            selector: (row) => row.id,
            cell: (row) => {
                return (
                    <>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                if (jquery("div#row-" + row.id).next("div.rdt_ExpanderRow").length == 0) {
                                    jquery("div#row-" + row.id + " div:first-child button").trigger("click")
                                }
                                setTimeout(function () {
                                    submitUpdateUser(row)
                                }, 300)
                            }}
                            type="button">
                            {t("single.save")}
                        </button>
                    </>
                )
            }
        }
    ])

    const [createUser] = useMutation(CREATE_USER_QUERY, {
        onCompleted: (data2) => {
            props.refetchData()
            toast(t("settings.user.toast_save_ok", { name: data2.createUser?.username }))
        },
        onError: (error2) => errorToast(error2)
    })
    const [updateUser] = useMutation(UPDATE_USER_QUERY, {
        onCompleted: (data2) => {
            props.refetchData()
            toast(t("settings.user.toast_save_ok", { name: data2.UpdateUser?.username }))
        },
        onError: (error2) => errorToast(error2)
    })

    function errorToast(error2) {
        if (error2.message.toString().length > 0) {
            toast.error(t("settings.user.toast_error", { msg: error2.message.toString(), autoClose: 10000 }))
        }
    }

    const submitCreateUser = () => {
        let userUpdateData = {
            variables: {
                user: {
                    hidden: document.getElementById("custom-control-input-hidden").checked,
                    ruleEngineAccess: document.getElementById("custom-control-input-reaccess").checked,
                    rights: rightsRef.value,
                    username: usernameRef.current.value,
                    password: passwordRef.current.value,
                    name: nameRef.current.value,
                    email: emailRef.current.value,
                    configLanguage: languageRef.value
                }
            }
        }
        createUser(userUpdateData)
    }

    const submitUpdateUser = (row) => {
        let fields = jquery("div#row-" + row.id)
            .find("select, textarea, input")
            .serializeArray()
        let updateData = {}

        jquery(fields).each(function (_index, obj) {
            if (obj.name == "password" && obj.value == "") {
                return
            }
            updateData[obj.name] = obj.value
        })

        updateData["id"] = row.id
        updateData["hidden"] = row.hidden
        updateData["customerId"] = row.customerId
        updateData["avatarUrl"] = row.avatarUrl
        updateData["ruleEngineAccess"] = row.ruleEngineAccess

        // Customer & Shops
        if (customer_activ) {
            let fields_shops = jquery("div.custumerbox-" + row.id + " ul#useradministrationshopslist-" + row.id)
                .find("input")
                .serializeArray()
            let configShopsActivated = []
            jquery(fields_shops).each(function (_index, obj) {
                configShopsActivated.push(obj.value)
            })
            updateData["configShopsActivated"] = configShopsActivated.join(",")
            updateData["customerId"] = jquery("div.custumerbox-" + row.id + " input[name=selectcustomer]").val()
        }

        let userUpdateData = {
            variables: {
                user: updateData
            }
        }

        updateUser(userUpdateData)
    }

    function createButtonDisable() {
        if (
            usernameRef?.current?.value?.length > 0 &&
            passwordRef?.current?.value?.length > 0 &&
            emailRef?.current?.value?.length > 0 &&
            nameRef?.current?.value?.length > 0
        ) {
            setButtonCreateDisabled(false)
        } else {
            setButtonCreateDisabled(true)
        }
    }

    return (
        <div>
            <div className="row mt-12">
                <div className="col-3">
                    <label>{t("single.rights")}</label>
                    <PPDefaultSelector
                        id="selectAdminUserSettingsRights"
                        data={rights}
                        isClearable={false}
                        onChange={(evt) => {
                            setRights(evt)
                        }}
                        defaultValue={rightsRef}
                    />
                </div>
                <div className="col-3">
                    <label>{t("single.username")}</label>
                    <input
                        id="inputAdminUserSettingsUsername"
                        type="text"
                        className="form-control"
                        ref={usernameRef}
                        autoComplete="off"
                        onChange={() => {
                            createButtonDisable()
                        }}
                    />
                </div>
                <div className="col-3">
                    <label>{t("single.password")}</label>
                    <input
                        id="inputAdminUserSettingsPassword"
                        type="text"
                        className="form-control"
                        ref={passwordRef}
                        autoComplete="off"
                        onChange={() => {
                            createButtonDisable()
                        }}
                    />
                </div>
                <div className="col-1">
                    <label>{t("single.hidden")}</label>
                    <div className="custom-control custom-switch">
                        <input
                            type="checkbox"
                            id={"custom-control-input-hidden"}
                            className="custom-control-input"
                            name="hidden"
                            defaultChecked={0}
                        />
                        <label className="custom-control-label" htmlFor={"custom-control-input-hidden"}></label>
                    </div>
                </div>
                <div className="col-1">
                    <label>{t("single.ruleEngineAccess")}</label>
                    <div className="custom-control custom-switch">
                        <input
                            type="checkbox"
                            id={"custom-control-input-reaccess"}
                            className="custom-control-input"
                            name="reaccess"
                            defaultChecked={0}
                        />
                        <label className="custom-control-label" htmlFor={"custom-control-input-reaccess"}></label>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-3">
                    <label>{t("single.name")}</label>
                    <input
                        id="inputAdminUserSettingsName"
                        type="text"
                        className="form-control"
                        ref={nameRef}
                        autoComplete="off"
                        onChange={() => {
                            createButtonDisable()
                        }}
                    />
                </div>
                <div className="col-3">
                    <label>E-Mail</label>
                    <input
                        id="inputAdminUserSettingsEmail"
                        type="text"
                        className="form-control"
                        ref={emailRef}
                        autoComplete="off"
                        onChange={() => {
                            createButtonDisable()
                        }}
                    />
                </div>
                <div className="col-3">
                    <label>{t("single.language")}</label>
                    <PPDefaultSelector
                        id="selectAdminUserSettingsLanguage"
                        data={languages}
                        isClearable={false}
                        onChange={(evt) => {
                            setLanguage(evt)
                        }}
                        defaultValue={languageRef}
                    />
                </div>
                <div className="col-3">
                    <label className="btn-block">&nbsp;</label>
                    <button
                        id="buttonAdminUserSettingsCreateUser"
                        className="category-button-go btn btn-primary btn-block"
                        type="button"
                        onClick={submitCreateUser}
                        disabled={buttonCreateDisabled}>
                        {t("single.create")}
                    </button>
                </div>
            </div>
            <div className="mt-3 datatable_headerbold datatable_specialpadding">
                {customer_activ ? (
                    <DataTable
                        noHeader
                        columns={columns}
                        data={props.users}
                        expandableRows={true}
                        // expandableRowExpanded={(evt) => {
                        //     return true
                        // }}
                        expandableRowsComponent={PPExpandedRowUserAdministration}
                        expandableRowsComponentProps={{ shops: props?.shops, customers: customers }}
                    />
                ) : (
                    <DataTable noHeader columns={columns} data={props.users} />
                )}
            </div>
        </div>
    )
}

//
