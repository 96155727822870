import React, { useEffect, useLayoutEffect, useState } from "react"
import "../App.css"
import DataTable from "react-data-table-component"
import PPTableCellPrice from "../components/PPTableCellPrice"
import PPPictureBoxLoad from "../components/PPPictureBoxLoad"
import PPLoadingIndicator from "../components/PPLoadingIndicator"
import PPExpandedRow from "../components/PPExpandedRow"
import "admin-lte/plugins/flag-icons/css/flag-icons.min.css"
import PPCategorieFilter from "../components/PPCategorieFilter"
import PPShopFilter from "../components/PPShopFilter"
import PPPriceFilter from "../components/PPPriceFilter"
import { PPPresetSelector } from "../components/PPPresetSelector"
import $ from "jquery"
import { gql, useQuery, useMutation, useSubscription } from "@apollo/client"
import INSTANT_CRAWL_SUBSCRIPTION from "../utils/const/InstantCrawlSub"
import * as PropTypes from "prop-types"

import i18n_iso_countrie_de from "i18n-iso-countries/langs/de.json"
import i18n_iso_countrie_en from "i18n-iso-countries/langs/en.json"
import countries from "i18n-iso-countries"
import PPErrorDisplay from "../components/PPErrorDisplay"
import { useParams } from "react-router-dom"
import PPUtils from "../utils/PPUtils"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import MappingState from "../utils/const/MappingState"
import PPUploadImport from "../components/PPUploadImport"

countries.registerLocale(i18n_iso_countrie_de)
countries.registerLocale(i18n_iso_countrie_en)

const ref = React.createRef()

const customStyles = {
    rows: {
        style: {
            // minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
        }
    },
    cells: {
        style: {
            // paddingLeft: '0px', // override the cell padding for data cells
            // paddingRight: '0px',
        }
    }
}

const QUERY_MAINLANG = gql`
    query baseData {
        baseData {
            mainLang
            tariffComparisonMode
        }
    }
`

const QUERY = gql`
    query getPrices(
        $searchword: String
        $perpage: Int!
        $page: Int!
        $sortDirection: SortMode
        $propertyName: String
        $department: [String]
        $productGroup: [String]
        $productCat: [String]
        $productSubCat: [String]
        $producer: [String]
        $mappingState: MAPPING_STATE
        $buttoncatsearch: Boolean
        $sortShopId: Long
        $pricetableShopId: Long
        $presetId: Long
        $currencyLang: String
        $sortLang: String
        $sortRanking: Boolean
        $discontinuedItems: Boolean
        $sortMinPrice: Float!
        $sortMaxPrice: Float!
        $genericFilter: Boolean
    ) {
        shops(onlyActive: true) {
            name
            shortName
            id
            lang
            domain
        }
        settings {
            presets {
                label: name
                value: id
            }
        }
        pricetable(
            search: $searchword
            perPage: $perpage
            page: $page
            sorting: {
                mode: $sortDirection
                shopId: $sortShopId
                propertyName: $propertyName
                currencyLang: $currencyLang
                lang: $sortLang
                ranking: $sortRanking
                minPrice: $sortMinPrice
                maxPrice: $sortMaxPrice
                genericFilter: $genericFilter
            }
            department: $department
            productGroup: $productGroup
            productCat: $productCat
            productSubCat: $productSubCat
            producer: $producer
            mappingState: $mappingState
            buttoncatsearch: $buttoncatsearch
            shopId: $pricetableShopId
            presetId: $presetId
            discontinuedItems: $discontinuedItems
        ) {
            exportDate
            importFeedDate
            maxId
            totalProducts
            currencyIsoCode
            preset {
                name
                id
                columns {
                    order
                    propertyName
                    frontendv2format
                }
                shops {
                    order
                    shop {
                        name
                        shortName
                        id
                        lang
                        domain
                    }
                }
            }
            productPrices {
                id
                ean
                productnr: productNr
                ranking
                stockCount
                department
                productGroup
                productCat
                productName
                producer
                pricesV2 {
                    value
                    symbol
                    lang
                    stockLight
                }
                secondPricesV2 {
                    value
                    symbol
                    lang
                }
                imageUrl
                urls
                extartnr
                avgPrice
                avgTotalPrice
                avgCountDealers
                minPrice
                minTotalPrice
                maxPrice
                maxTotalPrice
                checked
                appointed
                discontinuedItem: discontinuedItems
                packageunit
                priceEk
                minPriceMerchantName
                minTotalPriceMerchantName
                maxPriceMerchantName
                maxTotalPriceMerchantName
                genericColumns
                mappingEditinfo
                foreignShopsProductInformations {
                    shopname
                    shopId
                    productnr
                    productName
                    producer
                    price
                    priceByTotalPriceProvider
                    totalPrice
                    totalPriceByTotalPriceProvider
                    secondPrice
                    secondTotalPrice
                    stock
                    lang
                    crawlDate
                    currency
                    currencySymbol
                    secondCurrencySymbol
                    url
                    imageUrl
                    additionalData
                    cheapestMerchantName
                    cheapestMerchantNameTotalPrice
                    parsingFlag
                    priceDifference
                    totalPriceDifference
                    productInfoFrontend
                    merchantPrices {
                        name
                        price
                        totalPrice
                        deliveryPrice
                        stock
                        url
                        tarif {
                            displayPrice
                            tarifInfoMap
                        }
                    }
                    isManualMatch
                    isCustomerSelf
                }
            }
        }
    }
`

const PRICETABLE_EXPORT_QUERY = gql`
    mutation startPricetableExport(
        $searchword: String
        $sortDirection: SortMode
        $propertyName: String
        $department: [String]
        $productGroup: [String]
        $productCat: [String]
        $productSubCat: [String]
        $producer: [String]
        $mappingState: MAPPING_STATE
        $buttoncatsearch: Boolean
        $sortShopId: Long
        $pricetableShopId: Long
        $presetId: Long
        $currencyLang: String
        $sortLang: String
        $sortRanking: Boolean
        $sortMinPrice: Float!
        $sortMaxPrice: Float!
        $genericFilter: Boolean
    ) {
        startPricetableExport(
            search: $searchword
            sorting: {
                mode: $sortDirection
                shopId: $sortShopId
                propertyName: $propertyName
                currencyLang: $currencyLang
                lang: $sortLang
                ranking: $sortRanking
                minPrice: $sortMinPrice
                maxPrice: $sortMaxPrice
                genericFilter: $genericFilter
            }
            department: $department
            productGroup: $productGroup
            productCat: $productCat
            productSubCat: $productSubCat
            producer: $producer
            mappingState: $mappingState
            buttoncatsearch: $buttoncatsearch
            shopId: $pricetableShopId
            presetId: $presetId
        ) {
            responseCode
            responseMessage
            downloadUrl
        }
    }
`

function PPPriceTableHeader(props) {
    const [t] = useTranslation()

    return (
        <div className="container-fluid mb-2">
            <div className="row d-flex">
                <div className="col-8 d-flex justify-content-start align-items-start">
                    <button
                        id="buttonPricetableFilterCollapseCatfilter"
                        className="btn btn-outline-primary mx-1"
                        type="button"
                        onClick={() => {
                            if (!$("body").hasClass("filtercat_open")) {
                                $("body").addClass("filtercat_open")
                            } else {
                                $("body").removeClass("filtercat_open")
                            }
                        }}
                        data-toggle="collapse"
                        data-target="#collapseCatfilter"
                        aria-expanded="false"
                        aria-controls="collapseCatfilter">
                        <i className="fas fa-filter" /> {t("single.category_filter")}
                    </button>
                    <button
                        id="buttonPricetableFilterCollapseShopfilter"
                        className="btn btn-outline-primary mx-1"
                        type="button"
                        onClick={() => {
                            if (!$("body").hasClass("filtershop_open")) {
                                $("body").addClass("filtershop_open")
                            } else {
                                $("body").removeClass("filtershop_open")
                            }
                        }}
                        data-toggle="collapse"
                        data-target="#collapseShopFilter"
                        aria-expanded="false"
                        aria-controls="collapseShopFilter">
                        <i className="fas fa-filter" /> {t("shop_filter.title")}
                    </button>
                    <button
                        id="buttonPricetableFilterCollapsePricefilter"
                        className="btn btn-outline-primary mx-1"
                        type="button"
                        onClick={() => {
                            if (!$("body").hasClass("filterprice_open")) {
                                $("body").addClass("filterprice_open")
                            } else {
                                $("body").removeClass("filterprice_open")
                            }
                        }}
                        data-toggle="collapse"
                        data-target="#collapsePriceFilter"
                        aria-expanded="false"
                        aria-controls="collapsePriceFilter">
                        <i className="fas fa-filter" /> {t("price_filter.title")}
                    </button>
                    <button
                        id="buttonPricetableExort"
                        className="btn btn-outline-primary mx-1"
                        type="button"
                        aria-expanded="false"
                        aria-controls="exportPricetable"
                        data-toggle="modal"
                        data-target="#createPricetableExportDialog"
                        disabled={props.countProducts == 0}>
                        <i className="fas fa-file-excel" /> {t("single.export")}
                    </button>
                </div>

                <div className="col-3 d-flex justify-content-end align-items-center">
                    <div className="pricetable-filter-row-font">Preset</div>
                    <PPPresetSelector
                        presetId={props.preset?.id}
                        id="selectPricetablePresetSelector"
                        changePresetId={props.changePresetId}
                        presets={props.presets}
                    />
                </div>
                <div className="col-1 d-flex justify-content-start align-items-center">
                    <PPCellStyleSlider />
                </div>
            </div>
            <div className="flex-grow-1 collapse m-1 mt-2" id="collapseCatfilter">
                <div>
                    <h5>{t("single.category_filter")}</h5>
                    <PPCategorieFilter
                        onChangeCatFilter={props.onChangeCatFilter}
                        presetId={props.preset?.id}
                        filters={props.filters}
                    />
                </div>
            </div>
            <div className="flex-grow-1 collapse m-1 mt-2" id="collapseShopFilter">
                <div>
                    <h5>{t("shop_filter.title")}</h5>
                    <PPShopFilter preset={props.preset} handleSort={props.handleSort} />
                </div>
            </div>
            <div className="flex-grow-1 collapse m-1 mt-2" id="collapsePriceFilter">
                <div>
                    <h5>{t("price_filter.title")}</h5>
                    <PPPriceFilter handleSort={props.handleSort} />
                </div>
            </div>
            <div
                className="modal fade"
                id="createPricetableExportDialog"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="createPricetableExportDialogLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createPricetableExportDialogLabel">
                                Export
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {t("pricetable.export_dialog.question", { countProducts: props.countProducts })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("pricetable.export_dialog.cancel")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    props.triggerPricetableExport()
                                }}>
                                {t("pricetable.export_dialog.create")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PPPriceTableHeader.propTypes = {
    element: PropTypes.func,
    presetId: PropTypes.any,
    changePresetId: PropTypes.func,
    data: PropTypes.any,
    handleToggleShop: PropTypes.func,
    onChangeCatFilter: PropTypes.func
}

function PPPriceTable(props) {
    const [t, i18n] = useTranslation()
    const [perPage, setPerPage] = useState(25)
    const [filters, setFilters] = useState({})
    const [columns, setColumns] = useState([])
    const [mainLang, setMainLang] = useState(null)
    const [tariffComparisonMode, setTariffComparisonMode] = useState("N")

    const [page, setPage] = useState(1)
    const [sortDirection, setSortDirection] = useState(null)
    const [sortColumnName, setSortColumnName] = useState(null)
    const [currencyLang, setCurrencyLang] = useState(
        localStorage.getItem("switchCurrency") === "true" ? "anythingElse" : "de"
    )
    const [tmpPresetId, setTmpPresetId] = useState(null)

    let presetShopIds = {}

    let { filter, where, search, sortProperty, genericFilterProperty } = useParams()
    let currencyIsoCode = "EUR"

    if (genericFilterProperty?.length > 0) {
        filters.genericFilterProperty = genericFilterProperty
    } else if (search?.length > 0) {
        search = decodeURIComponent(search)
        props.setSearchword(search)
    }

    let variables = {
        perpage: perPage,
        page: page,
        sortDirection: sortDirection ? sortDirection.toUpperCase() : null,
        sortLang: null,
        propertyName: filters.genericFilterProperty
            ? filters.genericFilterProperty
            : sortColumnName
            ? sortColumnName.propertyName
            : sortProperty && !window.location.pathname.includes("/s/")
            ? sortProperty
            : null,
        genericFilter: filters.genericFilterProperty ? true : false,
        searchword: props.searchword ? props.searchword : search ? search : null,

        presetId: props.presetId,

        department: filters.department !== "" ? filters.department : null,
        productGroup: filters.productgroup !== "" ? filters.productgroup : null,
        productCat: filters.productcat !== "" ? filters.productcat : null,
        productSubCat: filters.productsubcat !== "" ? filters.productsubcat : null,
        producer: filters.producer !== "" ? filters.producer : null,

        mappingState:
            sortProperty && !window.location.pathname.includes("/s/")
                ? MappingState.MAPPED
                : localStorage.getItem("mappingState"),
        buttoncatsearch: filters.buttoncatsearch ? filters.buttoncatsearch : false,

        pricetableShopId: filters.shopId,
        sortShopId: filters.shopId !== null && filters.shopId !== undefined ? filters.shopId : null,
        sortRanking: localStorage.getItem("sortRanking") === "true" ? true : false,
        discontinuedItems: localStorage.getItem("switchDiscontinuedItems") === "true" ? true : false,
        sortMinPrice: -1,
        sortMaxPrice: -1,
        currencyLang: currencyLang
    }

    const [triggerPricetableExport] = useMutation(PRICETABLE_EXPORT_QUERY, {
        variables: variables,
        fetchPolicy: "no-cache",
        onCompleted: () => {
            toast(t("export.started"))
        },
        onError: (error2) => {
            if (error2.message.toString().length > 0) {
                toast.error(t("export.start_failed"))
            }
        }
    })

    useQuery(QUERY_MAINLANG, {
        skip: shouldSkip(),
        displayName: "GetMainLangQuery",
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setMainLang(data.baseData.mainLang)
            setTariffComparisonMode(data.baseData.tariffComparisonMode)
            setCurrencyLang(localStorage.getItem("switchCurrency") === "true" ? "anythingElse" : data.baseData.mainLang)
        }
    })

    if (sortColumnName && sortColumnName.shopId) {
        variables.sortShopId = sortColumnName.shopId
        variables.propertyName = sortColumnName.propertyName?.includes("foreignShop")
            ? sortColumnName.propertyName
            : "foreignShopPrice"
    } else if (sortColumnName && (sortColumnName.minPrice || sortColumnName.maxPrice)) {
        variables.sortMinPrice = sortColumnName.minPrice != undefined ? sortColumnName.minPrice : -1
        variables.sortMaxPrice = sortColumnName.maxPrice != undefined ? sortColumnName.maxPrice : -1
        variables.sortLang = mainLang != null ? mainLang : "de"
    } else if (variables?.propertyName?.includes("price_")) {
        variables.sortLang = variables.propertyName.split("_")[1]
        variables.propertyName = "price"
    }

    function shouldSkip() {
        if (!props.presetId) {
            return true
        }
        return false
    }

    const { loading, error, data, refetch } = useQuery(QUERY, {
        skip: shouldSkip(),
        displayName: "PricetableQuery",
        variables: variables,
        fetchPolicy: "no-cache",
        onCompleted: () => {
            if (tmpPresetId !== props.presetId) {
                setTmpPresetId(props.presetId)
            }
            props.setRefetchPricetable({ ...{ refetch } })
        }
    })

    useEffect(() => {
        if (!window.location.pathname.includes("/s/")) {
            switch (where) {
                case "Shop":
                    filters.shopId = filter
                    break
                case "Kategorie":
                    filters.department = filter
                    break
                case "Hersteller":
                    filters.producer = filter
                    break
                default:
                    break
            }
        }
        setFilters(filters)
    }, [filter, variables, where])

    useEffect(() => {
        if (props.presetId !== tmpPresetId) {
            setFilters({})
        }
    }, [props.presetId])

    useLayoutEffect(() => {
        const createShopColumns = (shops) => {
            if (filters.shopId) {
                shops = []
                shops.push(data?.shops?.find((shop) => shop.id === Number(filters.shopId)))
            }
            return shops.map((shop) => ({
                name: (
                    <>
                        <span title={shop.name} className="d-inline-block text-truncate">
                            {localStorage.getItem("showLongShopname") === "false"
                                ? shop.shortName
                                    ? shop.shortName
                                    : shop.name
                                : shop.name}
                        </span>
                        <span
                            title={countries.getName(shop.lang, i18n.language)}
                            className={"mx-1 align-top fi fi-" + shop.lang}
                        />
                    </>
                ),
                shopId: shop.id,
                propertyName: shop.name,
                center: true,
                sortable: true,
                maxWidth: "132px",
                omit: false,
                compact: true,
                cell: (row) => (
                    <PPTableCellPrice
                        foreignShop={row.foreignShopsProductInformations.find(function (item) {
                            return item.shopname === shop.name && item.lang === shop.lang
                        })}
                        shopName={shop.name + " " + shop.lang.toUpperCase()}
                        shopId={shop.id}
                        shopDomain={shop.domain}
                        shopLang={shop.lang.toLowerCase()}
                        row={row}
                        changeSelectedCell={props.changeSelectedCell}
                        changeSelectedRow={props.changeSelectedRow}
                        presetShopIds={presetShopIds}
                        currencyIsoCode={currencyIsoCode}
                        tariffComparisonMode={tariffComparisonMode}
                    />
                )
            }))
        }

        function formatGenericColumns(columnName, value) {
            switch (columnName) {
                case "TopOffer":
                    value = PPUtils.getBooleanValue(value, t)
                    break
            }
            return value
        }

        const createBaseColums = (vColumns) => {
            if (filters.shopId) {
                let shop = data?.shops?.find((vShop) => vShop.id === Number(filters.shopId))
                let alreayContainsPriceColumn = vColumns?.filter(
                    (column) => column.propertyName == "price_" + shop?.lang
                )
                if (
                    shop !== null &&
                    shop !== undefined &&
                    shop.lang !== "de" &&
                    alreayContainsPriceColumn.length == 0
                ) {
                    vColumns?.push({ order: vColumns.length, propertyName: "price_" + shop?.lang })
                }
            }

            return vColumns.map((column) => {
                if (
                    column.propertyName.toLowerCase().includes("price") ||
                    ["formatprice", "formatnormal"].includes(column.frontendv2format?.toLowerCase()) ||
                    ["Versandkosten", "UVP"].includes(column.propertyName) ||
                    PPUtils.isBooleanColumn(column.propertyName)
                ) {
                    return {
                        name: PPUtils.getMappedColumnName(column.propertyName, t, i18n, tariffComparisonMode),
                        propertyName: column.propertyName,
                        center: true,
                        sortable: true,
                        maxWidth: "132px",
                        fixed: true,
                        cell: (row) => (
                            <PPTableCellPrice
                                row={row}
                                columnName={column.propertyName}
                                columnFormat={column.frontendv2format}
                                changeSelectedCell={props.changeSelectedCell}
                                changeSelectedRow={props.changeSelectedRow}
                                presetShopIds={presetShopIds}
                                currencyIsoCode={currencyIsoCode}
                                tariffComparisonMode={tariffComparisonMode}
                            />
                        )
                    }
                } else if (column.propertyName === "imageUrl") {
                    return {
                        name: PPUtils.getMappedColumnName(column.propertyName, t, i18n, tariffComparisonMode),
                        center: true,
                        sortable: false,
                        maxWidth: "132px",
                        cell: (row) => <PPPictureBoxLoad image={row?.imageUrl} className={"picturebox shadow"} />
                    }
                } else {
                    return {
                        name: PPUtils.getMappedColumnName(column.propertyName, t, i18n, tariffComparisonMode),
                        propertyName: column.propertyName,
                        center: true,
                        sortable: true,
                        wrap: true,
                        maxWidth: "132px",
                        cell: (row) =>
                            row.genericColumns !== undefined && column.propertyName in row.genericColumns
                                ? formatGenericColumns(column.propertyName, row.genericColumns[column.propertyName])
                                : row[column.propertyName]?.length > 41
                                ? row[column.propertyName]?.replace(/^(.{40}[^\s]*).*/, "$1") + " ..."
                                : row[column.propertyName]
                    }
                }
            })
        }

        if (data && data.pricetable.preset) {
            presetShopIds = data?.pricetable?.preset?.shops?.map((e) => e.shop.id)
            if (data?.pricetable?.currencyIsoCode !== undefined) {
                currencyIsoCode = data?.pricetable?.currencyIsoCode
            }
            setColumns(
                createBaseColums(data.pricetable.preset.columns).concat(
                    createShopColumns(data.pricetable.preset.shops.map((x) => x.shop))
                )
            )
        } else if (data && data.shops) {
            if (data?.pricetable?.currencyIsoCode !== undefined) {
                currencyIsoCode = data?.pricetable?.currencyIsoCode
            }
            setColumns(createShopColumns(data.shops))
        }
    }, [data, props.changeSelectedCell, props.changeSelectedRow])

    useEffect(() => {
        props.productCountChange(data?.pricetable?.totalProducts)
        if (
            !window.location.href.includes("pricetable/") &&
            (props.presetId === null || props.presetId === undefined)
        ) {
            props.changePresetId(data?.pricetable?.preset?.id)
        }
    }, [data, props, props.productCountChange])

    const handleSort = (vColumn, vSortDirection) => {
        setSortColumnName(vColumn)
        setSortDirection(vSortDirection)
    }

    useSubscription(INSTANT_CRAWL_SUBSCRIPTION, {
        variables: { channel: "InstantCrawls" + props?.userId },
        onData: ({ data: { vData } }) => {
            if (vData !== undefined && vData.instantCrawlSub.responseCode === 200) {
                refetch()
            }
        }
    })

    if (error) return <PPErrorDisplay error={error} />

    $("body").addClass("pricetable_datatable_fixedheader_activ")
    if ($("div#collapseCatfilter").hasClass("show")) {
        $("body").addClass("filtercat_open")
    }
    if ($("div#collapseShopFilter").hasClass("show")) {
        $("body").addClass("filtershop_open")
    }
    if ($("div#collapsePriceFilter").hasClass("show")) {
        $("body").addClass("filterprice_open")
    }

    let pageTotal = Math.ceil(data?.pricetable.totalProducts / perPage)

    return (
        <div id="pricetable_maindiv" className="row mb-2" ref={ref} style={{ maxHeight: "100%" }}>
            <div className="col-12">
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <PPPriceTableHeader
                                            presetId={data?.pricetable?.preset.id}
                                            preset={data?.pricetable?.preset}
                                            changePresetId={props.changePresetId}
                                            presets={data?.settings?.presets}
                                            refetchData={refetch}
                                            onChangeCatFilter={(cats) => setFilters(cats)}
                                            triggerPricetableExport={triggerPricetableExport}
                                            countProducts={data?.pricetable.totalProducts}
                                            handleSort={handleSort}
                                            filters={filters}
                                        />
                                        <div className={"datatable-wrapper"}>
                                            <DataTable
                                                noHeader
                                                fixedHeader={true}
                                                columns={columns}
                                                data={data?.pricetable?.productPrices}
                                                customStyles={customStyles}
                                                striped={true}
                                                progressPending={loading}
                                                progressComponent={<PPLoadingIndicator leftSide={true} />}
                                                onSort={handleSort}
                                                sortServer
                                                pagination
                                                paginationServer
                                                paginationPerPage={perPage}
                                                paginationDefaultPage={page}
                                                paginationRowsPerPageOptions={[25, 50]}
                                                onChangePage={(vPage) => setPage(vPage)}
                                                onChangeRowsPerPage={(vPerPage) => setPerPage(vPerPage)}
                                                paginationTotalRows={data?.pricetable.totalProducts}
                                                persistTableHead
                                                expandableRows
                                                expandableRowsComponent={PPExpandedRow}
                                                noDataComponent={
                                                    <>
                                                        <div className={"nodata"}>
                                                            {t("pricetable.datatable.nodata")}
                                                            <br />
                                                            <br />
                                                            {sortDirection != null && sortColumnName != null ? (
                                                                <>
                                                                    <button
                                                                        id="buttonResetSorting"
                                                                        className="btn btn-outline-primary mx-1"
                                                                        type="button"
                                                                        onClick={() => handleSort(null, null)}>
                                                                        <i className="fas fa-sort" />{" "}
                                                                        {t("single.reset")}
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        {data?.pricetable?.importFeedDate && (
                                            <div className={"datatable_importfeeddate"}>
                                                <div className={"text"}>
                                                    {t("single.data_import_feed")}:{" "}
                                                    {new Intl.DateTimeFormat(i18n.language, {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "2-digit",
                                                        hour: "2-digit",
                                                        minute: "2-digit"
                                                    }).format(new Date(data?.pricetable?.importFeedDate))}
                                                </div>
                                                <PPUploadImport />
                                            </div>
                                        )}

                                        {pageTotal > 1 && (
                                            <div className={"datatableChangePagesDirect"}>
                                                <input
                                                    id="inputDatatableChangePagesDirect"
                                                    name="inputDatatableChangePagesDirect"
                                                    type="text"
                                                    defaultValue={page}
                                                    onKeyUp={(e) => {
                                                        if (
                                                            e.key === "Enter" &&
                                                            (1 >= parseInt(e.target.value) ||
                                                                parseInt(e.target.value) <= pageTotal)
                                                        ) {
                                                            setPage(parseInt(e.target.value))
                                                        }
                                                    }}
                                                />
                                                /{pageTotal}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

PPPriceTable.whyDidYouRender = false

function PPCellStyleSlider() {
    function configureCell(value) {
        if (value < 70) {
            $(".stockbox, .pricecell-subsubline").hide()
            $(".pricetext").css("margin-bottom", 0)
        } else {
            $(".stockbox, .pricecell-subsubline").show()
            $(".pricetext").css("margin-bottom", "0.45rem")
        }

        if (value < 80) {
            $(".subline").hide()
        } else {
            $(".subline").show()
        }
    }

    function getPriceCellHeight() {
        if(localStorage.getItem("pricebox-height")>0) {
            setPriceboxHeight(localStorage.getItem("pricebox-height"))
        }
        return parseInt(getComputedStyle(document.body).getPropertyValue("--pricebox-height"))
    }

    useLayoutEffect(() => {
        configureCell(getPriceCellHeight())
    })

    function setPriceboxHeight(value) {
        document.documentElement.style.setProperty("--pricebox-height", parseInt(value) + "px")
        configureCell(value)
    }

    return (
        <input
            type="range"
            className="custom-range"
            list="tickmarks"
            defaultValue={getPriceCellHeight()}
            min={50}
            max={100}
            id="customRange1"
            onChange={(event) => {
                let value = event.target.value
                localStorage.setItem("pricebox-height", value)
                setPriceboxHeight(value)
            }}
        />
    )
}

export default PPPriceTable
