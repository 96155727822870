import React, { useEffect, useState, useRef } from "react"
import "../App.css"
import { useTranslation } from "react-i18next"
import CurrencyInput from "react-currency-input-field"
import { PPDefaultSelector } from "./PPDefaultSelector"

let options = [
    {
        locale: "de-DE",
        currency: "EUR"
    },
    {
        //Auch EUR um die englische Schreibweise mit . beizubehalten und Symbol €
        locale: "en-US",
        currency: "EUR"
    },
    {
        locale: "en-GB",
        currency: "GBP"
    },
    {
        locale: "ja-JP",
        currency: "JPY"
    },
    {
        locale: "en-IN",
        currency: "INR"
    }
]

function PPPriceFilter(props) {
    const [t] = useTranslation()
    let addFilterBtnRef = useRef()

    const [intlConfig, setIntlConfig] = useState(options[1])
    const [valueMin, setValueMin] = useState("")
    const [valueMax, setValueMax] = useState("")
    const [rawValueMin, setRawValueMin] = useState(0)
    const [rawValueMax, setRawValueMax] = useState(-1)
    const [selectedFilterOption, setSelectedFilterOption] = useState(null)

    const handleOnValueChangeMin = (value) => {
        setRawValueMin(value === undefined ? 0 : value || 0)
        setValueMin(value)
    }

    const handleOnValueChangeMax = (value) => {
        setRawValueMax(value === undefined ? -1 : value || -1)
        setValueMax(value)
    }

    //TODO: Wenn das default Language Ticket durch ist,
    //      muss hier die Currency der gespeicherten default price_<lang> Spalte genommmen werden
    // eslint-disable-next-line
    const _handleCurrency = (event) => {
        const config = options[Number(event.target.value)]
        if (config) {
            setIntlConfig(config)
        }
    }

    function filterPricetableData() {
        if (rawValueMin != undefined || rawValueMax != undefined) {
            let sortObject = { minPrice: rawValueMin, maxPrice: rawValueMax, propertyName: "priceRange" }
            let sortOption = selectedFilterOption
            props.handleSort(sortObject, sortOption)
        } else {
            props.handleSort(null, null)
        }
    }

    function prepareFilterOptionOptions() {
        return [
            {
                value: "ASC",
                label: t("single.asc")
            },
            {
                value: "DESC",
                label: t("single.desc")
            }
        ]
    }

    useEffect(() => {
        if (rawValueMax != -1 && Math.abs(parseFloat(rawValueMax)) < Math.abs(parseFloat(rawValueMin))) {
            addFilterBtnRef.current.setAttribute("disabled", true)
            addFilterBtnRef.current.setAttribute("title", "Min > Max!")
        } else {
            addFilterBtnRef.current.removeAttribute("disabled")
            addFilterBtnRef.current.removeAttribute("title")
        }
    }, [rawValueMax, rawValueMin])

    return (
        <div>
            <div className="row">
                <div className="col-1">
                    <CurrencyInput
                        id="validationCustom01"
                        name="input-1"
                        intlConfig={intlConfig}
                        className={`form-control`}
                        onValueChange={handleOnValueChangeMin}
                        decimalsLimit={6}
                        allowNegativeValue={false}
                        value={valueMin}
                        step={1}
                        placeholder={"Min"}
                    />
                </div>
                <div className="col-1">
                    <CurrencyInput
                        id="validationCustom02"
                        name="input-2"
                        intlConfig={intlConfig}
                        className={`form-control`}
                        onValueChange={handleOnValueChangeMax}
                        decimalsLimit={6}
                        allowNegativeValue={false}
                        value={valueMax}
                        step={1}
                        placeholder={"Max"}
                    />
                </div>
                <div className="col-2">
                    <PPDefaultSelector
                        id="selectPricetableFilterShopOption"
                        data={prepareFilterOptionOptions()}
                        name="FilterOptions"
                        placeholder={t("shop_filter.filter_options")}
                        onChange={(e) => setSelectedFilterOption(e?.value)}
                        isClearable={false}
                        value={
                            selectedFilterOption != null ? selectedFilterOption.value : prepareFilterOptionOptions()[0]
                        }
                    />
                </div>
                <div className="col-0">
                    <button
                        id="buttonPricetableFilterPriceApplyFilter"
                        className="category-button-go btn btn-primary btn-block"
                        type="button"
                        ref={addFilterBtnRef}
                        onClick={filterPricetableData}>
                        {t("single.apply_filter")}
                    </button>
                </div>
                {/* <div className="col-12 mt-3">
                    <label htmlFor="intlConfigSelect">Intl option</label>
                    <select className="form-control" id="intlConfigSelect" onChange={handleIntlSelect}>
                        {options.map((config, i) => {
                            if (config) {
                                const { locale, currency } = config
                                return (
                                    <option key={`${locale}${currency}`} value={i}>
                                        {locale}
                                    </option>
                                )
                            }
                        })}
                    </select>
                </div> */}
            </div>
        </div>
    )
}

export default PPPriceFilter
