import React from "react"
import "../App.css"

function PPErrorDisplay({ error }) {
    return (
        <div className="alert alert-danger">
            <h3>
                <i className="icon fas fa-ban" /> Es ist ein Fehler aufgetreten!
            </h3>
            <h6>Fehler: {error?.message}</h6>
            <h6>Statuscode: {error?.networkError?.statusCode}</h6>
        </div>
    )
}

export default PPErrorDisplay
