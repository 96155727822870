import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import { useAuth } from "../hooks/use-auth"
import { toast } from "react-toastify"
import $ from "jquery"

const UPDATE_USER_PASSWORD_QUERY = gql`
    mutation ($username: String, $old_password: String, $new_password: String) {
        updateUserPassword(username: $username, old_password: $old_password, new_password: $new_password)
    }
`

const style = {
    flexbox_container: { display: "flex", flexDirection: "row", alignItems: "center" },
    eye_icon: { padding: "15px 0 15px 15px", cursor: "pointer" }
}

export function ChangePassword() {
    const auth = useAuth()
    const [t] = useTranslation()
    const [oldPasswordValues, setOldPasswordValues] = useState({
        password: "",
        showPassword: false
    })
    const [newPasswordValues, setNewPasswordValues] = useState({
        password: "",
        showPassword: false
    })
    const [repeatNewPasswordValues, setRepeatNewPasswordValues] = useState({
        password: "",
        showPassword: false
    })
    const [newPasswordValid, setNewPasswordValid] = useState(true)
    const [newPasswordRepeatedValid, setNewPasswordRepeatedValid] = useState(true)

    const [showWrongInputError, setShowWrongInputError] = useState({
        show: false,
        message: ""
    })

    const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD_QUERY, {
        onCompleted: (data) => {
            if (data?.updateUserPassword) {
                toast(t("settings.password_settings.save.saved_successfully"))
                $("#passwordModalCenter").modal("hide")
            } else {
                toast.error(t("settings.password_settings.save.save_failed"), {
                    autoClose: false,
                    closeOnClick: false
                })
            }
        },
        onError: (error) => {
            if (error.message.toString().length > 0) {
                toast.error(t("settings.password_settings.save.save_failed_error"), {
                    autoClose: false,
                    closeOnClick: false
                })
            }
        }
    })

    function resetInputFields() {
        setNewPasswordValues({ password: "", showPassword: false })
        setRepeatNewPasswordValues({ password: "", showPassword: false })
        setOldPasswordValues({ password: "", showPassword: false })
        setShowWrongInputError({ show: false, message: "" })
    }

    function validateNewPassword(pw) {
        if (pw == repeatNewPasswordValues.password) {
            setNewPasswordRepeatedValid(true)
        }

        let regularExpression = /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*_-~§=]{8,32}$/

        return pw == "" || regularExpression.test(pw)
    }

    function validateNewPasswordRepeatedCorrectly(pw) {
        return pw == newPasswordValues.password
    }

    function submitData() {
        let validNewPassword = validateNewPassword(newPasswordValues.password)
        let pwRepeatedCorrectly = validateNewPasswordRepeatedCorrectly(repeatNewPasswordValues.password)

        let oldPasswordEmpty = oldPasswordValues.password == ""
        let newPasswordEmpty = newPasswordValues.password == ""

        if (validNewPassword && pwRepeatedCorrectly && !oldPasswordEmpty && !newPasswordEmpty) {
            let requestData = {
                variables: {
                    username: auth.login.user.username,
                    old_password: oldPasswordValues.password,
                    new_password: newPasswordValues.password
                }
            }
            updateUserPassword(requestData)
        } else {
            let message

            if (oldPasswordEmpty) {
                message = t("settings.password_settings.error.insert_old_password")
            } else if (newPasswordEmpty) {
                message = t("settings.password_settings.error.insert_new_password")
            } else if (!validNewPassword) {
                message = t("settings.password_settings.error.passwort_does_not_meet_requirements")
            } else {
                message = t("settings.password_settings.error.password_was_not_repeated_correctly")
            }

            setShowWrongInputError({
                show: true,
                message: message
            })
        }
    }

    return (
        <>
            <div className="row">
                <div className="col d-xl-flex justify-content-xl-center">
                    <button
                        className="btn btn-primary btn-sm mt-2"
                        type="button"
                        data-toggle="modal"
                        data-target="#passwordModalCenter"
                        onClick={() => resetInputFields()}>
                        {t("single.change_password")}
                    </button>
                </div>
            </div>
            <div
                className="modal fade"
                id="passwordModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="Dialog zum Ändern des Passwortes"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="passwordModalLongTitle">
                                {t("settings.password_settings.header_change_password_dialog")}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <div className="alert alert-info" role="alert">
                                {t("settings.password_settings.password_requirements")}
                                <ul style={{ margin: 0 }}>
                                    <li>
                                        {t("settings.password_settings.at_least")}{" "}
                                        <strong>{t("settings.password_settings.digit_count")}</strong>
                                    </li>
                                    <li>
                                        {t("settings.password_settings.at_least")}{" "}
                                        <strong>{t("settings.password_settings.capital_letter")}</strong>
                                    </li>
                                    <li>
                                        {t("settings.password_settings.at_least")}{" "}
                                        <strong>{t("settings.password_settings.lowercase_letter")}</strong>
                                    </li>
                                    <li>
                                        {t("settings.password_settings.at_least")}{" "}
                                        <strong>{t("settings.password_settings.number")}</strong>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group">
                                <label>{t("settings.password_settings.old_password")}</label>
                                <div style={style.flexbox_container}>
                                    <input
                                        id="inputUserChangePWOldPassword"
                                        type={oldPasswordValues.showPassword ? "text" : "password"}
                                        className="form-control"
                                        onChange={(event) =>
                                            setOldPasswordValues({ ...oldPasswordValues, password: event.target.value })
                                        }
                                        value={oldPasswordValues.password}
                                    />
                                    <i
                                        onClick={() =>
                                            setOldPasswordValues({
                                                ...oldPasswordValues,
                                                showPassword: !oldPasswordValues.showPassword
                                            })
                                        }
                                        className={oldPasswordValues.showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                                        style={style.eye_icon}></i>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t("settings.password_settings.new_password")}</label>
                                <div style={style.flexbox_container}>
                                    <input
                                        id="inputUserChangePWNewPassword"
                                        ref={(node) => {
                                            if (node) {
                                                if (newPasswordValid) {
                                                    node.style.setProperty("border-color", "")
                                                } else {
                                                    node.style.setProperty("border-color", "#ff0000", "important")
                                                }
                                            }
                                        }}
                                        type={newPasswordValues.showPassword ? "text" : "password"}
                                        className="form-control"
                                        onChange={(event) => {
                                            setNewPasswordValues({ ...newPasswordValues, password: event.target.value })
                                            setNewPasswordValid(validateNewPassword(event.target.value))
                                        }}
                                        value={newPasswordValues.password}
                                    />
                                    <i
                                        onClick={() =>
                                            setNewPasswordValues({
                                                ...newPasswordValues,
                                                showPassword: !newPasswordValues.showPassword
                                            })
                                        }
                                        className={newPasswordValues.showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                                        style={style.eye_icon}></i>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>{t("settings.password_settings.repeat_new_password")}</label>
                                <div style={style.flexbox_container}>
                                    <input
                                        id="inputUserChangePWNewPasswordRepeat"
                                        // style={{ borderColor: newPasswordRepeatedError ? "#ff0000 !important" : "" }}
                                        ref={(node) => {
                                            if (node) {
                                                if (newPasswordRepeatedValid) {
                                                    node.style.setProperty("border-color", "")
                                                } else {
                                                    node.style.setProperty("border-color", "#ff0000", "important")
                                                }
                                            }
                                        }}
                                        type={repeatNewPasswordValues.showPassword ? "text" : "password"}
                                        className="form-control"
                                        onChange={(event) => {
                                            setRepeatNewPasswordValues({
                                                ...repeatNewPasswordValues,
                                                password: event.target.value
                                            })
                                            setNewPasswordRepeatedValid(
                                                validateNewPasswordRepeatedCorrectly(event.target.value)
                                            )
                                        }}
                                        value={repeatNewPasswordValues.password}
                                    />
                                    <i
                                        onClick={() =>
                                            setRepeatNewPasswordValues({
                                                ...repeatNewPasswordValues,
                                                showPassword: !repeatNewPasswordValues.showPassword
                                            })
                                        }
                                        className={
                                            repeatNewPasswordValues.showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                                        }
                                        style={style.eye_icon}></i>
                                </div>
                            </div>
                            {showWrongInputError.show && (
                                <div className="alert alert-danger" role="alert">
                                    {showWrongInputError.message}
                                </div>
                            )}
                            <div className="text-right">
                                <button
                                    className="btn btn-primary"
                                    style={{ minWidth: "100px" }}
                                    type="button"
                                    onClick={() => submitData()}>
                                    {t("single.save")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
