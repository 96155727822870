import React, { useEffect, useState, useRef } from "react"
import "../App.css"
import { useTranslation } from "react-i18next"
import PPUtils from "../utils/PPUtils"
import { toast } from "react-toastify"
import { PPDefaultSelector } from "./PPDefaultSelector"
import { MultiValueLabel, Option } from "./PPShopPreset"
import _ from "lodash"
import MappingState from "../utils/const/MappingState"
import { gql, useMutation } from "@apollo/client"

const UPDATE_USER_SETTINGS_LOCALSTORAGE = gql`
    mutation ($userId: Long, $localstorage: String) {
        updateUserSettingsLocalstorage(userId: $userId, localstorage: $localstorage)
    }
`

function SettingsCheckbox({ name, defaultValue, label, description, updateUserSettingsLocalstorage }) {
    const [mounted, setMounted] = useState(false)
    const [isChecked, setIsChecked] = useState(localStorage.getItem(name))

    useEffect(() => {
        if (mounted) {
            localStorage.setItem(name, isChecked)
            updateUserSettingsLocalstorage()
        }
    }, [isChecked])

    useEffect(() => {
        setMounted(true)
    }, [])

    return (
        <div className="form-group">
            <div className="custom-control custom-switch">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={name}
                    checked={isChecked ? isChecked !== "false" : defaultValue}
                    onChange={(event) => {
                        setIsChecked(event.target.checked.toString())
                    }}
                />
                <label className="custom-control-label" htmlFor={name}>
                    {label}
                </label>
            </div>
            <div className="smallinfo">{description}</div>
        </div>
    )
}

function PPPriceSettings({ settingLocalstorage, shops }) {
    const [t] = useTranslation()
    let switchPriceAge = settingLocalstorage.PPPriceSettings.find((e) => e.name == "switchPriceAge")
    let switchStock = settingLocalstorage.PPPriceSettings.find((e) => e.name == "switchStock")
    let switchCustomerStock = settingLocalstorage.PPPriceSettings.find((e) => e.name == "switchCustomerStock")
    let switchCurrency = settingLocalstorage.PPPriceSettings.find((e) => e.name == "switchCurrency")
    let defaultMappingStats = localStorage.getItem("mappingState")
    let sortRanking = settingLocalstorage.PPPriceSettings.find((e) => e.name == "sortRanking")
    let showTotalPrice = settingLocalstorage.PPPriceSettings.find((e) => e.name == "showTotalPrice")
    let showLongShopname = settingLocalstorage.PPPriceSettings.find((e) => e.name == "showLongShopname")
    let showPriceDifferenceThumb = settingLocalstorage.PPPriceSettings.find((e) => e.name == "showPriceDifferenceThumb")
    let defaultLanguageStats = localStorage.getItem("default_language_stats")
    let dashboardTopProductsOwnNrs = localStorage.getItem("dashboard_topproducts_own_nrs")
    let switchShowLinks = settingLocalstorage.PPPriceSettings.find((e) => e.name == "switchShowLinks")
    let switchDiscontinuedItems = settingLocalstorage.PPPriceSettings.find((e) => e.name == "switchDiscontinuedItems")

    let languages = _.uniqBy(
        shops.map((x) => ({
            label: x.lang,
            value: x.lang,
            lang: x.lang
        })),
        (e) => e.value
    )

    let mappingStatesData = [
        { label: t("settings.pricetable.mappingState.mapped"), value: MappingState.MAPPED },
        { label: t("settings.pricetable.mappingState.unmapped"), value: MappingState.UNMAPPED },
        { label: t("settings.pricetable.mappingState.mapped_and_unmapped"), value: MappingState.MAPPED_AND_UNMAPPED }
    ]

    let toastId = useRef(null)
    const [updateUserSLMu] = useMutation(UPDATE_USER_SETTINGS_LOCALSTORAGE, {
        onCompleted: (data) => {
            if (data?.updateUserSettingsLocalstorage) {
                if (toastId.current) {
                    toast.update(toastId.current)
                } else {
                    toastId.current = toast(t("settings.pricetable.toast_save_ok"), {
                        autoClose: 1000,
                        onClose: function () {
                            toastId.current = null
                        }
                    })
                }
            } else {
                toast.error(t("settings.pricetable.toast_error"), {})
            }
        },
        onError: (error) => {
            if (error.message.toString().length > 0) {
                toast.error(t("settings.pricetable.toast_error"), {})
            }
        }
    })

    function updateUserSettingsLocalstorage() {
        let currentAuthLogin = PPUtils.getLogin()
        let jsonArr = {}
        Object.keys(localStorage).forEach(function (key) {
            if (key != "login" && key != "presetId") {
                jsonArr[key] = localStorage.getItem(key)
            }
        })
        let localstorage = JSON.stringify(jsonArr)
        let requestData = {
            variables: {
                userId: currentAuthLogin.user.id,
                localstorage: localstorage
            }
        }
        updateUserSLMu(requestData)

        currentAuthLogin.user.configSettingsLocalstorage = localstorage
        PPUtils.updateLogin(currentAuthLogin)
    }

    return (
        <>
            <div className="card shadow col-12">
                <div className="card-header">
                    <h2 className="card-title">{t("settings.pricetable.header")}</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={switchPriceAge?.defaultValue}
                                name={switchPriceAge?.name}
                                label={t("settings.pricetable.switchPriceAge.label")}
                                description={t("settings.pricetable.switchPriceAge.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={switchStock?.defaultValue}
                                name={switchStock?.name}
                                label={t("settings.pricetable.switchStock.label")}
                                description={t("settings.pricetable.switchStock.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={switchCustomerStock?.defaultValue}
                                name={switchCustomerStock?.name}
                                label={t("settings.pricetable.switchCustomerStock.label")}
                                description={t("settings.pricetable.switchCustomerStock.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={switchCurrency?.defaultValue}
                                name={switchCurrency?.name}
                                label={t("settings.pricetable.switchCurrency.label")}
                                description={t("settings.pricetable.switchCurrency.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={showPriceDifferenceThumb?.defaultValue}
                                name={showPriceDifferenceThumb?.name}
                                label={t("settings.pricetable.showPriceDifferenceThumb.label")}
                                description={t("settings.pricetable.showPriceDifferenceThumb.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={sortRanking?.defaultValue}
                                name={sortRanking?.name}
                                label={t("settings.pricetable.sortRanking.label")}
                                description={t("settings.pricetable.sortRanking.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={showTotalPrice?.defaultValue}
                                name={showTotalPrice?.name}
                                label={t("settings.pricetable.showTotalPrice.label")}
                                description={t("settings.pricetable.showTotalPrice.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={showLongShopname?.defaultValue}
                                name={showLongShopname?.name}
                                label={t("settings.pricetable.showLongShopname.label")}
                                description={t("settings.pricetable.showLongShopname.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={switchShowLinks?.defaultValue}
                                name={switchShowLinks?.name}
                                label={t("settings.pricetable.switchShowLinks.label")}
                                description={t("settings.pricetable.switchShowLinks.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                        <div className="col-3">
                            <SettingsCheckbox
                                defaultValue={switchDiscontinuedItems?.defaultValue}
                                name={switchDiscontinuedItems?.name}
                                label={t("settings.pricetable.switchDiscontinuedItems.label")}
                                description={t("settings.pricetable.switchDiscontinuedItems.description")}
                                updateUserSettingsLocalstorage={updateUserSettingsLocalstorage}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label>{t("settings.pricetable.defaultLanguageStats.label")}</label>
                            <PPDefaultSelector
                                isClearable={false}
                                onChange={(selected) => {
                                    if (selected?.value != "") {
                                        localStorage.setItem("default_language_stats", selected?.value)
                                    } else {
                                        localStorage.setItem("default_language_stats", "de")
                                    }
                                    updateUserSettingsLocalstorage()
                                }}
                                data={languages}
                                defaultValue={languages.filter((option) => option.value === defaultLanguageStats)}
                                components={{ MultiValueLabel, Option }}
                            />
                        </div>
                        <div className="col-3">
                            <label title={t("settings.pricetable.mappingState.description")}>
                                {t("settings.pricetable.mappingState.label")}
                            </label>
                            <PPDefaultSelector
                                isClearable={false}
                                onChange={(selected) => {
                                    if (selected?.value != "") {
                                        localStorage.setItem("mappingState", selected?.value)
                                    } else {
                                        localStorage.setItem("mappingState", MappingState.MAPPED_AND_UNMAPPED)
                                    }
                                    updateUserSettingsLocalstorage()
                                }}
                                data={mappingStatesData}
                                defaultValue={mappingStatesData.filter(
                                    (option) => option.value === defaultMappingStats
                                )}
                                components={{ MultiValueLabel, Option }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow col-12">
                <div className="card-header">
                    <h2 className="card-title">Dashboard</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <label>
                                {t("dashboard.top_products")} ({t("single.productnr")} {t("single.comma_separated")})
                            </label>
                            <input
                                onChange={(e) => {
                                    let error = false
                                    let produktnrs = e.target.value
                                    if (produktnrs?.length > 0) {
                                        if (!PPUtils.checkStringInputProductNrs(produktnrs)) {
                                            toast.error(
                                                t("single.mandatoryfield_error", { field: t("dashboard.top_products") })
                                            )
                                            error = true
                                        }
                                    }
                                    if (!error) {
                                        localStorage.setItem("dashboard_topproducts_own_nrs", produktnrs)
                                        updateUserSettingsLocalstorage()
                                    }
                                }}
                                className="form-control"
                                type="input"
                                placeholder=""
                                defaultValue={dashboardTopProductsOwnNrs}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PPPriceSettings
