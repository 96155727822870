import React, { useState } from "react"
import Avatar from "react-avatar-edit"
import { useTranslation } from "react-i18next"
import PPUtils from "../utils/PPUtils"

export function AvatarEditor({ avatarState, name }) {
    const [t] = useTranslation()
    const [preview, setPreview] = useState("")
    const [avatar, setAvatar] = avatarState

    return (
        <>
            <div className="row">
                <div className="col">
                    {avatar ? (
                        <img src={avatar} className="rounded-circle elevation-2" width="150px" />
                    ) : (
                        PPUtils.getDummyAvatarString(name, 150, "5em")
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col d-xl-flex justify-content-xl-center">
                    <button
                        className="btn btn-primary btn-sm mt-2"
                        type="button"
                        data-toggle="modal"
                        data-target="#avatarEditorModalCenter"
                    >
                        {t("single.change_avatar_editor")}
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col d-xl-flex justify-content-xl-center">
                    <button
                        className="btn btn-primary btn-sm mt-2"
                        onClick={() => {
                            setPreview("")
                            setAvatar("")
                        }}
                    >
                        {t("single.change_avatar_reset")}
                    </button>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal fade"
                id="avatarEditorModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="avatarEditorModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered" role="dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="avatarModalLongTitle">
                                {t("settings.usersettings.header_avatar_editor")}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body avatar-padding avatar-editor-modal-body">
                            <div className="row">
                                <div className="col-8">
                                    <Avatar
                                        width="100%"
                                        height={295}
                                        onCrop={(previewAvatar) => {
                                            setPreview(previewAvatar)
                                        }}
                                        exportAsSquare={true}
                                        exportSize={300}
                                    />
                                </div>
                                <div className="col-4 text-center">
                                    <h5>{t("single.preview")}</h5>
                                    <img src={preview} alt="" className="avatarEditorPreview rounded-circle" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.close")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => setAvatar(preview)}
                            >
                                {t("single.apply_filter")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
