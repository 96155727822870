import "./wdyr"

import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./pages/App"
import * as serviceWorker from "./serviceWorker"
import { ProvideAuth } from "./hooks/use-auth"
import { BrowserRouter } from "react-router-dom"
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split } from "@apollo/client"
import { WebSocketLink } from "@apollo/client/link/ws"
import { setContext } from "@apollo/client/link/context"
import PPUtils from "./utils/PPUtils"
import { getMainDefinition } from "@apollo/client/utilities"
import "./i18nConf"

const httpLink = createHttpLink({
    uri: "/graphql"
})

const wsLink = new WebSocketLink({
    uri:
        window.location.hostname == `localhost`
            ? `ws://localhost:4000/graphql`
            : `wss://` + window.location.hostname + `/graphqlinstant`,
    options: {
        reconnect: true,
        connectionParams: {
            Authorization: "Bearer " + PPUtils.getLogin()?.token
        }
    }
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === "OperationDefinition" && definition.operation === "subscription"
    },
    wsLink,
    httpLink
)

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = PPUtils.getLogin()?.token

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : ""
        }
    }
})

const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache({
        addTypename: false
    }),
    addTypename: false
})

const basename = process.env.PUBLIC_URL

createRoot(document.getElementById("root")).render(
    //<React.StrictMode>
    <ProvideAuth>
        <ApolloProvider client={client}>
            <BrowserRouter basename={basename}>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </ProvideAuth>
    //</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
