import React from "react"
import dummyLogo from "../img/preispiranha_logo_sidebar.png"
import { NavLink } from "react-router-dom"
import useRequireAuth from "../hooks/use-require-auth"
import { useTranslation } from "react-i18next"

export function TopRightToolbar({ customerLogo }) {
    const auth = useRequireAuth()
    const [t] = useTranslation()
    return (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <img className={"nav-link"} src={customerLogo} alt="" />
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown">
                    <i style={{ fontSize: "150%" }} className="far fa-comments"></i>
                    <span className="badge badge-danger navbar-badge">3</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a className="dropdown-item">
                        <div className="media">
                            <img src={dummyLogo} alt="PP Logo" className={"messages_server_logo"} />
                            <div className="media-body">
                                <h3 className="dropdown-item-title">
                                    PP Server
                                    <span className="float-right text-sm text-muted">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </h3>
                                <p className="text-sm">IC für Produkt T1058 ist fertig!</p>
                                <p className="text-sm text-muted">
                                    <i className="far fa-clock mr-1"></i> Vor 4 Stunden
                                </p>
                            </div>
                        </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item">
                        <div className="media">
                            <img src={dummyLogo} alt="PP Logo" className={"messages_server_logo"} />
                            <div className="media-body">
                                <h3 className="dropdown-item-title">
                                    PP Server
                                    <span className="float-right text-sm text-muted">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </h3>
                                <p className="text-sm">Nächtlicher Datenexport steht zum Download bereit.</p>
                                <p className="text-sm text-muted">
                                    <i className="far fa-clock mr-1"></i> Vor 5 Stunden
                                </p>
                            </div>
                        </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item">
                        <div className="media">
                            <img src={dummyLogo} alt="PP Logo" className={"messages_server_logo"} />
                            <div className="media-body">
                                <h3 className="dropdown-item-title">
                                    PP Server
                                    <span className="float-right text-sm text-muted">
                                        <i className="fas fa-star"></i>
                                    </span>
                                </h3>
                                <p className="text-sm">Das Produkt T0158 wurde erfolgreich verknüpft.</p>
                                <p className="text-sm text-muted">
                                    <i className="far fa-clock mr-1"></i> Vor 6 Stunden
                                </p>
                            </div>
                        </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item dropdown-footer">Alle Nachrichten</a>
                </div>
            </li>
            <li className="nav-item dropdown">
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span className="dropdown-header">15 Notifications</span>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item">
                        <i className="fas fa-envelope mr-2"></i> 4 new messages
                        <span className="float-right text-muted text-sm">3 mins</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item">
                        <i className="fas fa-users mr-2"></i> 8 friend requests
                        <span className="float-right text-muted text-sm">12 hours</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item">
                        <i className="fas fa-file mr-2"></i> 3 new reports
                        <span className="float-right text-muted text-sm">2 days</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item dropdown-footer">See All Notifications</a>
                </div>
            </li>
            <li className="nav-item">
                <NavLink to="/loginpage" onClick={() => auth.signout()} className="nav-link">
                    <i
                        style={{ fontSize: "150%" }}
                        title={t("app.menu.logout")}
                        className="nav-icon fas fa-sign-out-alt"
                    ></i>
                </NavLink>
            </li>
        </ul>
    )
}
