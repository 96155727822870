import React, { useEffect } from "react"
import validator from "validator"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import PPPictureBoxLoad from "./PPPictureBoxLoad"
import { PPCompetitors } from "./PPCompetitor"
import jquery from "jquery"
import useRequireAuth from "../hooks/use-require-auth"
import PPUtils from "../utils/PPUtils"
import { PPRightTabComment } from "./PPRightTabComment"

export default function PPrightTabMatching(props) {
    const [t] = useTranslation()
    let matchingURL = ""

    function validateURL(url) {
        const errors = {}
        let shopUrlInfo = new URL(props.selectedProductRow?.shopDomain)

        if (url.startsWith("appcrawl://")) {
            return errors
        }

        if (!validator.isURL(url)) {
            errors.url = t("pricetable.righttab.url.error.urlformat")
        }

        if (!url?.toLowerCase().includes(shopUrlInfo.hostname.toLowerCase())) {
            errors.url = t("pricetable.righttab.url.error.shopname")
        }

        return errors
    }

    function getTarifIdFromTariffuxx(url) {
        var regex = /[?&]product_config=([^&]+)/

        var match = url.match(regex)

        if (match && match[1]) {
            return match[1]
        } else {
            return null
        }
    }

    function startMatchingAfterValidation() {
        if (matchingURL !== undefined) {
            if (matchingURL.includes("google")) {
                let google_domains = ["google.de", "google.at", "google.ch"]
                google_domains.forEach(function (el_domain) {
                    if (matchingURL.includes(el_domain)) {
                        matchingURL = matchingURL.replace(el_domain, "google.com")
                    }
                })
                matchingURL = transformGoogleShoppingURL(matchingURL)
            }
            if (props.selectedProductRow?.shopDomain.includes("picnic.de") && !matchingURL.startsWith("appcrawl://")) {
                matchingURL = "appcrawl://picnic.de/" + matchingURL
            }
            if (
                props.selectedProductRow?.shopDomain.includes("preisvergleich.de") &&
                matchingURL.includes("tariffuxx.de/handytarife/angebot")
            ) {
                var tarifId = getTarifIdFromTariffuxx(matchingURL)
                if (tarifId === null) {
                    toast.error(t("pricetable.righttab.url.error.urlformat"))
                    return
                }
                matchingURL = "https://handy.preisvergleich.de/rechner/handytarife/#" + tarifId
            }
            const errors = validateURL(matchingURL)
            if (Object.keys(errors)?.length === 0) {
                props.onIcButtonClick(matchingURL, 1)
            } else {
                toast.error(errors.url)
            }
            document.getElementById("inputURL").value = ""
        }
    }

    function transformGoogleShoppingURL(vMatchingURL) {
        if (!vMatchingURL.includes("?hl") && vMatchingURL.includes("&hl=") && !vMatchingURL.includes("search?q=")) {
            let newGSURL = vMatchingURL.substring(0, vMatchingURL.indexOf("?") + 1)
            newGSURL += vMatchingURL.substring(vMatchingURL.indexOf("hl="), vMatchingURL.indexOf("hl=") + 5)
            return newGSURL
        }
        return vMatchingURL
    }

    useEffect(() => {
        if (!jquery("body").hasClass("control-sidebar-slide-open")) {
            jquery("#inputURL").val("")
        }
    })

    //extra wegen Flaschenpost
    const auth = useRequireAuth()
    const currentAuth = PPUtils.getCurrentAuthFromAuth(auth)

    return (
        <>
            <div className="d-flex align-items-center bg-white mr-0 my-3 m-3 pl-2">
                <button id="sidebarclose" type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="d-flex align-items-center bg-white mr-0 my-3 m-3">
                <div className="productimgbox">
                    <PPPictureBoxLoad image={props.selectedProductRow?.imageUrl} className={"img-fluid"} />
                </div>
                <div>
                    <h5 className="col px-3 p-2 align-self-start">{props.selectedProductRow?.productName}</h5>
                    <p className="col px-3">Shop: {props.selectedProductRow?.shopName}</p>
                    <p className="col px-3">
                        {t("single.category")}: {props.selectedProductRow?.department}
                    </p>
                    <p className="col px-3">
                        {PPUtils.isTariffComparisonMode(props?.selectedProductRow?.tariffComparisonMode)
                            ? t("overwrite.tariffcomparisonmode.single.manufacturer")
                            : t("single.manufacturer")}
                        : {props.selectedProductRow?.producer}
                    </p>
                </div>
            </div>
            <div className="card card-primary card-outline card-outline-tabs m-3">
                <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li className="nav-item active">
                            <a
                                className="nav-link active"
                                id="custom-tabs-four-matching-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-matching"
                                role="tab"
                                onClick={() => window.dispatchEvent(new Event("resize"))}
                                aria-controls="custom-tabs-four-matching"
                                aria-selected="true">
                                {t("pricetable.righttab.url.label_matching")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                id="custom-tabs-four-suggestions-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-suggestions"
                                role="tab"
                                aria-controls="custom-tabs-four-suggestions"
                                aria-selected="false">
                                {t("pricetable.righttab.suggestions")}
                            </a>
                        </li>
                        {currentAuth.customer.name !== "Flaschenpost" && (
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="custom-tabs-four-comment-tab"
                                    data-toggle="pill"
                                    href="#custom-tabs-four-comment"
                                    role="tab"
                                    aria-controls="custom-tabs-four-comment"
                                    aria-selected="false">
                                    {t("pricetable.righttab.comment")}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>

                <div className="card-body">
                    <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div
                            className="tab-pane fade active show"
                            id="custom-tabs-four-matching"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-matching-tab">
                            <p>
                                {t("pricetable.righttab.url.infotext1")}
                                <br />
                                {t("pricetable.righttab.url.infotext2")}
                            </p>
                            <div>
                                <label>{t("pricetable.righttab.url.label_to_link")}</label>
                                <input
                                    onChange={(e) => (matchingURL = e.target.value)}
                                    type="input"
                                    className="form-control"
                                    id="inputURL"
                                    placeholder="https://example.url/product/12"
                                />
                            </div>
                            <br />
                            <button
                                type="button"
                                data-toggle="modal"
                                data-target="#urlMatchingModal"
                                className="btn btn-block btn-primary">
                                {t("pricetable.righttab.url.label_start_matching")}
                            </button>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="custom-tabs-four-suggestions"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-suggestions-tab">
                            <PPCompetitors
                                productId={props.selectedProductRow?.id}
                                shopId={props.selectedProductRow.shopId}
                                productNr={props.selectedProductRow?.productNr}
                                refetchPricetable={props.refetchPricetable}
                                onIcButtonClick={props.onIcButtonClick}
                            />
                        </div>
                        {currentAuth.customer.name !== "Flaschenpost" && (
                            <div
                                className="tab-pane fade"
                                id="custom-tabs-four-comment"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-four-comment-tab">
                                <PPRightTabComment
                                    comment={props.selectedProductRow.comment}
                                    saveCommentMappingEditinfo={props.saveCommentMappingEditinfo}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                data-backdrop="false"
                id="urlMatchingModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="urlMatchingModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="urlMatchingModalLabel">
                                {t("pricetable.righttab.url.label_matching")}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {t("pricetable.righttab.url.modal_text")}{" "}
                            <ul>
                                <li>{t("pricetable.righttab.url.modal_text_info_1")}</li>
                                <li>{t("pricetable.righttab.url.modal_text_info_2")}</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.cancel")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-primary"
                                ref={props.icBtnRef}
                                data-dismiss="modal"
                                onClick={startMatchingAfterValidation}>
                                {t("pricetable.righttab.url.label_start_matching")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
