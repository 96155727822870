import React, { useEffect, useReducer, useState } from "react"
import "../App.css"
import ReactApexChart from "react-apexcharts"
import { DateRangeInput } from "@datepicker-react/styled"
import { ThemeProvider } from "styled-components"
import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

const query = gql`
    query getPriceHistory(
        $productId: Long!
        $fromMillis: Long
        $toMillis: Long
        $shopIds: [Long]
        $columnLang: String
    ) {
        priceHistory(
            productId: $productId
            fromMillis: $fromMillis
            toMillis: $toMillis
            shopIds: $shopIds
            columnLang: $columnLang
        ) {
            priceData {
                price
                totalPrice
                totalPriceByTotalPriceProvider
                secondPrice
                secondTotalPrice
                currencyId
                secondCurrencyId
                crawlDate
                cheapestPriceProvider
                cheapestPriceProviderByTotalPrice
            }
            shop {
                id
                name
                lang
                colorHex
            }
        }
    }
`

let startDateOneWeekAgo = new Date()
startDateOneWeekAgo.setDate(startDateOneWeekAgo.getDate() - 2 * 7)
startDateOneWeekAgo.setHours(0, 0, 0)

let endDate = new Date()
endDate = prepareEndDate(endDate)

const initialState = {
    startDate: startDateOneWeekAgo,
    endDate: endDate,
    focusedInput: null
}

function reducer(state, action) {
    switch (action.type) {
        case "focusChange":
            return { ...state, focusedInput: action.payload }
        case "dateChange":
            return action.payload
        default:
            throw new Error()
    }
}

function prepareEndDate(endDate) {
    endDate.setHours(23, 59, 59)
    return endDate
}

function PPDateRangePicker(props) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <ThemeProvider
            theme={{
                breakpoints: ["32em", "48em", "64em"],
                reactDatepicker: {
                    daySize: [20, 20],
                    dayFontSize: 10,
                    inputMinHeight: 20,
                    inputFontSize: 15,
                    // inputCalendarWrapperWidth: 10,
                    // inputCalendarWrapperHeight: 10,
                    // inputCalendarIconWidth: 10,
                    // inputCalendarIconHeight: 10,
                    inputCalendarWrapperTop: "15px",
                    datepickerZIndex: 999,
                    dateRangeStartDateInputPadding: "5px",
                    dateRangeEndDateInputPadding: "5px",
                    datepickerPadding: "5px",
                    // fontFamily: "system-ui, -apple-system",
                    colors: {
                        // accessibility: "#D80249",
                        // selectedDay: "#f7518b",
                        // selectedDayHover: "#F75D95",
                        // primaryColor: "#d8366f"
                    }
                }
            }}>
            <DateRangeInput
                onDatesChange={(data) => {
                    dispatch({ type: "dateChange", payload: data })
                    props.handleDateChange(data)
                    return null
                }}
                onFocusChange={(focusedInput) => dispatch({ type: "focusChange", payload: focusedInput })}
                startDate={state.startDate} // Date or null
                endDate={state.endDate} // Date or null
                focusedInput={state.focusedInput} // START_DATE, END_DATE or null
                // placement={"top"}
                displayFormat={"dd.MM.yyyy"}
                showSelectedDates={false}
                showClose={false}
                showStartDateCalendarIcon={false}
                showEndDateCalendarIcon={false}
                showResetDates={true}
            />
        </ThemeProvider>
    )
}

function PPPriceHistory(props) {
    const [t] = useTranslation()
    const [priceHistory, setPriceHistory] = useState([])
    const [dateRange, setDateRange] = useState(initialState)
    const [variables, setVariables] = useState()

    function handleDateChange(data) {
        data.endDate = prepareEndDate(data.endDate)
        setDateRange(data)
    }

    function getChartPrice(pd) {
        if (localStorage.getItem("showTotalPrice") === "true") {
            return pd.secondTotalPrice !== -1
                ? pd.secondTotalPrice.toFixed(2)
                : pd.totalPriceByTotalPriceProvider > 0
                ? pd.totalPriceByTotalPriceProvider.toFixed(2)
                : pd.totalPrice.toFixed(2)
        } else {
            return pd.secondPrice !== -1 ? pd.secondPrice.toFixed(2) : pd.price.toFixed(2)
        }
    }

    function getChartMerchantName(pd) {
        if (localStorage.getItem("showTotalPrice") === "true") {
            return pd.cheapestPriceProviderByTotalPrice != "" ? pd.cheapestPriceProviderByTotalPrice : ""
        } else {
            return pd.cheapestPriceProvider != "" ? pd.cheapestPriceProvider : ""
        }
    }

    function prepareSeriesDataForAreaChart() {
        if (localStorage.getItem("switchCurrency") === "true") {
            return priceHistory.map((price) => ({
                name: price.shop.name + "." + price.shop.lang,
                data: price.priceData.map((pd) => [pd.crawlDate, getChartPrice(pd), getChartMerchantName(pd)])
            }))
        }

        return priceHistory.map((price) => ({
            name: price.shop.name + "." + price.shop.lang,
            data: price.priceData.map((pd) => [pd.crawlDate, getChartPrice(pd), getChartMerchantName(pd)])
        }))
    }

    const { refetch } = useQuery(query, {
        displayName: "PriceHistoryQuery",
        variables: variables,
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            setPriceHistory(data.priceHistory)
        },
        onError: (error) => console.error(error)
    })

    useEffect(() => {
        if (props.selectedProductId === undefined) {
            return
        }

        let newVariables = {
            productId: props.selectedProductId,
            shopIds:
                props.foreignShop !== null && props.foreignShop !== undefined
                    ? props.foreignShop.shopId
                    : props.shopIds,
            columnLang: props.columnLang
        }

        if (
            dateRange !== null &&
            dateRange !== undefined &&
            dateRange?.startDate !== null &&
            dateRange?.endDate !== null
        ) {
            newVariables.fromMillis = dateRange?.startDate.getTime()
            newVariables.toMillis = dateRange?.endDate.getTime()
        }

        setVariables(newVariables)
    }, [props.foreignShop?.shopId, props.selectedProductId, props.shopIds, dateRange, refetch])

    function prepareOptions() {
        let productname = props?.productname ? props?.productname : props?.foreignShop?.productName
        if (!productname) {
            productname = ""
        }
        productname = productname.replace(/^(.{90}[^\s]*).*/, "$1")

        return {
            colors: priceHistory.map((price) => {
                if (price.shop.colorHex == null) {
                    return "#000000"
                } else {
                    return price.shop.colorHex
                }
            }),
            chart: {
                height: 350,
                type: "area",
                toolbar: {
                    show: true
                }
            },
            title: {
                text: [t("pricetable.righttab.price_history.title") + ": " + props?.productNr, productname]
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "smooth"
            },
            xaxis: {
                type: "datetime",
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                type: "numeric"
            },
            tooltip: {
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                    let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                    let name = w.globals.initialSeries[seriesIndex].name

                    let html = '<div class="apexcharts-tooltip-title">'
                    html +=
                        new Date(data[0]).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "numeric"
                        }) +
                        " " +
                        new Date(data[0]).toLocaleTimeString("de-DE", { hour: "numeric", minute: "numeric" })
                    html += "</div>"

                    html += "<ul class='apexchart_custom_list'>"
                    html += "<li><b>" + t("single.shop") + "</b>: " + name + "</li>"
                    if (data[1]) {
                        html += "<li><b>" + t("single.price") + "</b>: " + data[1] + "</li>"
                    }
                    if (data[2] && data[2] != "") {
                        html += "<li><b>" + t("single.dealers") + "</b>: " + data[2] + "</li>"
                    }
                    html += "</ul>"

                    return html
                }
            }
        }
    }

    return (
        <div>
            <PPDateRangePicker handleDateChange={(data) => handleDateChange(data)} />

            {priceHistory.length !== 0 && (
                <ReactApexChart
                    options={prepareOptions()}
                    onChange={setDateRange}
                    series={prepareSeriesDataForAreaChart()}
                    type="area"
                    width={"100%"}
                    height={250}
                />
            )}
            {priceHistory.length === 0 && (
                <p>
                    {t("pricetable.righttab.price_history.no_pricehistory_available_1")}
                    <br />
                    {t("pricetable.righttab.price_history.no_pricehistory_available_2")}
                </p>
            )}
        </div>
    )
}

export default PPPriceHistory
