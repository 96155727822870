import React from "react"
import "../App.css"
import ReactApexChart from "react-apexcharts"
import PPUtils from "../utils/PPUtils"

function PPDonutStatsBox({ value, valueAll, onOpenClick, text, startColor, endColor, showMore }) {
    let seriesData = [value]
    let seriesFillColor = [startColor]
    let seriesGradientToColors = [endColor]

    if (valueAll > 0) {
        seriesData = [valueAll, value]
        seriesFillColor = [PPUtils.Colors.GreyLight, startColor]
        seriesGradientToColors = [PPUtils.Colors.GreyLight, endColor]
    }

    let config = {
        series: seriesData,
        options: {
            grid: {
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                }
            },
            chart: {
                type: "radialBar",
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    track: {
                        background: "lightgray",
                        strokeWidth: "60%",
                        margin: 0 // margin is in pixels
                    },
                    hollow: {
                        margin: 0,
                        size: "70%"
                    },
                    dataLabels: {
                        name: {
                            color: "#000",
                            fontSize: "12px",
                            show: false,
                            offsetY: -20
                        },
                        value: {
                            color: "#000000",
                            fontSize: "1.25rem",
                            show: true,
                            offsetY: 5
                        },
                        total: {
                            show: true,
                            label: "",
                            formatter: function (w) {
                                if (w.config.series.length == 2) {
                                    return w.config.series[1] + "%"
                                }
                                return w.config.series[0] + "%"
                            }
                        }
                    }
                }
            },
            fill: {
                colors: seriesFillColor,
                type: "gradient",
                gradient: {
                    shade: "dark",
                    gradientToColors: seriesGradientToColors,
                    shadeIntensity: 1,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                }
            }
        }
    }

    return (
        <div className="col-lg-4 col-md-6 col-xs-12 connectedSortable ui-sortable">
            <div className="info-box bg-white min-height ui-sortable-handle shadow">
                <div className="d-flex flex-grow-1">
                    <div className="flex-grow-0">
                        <ReactApexChart options={config.options} series={config.series} type="radialBar" width={200} />
                    </div>
                    <div className="d-flex flex-column flex-fill justify-content-around align-items-center">
                        <h5 className="text-center">{text}</h5>
                        <i
                            className={showMore ? "fas fa-arrow-alt-circle-up" : "fas fa-arrow-alt-circle-down"}
                            style={
                                !showMore
                                    ? {
                                          fontSize: "3em",
                                          color: "lightgray",
                                          cursor: "pointer"
                                      }
                                    : { fontSize: "3em", color: "#007bff", cursor: "pointer" }
                            }
                            onClick={onOpenClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PPDonutStatsBox
