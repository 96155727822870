import React from "react"
import PPUtils from "../utils/PPUtils"
import { useTranslation } from "react-i18next"
import PPPictureBoxLoad from "./PPPictureBoxLoad"
import useRequireAuth from "../hooks/use-require-auth"

export function PPProductInfos(props) {
    const [t] = useTranslation()
    const auth = useRequireAuth()

    const currentAuth = PPUtils.getCurrentAuthFromAuth(auth)

    return (
        <table className="table productinfo-table" style={{ width: "calc(100vw - 335px)" }}>
            <tr>
                <td>{t("single.productname")}:</td>
                <td>
                    {props.productData.productName ? props.productData.productName : ""} {props.imageUrl}
                </td>
                <td rowSpan="6">
                    <div className={"productinfo-table-imagebox"}>
                        <PPPictureBoxLoad image={props?.productData?.imageUrl} />
                    </div>
                </td>
            </tr>
            <tr>
                <td>{t("single.productnr")}:</td>
                <td>{props.productData.productnr}</td>
            </tr>
            <tr>
                <td>EAN/GTIN14:</td>
                <td>{props.productData.ean}</td>
            </tr>
            <tr>
                <td>{t("single.brand")}:</td>
                <td>{props.productData.producer}</td>
            </tr>
            <tr>
                <td>URL:</td>
                <td>
                    <a
                        href={PPUtils.getCustomerProductURL(currentAuth?.customer, props.productData)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {PPUtils.getCustomerProductURL(currentAuth?.customer, props.productData)}
                    </a>
                </td>
            </tr>
            <tr>
                <td>{t("single.category")}:</td>
                <td>{props.productData.department}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
            </tr>
        </table>
    )
}
