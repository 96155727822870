import React from "react"
import "../App.css"
import { PPProductInfos } from "./PPProductInfos"

function PPExpandedRow(props) {
    return (
        <>
            <div className="mt-1 mb-1">
                <PPProductInfos productData={props.data} />
            </div>
        </>
    )
}

export default PPExpandedRow
