import React, { useEffect, useState } from "react"
import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import GraphQL from "../utils/GraphQLClient"
import jquery from "jquery"
import PPPictureBoxLoad from "./PPPictureBoxLoad"
import PPMatchingTarifView from "./PPMatchingTarifView"

const COMPETITOR_QUERY = `query getCompetitors($productId: Long!, $shopId: Long!, $search: String, $searchModus: String, $showMapping: Boolean) {
        findCompetitorMatches(productId: $productId, shopId: $shopId, search: $search, searchModus: $searchModus, showMapping: $showMapping) {
            productName
            producer
            productnr
            price
            secondPrice
            currency
            shopId
            shopname
            url
            imageUrl
            customerFromProductNr
            merchantPrices {
                name
                price
                totalPrice
                deliveryPrice
                stock
                url
                tarif {
                    displayPrice
                    tarifInfoMap
                }
            }
        }
    }
`

const query_mappingcreate = `mutation($shopId: Int!, $productNrFrom: String, $productNrTo: String) {
        createMapping(
            shopId: $shopId
            productNrFrom: $productNrFrom
            productNrTo: $productNrTo
        ) {
            productName
            productnr
            url
        }
    }
`

function CompetitorRow(props) {
    return (
        <>
            <li className="list-group-item">
                <div className={"img"}>
                    <PPPictureBoxLoad image={props?.comp?.imageUrl} alt={props?.comp?.productName} height={"80px"} />
                </div>
                <div className={"name"}>
                    <strong>
                        {props.comp.producer} - {props.comp.productName}
                        {props.comp.price > 0 && (
                            <>
                                {" "}
                                (
                                {localStorage.getItem("switchCurrency") === "true"
                                    ? props.comp.secondPrice.toFixed(2)
                                    : props.comp.price.toFixed(2)}{" "}
                                {props.comp.currency})
                            </>
                        )}
                    </strong>
                </div>
                {props.comp.customerFromProductNr && (
                    <div className={"name"}>
                        <i className="fas fa-link mx-auto" style={{ color: "gray" }}></i>{" "}
                        {props.comp.customerFromProductNr}
                    </div>
                )}
                {props.comp.merchantPrices && props.comp.merchantPrices?.length > 0 && (
                    <div className={"tarifDetails"}>
                        {props.comp.merchantPrices?.map((merchant, idx) => (
                            <PPMatchingTarifView key={idx} tarifData={merchant} />
                        ))}
                    </div>
                )}
                <button
                    type="button"
                    data-toggle="modal"
                    data-target="#createMappingCompetitorsModal"
                    onClick={() => props.setSelectedData(props.comp)}
                    className={`btn ${
                        props.comp.customerFromProductNr?.length > 0 ? "btn-outline-primary" : "btn-primary"
                    } mt-1`}>
                    {props.comp.customerFromProductNr?.length > 0
                        ? props.t("single.mapping_change")
                        : props.t("single.mapping_create")}
                </button>
            </li>
        </>
    )
}

CompetitorRow.propTypes = { comp: PropTypes.any }

export function PPCompetitors(props) {
    const [t] = useTranslation()

    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [searchCompetitor, setSearchCompetitor] = useState("")
    const [isCheckedCompetitorSearchModus, setIsCheckedCompetitorSearchModus] = useState(true)
    const [isCheckedCompetitorShowMappings, setIsCheckedCompetitorShowMappings] = useState(false)

    useEffect(() => {
        setData([])
        setSelectedData([])
        setSearchCompetitor("")
        refreshData({ productId: props.productId, shopId: props.shopId })
    }, [props.shopId, props.productId])

    useEffect(() => {
        if (searchCompetitor != "") {
            searchData(searchCompetitor)
        }
    }, [isCheckedCompetitorSearchModus, isCheckedCompetitorShowMappings])

    function refreshData(vars) {
        GraphQL.getClient()
            .request(COMPETITOR_QUERY, vars)
            .then((vData) => {
                setData(vData)
            })
            .catch((error) => console.error(error))
    }

    function searchData(value) {
        let vars = { productId: props.productId, shopId: props.shopId }
        vars.search = value
        vars.searchModus = isCheckedCompetitorSearchModus ? "fulltext" : "manual"
        vars.showMapping = isCheckedCompetitorShowMappings
        refreshData(vars)
    }

    function createMapping() {
        let variables = {
            shopId: props.shopId,
            productNrFrom: props.productNr,
            productNrTo: selectedData.productnr
        }
        GraphQL.getClient()
            .request(query_mappingcreate, variables)
            .then((shopProduct) => {
                toast(
                    t("pricetable.righttab.competitor_tab.toast_msg", {
                        mapping: variables.productNrFrom + " -> " + variables.productNrTo
                    }),
                    {
                        onOpen: () => {
                            if (shopProduct?.createMapping?.url != "") {
                                props.onIcButtonClick(shopProduct?.createMapping?.url)
                            }
                        },
                        onClose: () => {
                            jquery("button#sidebarclose").trigger("click")
                            props?.refetchPricetable?.refetch()
                        }
                    }
                )
            })
            .catch((error) => console.error(error))
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="checkboxCompetitorSearchModus"
                                checked={isCheckedCompetitorSearchModus}
                                onChange={() => {
                                    setIsCheckedCompetitorSearchModus(!isCheckedCompetitorSearchModus)
                                }}
                            />
                            <label className="custom-control-label" htmlFor="checkboxCompetitorSearchModus">
                                {isCheckedCompetitorSearchModus
                                    ? t("pricetable.righttab.competitor_tab.label_infotext_fulltextsearch")
                                    : t("pricetable.righttab.competitor_tab.label_infotext_manualsearch")}
                            </label>
                        </div>
                    </div>
                    <div className="col">
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="checkboxCompetitorShowMappings"
                                checked={isCheckedCompetitorShowMappings}
                                onChange={() => {
                                    setIsCheckedCompetitorShowMappings(!isCheckedCompetitorShowMappings)
                                }}
                            />
                            <label
                                className="custom-control-label custom-control-label-normal"
                                htmlFor="checkboxCompetitorShowMappings">
                                {t("pricetable.righttab.competitor_tab.label_infotext_showmappings")}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <input
                onChange={(e) => {
                    setSearchCompetitor(e.target.value)
                    if (e.target.value.length >= 2) {
                        searchData(e.target.value)
                    }
                }}
                className="form-control mb-3"
                type="input"
                placeholder={
                    isCheckedCompetitorSearchModus
                        ? t("single.search_word") + " ... " + t("pricetable.righttab.competitor_tab.search_placeholder")
                        : t("single.search_word") + " ... "
                }
                name="searchcompetitor"
                value={searchCompetitor}
            />
            {data?.findCompetitorMatches?.length === 0 ? (
                <p>{t("pricetable.righttab.suggestion_tab.no_suggestions_found")}</p>
            ) : (
                <ul className="list-group competitorlist">
                    {data?.findCompetitorMatches?.map((comp, idx) => (
                        <CompetitorRow key={parseInt(idx)} comp={comp} t={t} setSelectedData={setSelectedData} />
                    ))}
                </ul>
            )}
            <div
                className="modal fade"
                data-backdrop="false"
                id="createMappingCompetitorsModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="createMappingCompetitorsLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createMappingCompetitorsLabel">
                                {t("pricetable.righttab.competitor_tab.label_modal_mapping")}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pb-0">
                            <p>
                                {selectedData?.shopname}:<br />
                                <strong>{selectedData?.producer} - </strong>
                                <strong>{selectedData?.productName}</strong>
                                <br />
                                {props?.productNr} = {selectedData?.productnr}
                            </p>
                            {selectedData.customerFromProductNr?.length > 0 && (
                                <>
                                    <p className={"p-2 mb-2 bg-warning text-dark font-weight-bold text-center"}>
                                        {t("pricetable.righttab.competitor_tab.text_modal_mappingexists", {
                                            mapping: selectedData.customerFromProductNr
                                        })}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                {t("single.cancel")}
                            </button>
                            <button
                                type="button"
                                className={`btn ${
                                    selectedData.customerFromProductNr?.length > 0
                                        ? "btn-outline-primary"
                                        : "btn-primary"
                                } mt-1`}
                                data-dismiss="modal"
                                onClick={createMapping}>
                                {selectedData.customerFromProductNr?.length > 0
                                    ? t("single.mapping_change")
                                    : t("single.mapping_create")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
